import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { isEqual } from 'lodash';
import DimensionSelect from 'components/DimensionSelect';
import SettingSelect from 'components/SettingSelect';
import { DIMENSIONS } from 'assets/constants';
import Incident from 'models/Incident';
import Dimension from 'models/Dimension';
import styles from '../ReportingResultPage.module.scss';
import { StudentWithDependencies } from 'models/Student';
import { TextField } from '@material-ui/core';

interface Props {
  student: StudentWithDependencies;
  incident: Incident;
  index: number;
  updateIncident: (incident: Incident) => void;
}
type StudentDimensions = { [key in Dimension]?: string; };

const IncidentRowEdit = ({ student, incident, index, updateIncident }: Props) => {
  const isInitialMount = useRef(true);
  const [dimensions, setDimensions] = useState<StudentDimensions>({
    consequence: incident.consequence,
    studentReaction: incident.studentReaction,
    targetBehavior: incident.targetBehavior,
    antecedent: incident.antecedent,
  });
  const [setting, setSetting] = useState(incident.setting);

  const getBehaviorDefinition = (targetBehavior: string | undefined) => student.operationalDefinitions.filter(x => x.targetBehavior === targetBehavior)[0]?.behaviorDefinition ?? ""
  const [behaviorDefinition, setBehaviorDefinition] = useState<string>(getBehaviorDefinition(incident.targetBehavior));

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const newIncident = {
        ...incident,
        ...dimensions,
        setting
      };
      if (isEqual(newIncident, incident)) return;
      updateIncident(newIncident);
      setBehaviorDefinition(getBehaviorDefinition(newIncident.targetBehavior))
    }
  }, [incident, dimensions, setting, updateIncident]);

  const getDimentionComponent = (dimension: any) => (
    <div className={styles.IncidentsTableCell} style={{ width: 210 }}>
      <div className={styles.IncidentsTableCellHeading}>{dimension.name}</div>
      <DimensionSelect
        dimension={dimension}
        value={dimensions[dimension.id as Dimension]}
        setDimension={(val: string | null) => {
            setDimensions({ ...dimensions, [dimension.id]: val })
          }
        }
      />
    </div>
  )

  return (
    <div className={styles.IncidentsTableRow}>
      <div className={styles.IncidentsTableCell}>
        {index}
      </div>
      <div className={styles.IncidentsTableCell}>
        <div className={styles.IncidentsTableCellHeading}>Started At</div>
        {moment(incident.startedAt).format('MM/DD/YYYY hh:mm:ssa')}
      </div>
      <div className={styles.IncidentsTableCell}>
        <div className={styles.IncidentsTableCellHeading}>Ended At</div>
        {moment(incident.endedAt).format('MM/DD/YYYY hh:mm:ssa')}
      </div>
      <div className={styles.IncidentsTableCell}>
        <div className={styles.IncidentsTableCellHeading}>Reporter</div>
        {incident.user?.name}
      </div>
      <div className={styles.IncidentsTableCell}>
        <div className={styles.IncidentsTableCellHeading}>Setting</div>
        <SettingSelect
          value={setting}
          setSetting={(newValue: string) => setSetting(newValue)}
        />
      </div>
      {getDimentionComponent(DIMENSIONS.filter(x => x.name == 'Target Behavior')[0])}
      <div className={styles.IncidentsTableCell} style={{ width: 210 }}>
        <div className={styles.IncidentsTableCellHeading}>Operational Definition</div>
        <TextField
          className={styles.IncidentsTableCellReadonlyTextField}
          type="text"
          fullWidth
          margin="none"
          value={behaviorDefinition}
          multiline
          rows={2}
          disabled
        />
      </div>
      {getDimentionComponent(DIMENSIONS.filter(x => x.name == 'Antecedent')[0])}
      {getDimentionComponent(DIMENSIONS.filter(x => x.name == 'Consequence')[0])}
      {getDimentionComponent(DIMENSIONS.filter(x => x.name == 'Student Reaction')[0])}
    </div>
  );
};

export default IncidentRowEdit;
