import AuthService from '../../services/auth.service';

import {
  UPDATE_USER_DATA,
  FETCH_AUTH_START,
  FETCH_AUTH_SUCCESS,
  FETCH_AUTH_ERROR,
  USER_LOGOUT
} from './actionTypes';

function fetchAuthStart() {
  return {
    type: FETCH_AUTH_START
  };
}

function fetchAuthSuccess(userData) {
  return {
    type: FETCH_AUTH_SUCCESS,
    userData
  };
}

function fetchAuthError(error) {
  return {
    type: FETCH_AUTH_ERROR,
    error
  };
}

export function getUser() {
  return (dispatch) => {
    dispatch(fetchAuthStart());
    return AuthService.getUser()
      .then((userData) => dispatch(fetchAuthSuccess(userData)))
      .catch((error) => dispatch(fetchAuthError(error)));
  };
}

export function updateUser(userData) {
  return {
    type: UPDATE_USER_DATA,
    userData
  };
}

export function logoutUser() {
  return (dispatch) => {
    dispatch(updateUser(null));
    dispatch({
      type: USER_LOGOUT
    });
    return AuthService.logout();
  };
}
