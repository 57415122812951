import { memo, useEffect, useState } from 'react';
import history from 'services/history';
import { connect, ConnectedProps } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
  Box, Drawer, List, ListItem, makeStyles, Menu, MenuItem, Typography
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import { logoutUser } from 'redux/actions/auth';
import Button from 'components/Button';
import Fab from 'components/Fab';
import RootState from 'redux/RootState';
import styles from './Header.module.scss';

const mapState = (state: RootState) => ({
  userData: state.auth.userData
});

const mapDispatch = { logoutUser };

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = {
  backgroundColor: string;
}

const Header = memo(({
  userData, logoutUser, backgroundColor
}: PropsFromRedux & Props) => {
  const location = useLocation<any>();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerMenuVisible, setIsDrawerMenuVisible] = useState(false);

  useEffect(() => {
    if (!userData) return;
    const hash = window.localStorage.getItem('growsurf-token');
    const { email } = userData;
    window.growsurf?.init({ email, hash });
  }, [userData, location]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function logOut() {
    setAnchorEl(null);
    logoutUser();
    history.replace('/', {});
  }

  function goTo(path: any) {
    setAnchorEl(null);
    history.push(path);
  }

  const toggleDrawer = () => {
    setIsDrawerMenuVisible(!isDrawerMenuVisible);
  };

  const useStyles = makeStyles({
    paper: {
      background: backgroundColor,
      color: "white",
      minWidth: "150px",
    }
  });
  const classes = useStyles();

  function renderHeader() {
    const links = [
      {
        text: "Home",
        route: "/",
      },
      {
        text: "Privacy",
        route: "/privacy",
      },
    ];

    return (
      <Box display="flex" flex={1} justifyContent="flex-end">
        <Box
          display={{xs: "none", md: "flex"}}
          alignItems="center"
          justifyContent="flex-end"
        >
          {links.map(link => (
            <NavLink 
              className={styles.NavLink}
              activeClassName={styles.NavActiveLink}
              exact to={link.route}
            >
              <Typography>{link.text}</Typography>
            </NavLink>
          ))}
        </Box>
        <Drawer
          anchor="left"
          open={isDrawerMenuVisible}
          onClose={toggleDrawer}
          classes={{ paper: classes.paper }}
        >
          <Box sx={{ height: "100%", ml: 1, borderLeft: "solid 3px white",}}>
            <List sx={{ pt: "100px" }}>
              {links.map((link, index) => (
                <ListItem button key={index} onClick={() => goTo(link.route)}>
                  <Typography>{link.text}</Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Link
            className={styles.NavLink}
            to={{
              pathname: '/login',
              state: { background: location, from: location?.state?.from }
            }}
          >
            <Typography>Log In</Typography>
          </Link>
          <Link
            className={styles.NavLink}
            to={{
              pathname: '/signup',
              state: { background: location }
            }}
          >
            <Button variant="contained" color="primary">
              Sign Up
            </Button>
          </Link>
        </Box>
      </Box>
    );
  }

  function renderHeaderAuth() {
    const links = [
      {
        text: "Home",
        route: "/",
      },
      {
        text: "Tracking",
        route: "/tracking",
      },
      {
        text: "Reporting",
        route: "/search-reporting-student",
      },
      {
        text: "Classroom",
        route: "/classroom",
      },
      {
        text: "Pricing",
        route: "/pricing",
      }
    ];

    return (
      <Box display="flex" flex="1" justifyContent="flex-end">
        {/* Referral program is temporarily disabled  https://trello.com/c/htHTn2UL/118-remove-the-grow-surf-code-from-the-site ticket */}
        <Box display="none">
          <div
            data-grsf-block-button
            data-grsf-button-style="{'border-radius': '4px', 'margin': '5px auto', 'display': 'inline-block' }"
            data-grsf-button-text="Referral Program"
          />
        </Box>
        <Box
          display={{xs: "none", md: "flex"}}
          alignItems="center"
          justifyContent="flex-end"
        >
          {links.map((link) => (
            <NavLink key={link.text}
              className={styles.NavLink}
              activeClassName={styles.NavActiveLink}
              exact to={link.route}
            >
              <Typography>{link.text}</Typography>
            </NavLink>
          ))}
        </Box>
        <Drawer
          anchor="left"
          open={isDrawerMenuVisible}
          onClose={toggleDrawer}
          classes={{ paper: classes.paper }}
        >
          <Box sx={{ height: "100%", ml: 1, borderLeft: "solid 3px white",}}>
            <List sx={{ pt: "100px" }}>
              {links.map((link) => (
                <ListItem button key={link.text} onClick={() => goTo(link.route)}>
                  <Typography>{link.text}</Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <nav>
          <Button
            className={styles.NavLink}
            color="white"
            aria-controls="user-menu"
            size="small"
            aria-haspopup="true"
            onClick={handleClick}
            variant="contained"
            startIcon={<AccountCircleIcon />}
          >
            {userData?.name}
          </Button>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => goTo({
              pathname: '/profile',
              state: { background: location }
            })}
            >
              My profile
            </MenuItem>
            <MenuItem onClick={logOut}>Logout</MenuItem>
          </Menu>
        </nav>
      </Box>
    );
  }

  return (
    <header className={styles.AppHeader}>
      <Fab
        color={userData ? 'white' : 'primary'}
        sx={{ display: {xs: "flex", md: "none"}}}
        onClick={toggleDrawer}
      >
        <MenuIcon />
      </Fab>
      {userData ? renderHeaderAuth() : renderHeader()}

    </header>
  );
});

export default connector(Header);