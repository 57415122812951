import { traceApiClient } from './api.service';
import { classroomApi } from './api.routes';
import Student, { StudentWithDependencies } from 'models/Student';
import StudentBehaivorStatistic, { BehaviorIncidentInfo, StudentBehaviorStatisticOverview, StudentTargetBehaviorOverview } from 'models/Report';
import store from 'redux/store';

function ClassroomService() {
  function getClassroomStudents() {
    const userId = store.getState().auth.userData.id;
    return traceApiClient.get<{ students: Student[] }>(`${classroomApi.students}?userId=${userId}&limit=0`).then(response => response.data);
  }

  function searchStudents(search: string) {
    return traceApiClient.get<{ students: Student[] }>(`${classroomApi.students}?search=${search}&limit=0`).then(response => response.data);
  }

  
  function searchAllStudents() {
    return traceApiClient.get<{ students: Student[] }>(`${classroomApi.students}?search=&limit=0&offset=`).then(response => response.data);
  }


  function searchStudentsInClassroom(search: string) {
    const userId = store.getState().auth.userData.id;
    return traceApiClient.get<{ students: Student[] }>(`${classroomApi.students}?search=${search}&limit=0&userId=${userId}`).then(response => response.data);
  }

  function createStudent(studentData: Student) {
    return traceApiClient.post<Student>(classroomApi.students, studentData)
    .then((response) => {
      const {
        id, birthDate, name, caseManager,
      } = response.data;

      (window as any).analytics.track('Created Student', {
        id, birthDate, name, caseManager,
      });

      return response.data;
    });
  }

  function addToClassroom(studentId: Student['id']) {
    return traceApiClient.put<Student>(classroomApi.studentAddToClassroom({ studentId }))
    .then((response) => {
      const {
        birthDate, caseManager, id, name,
      } = response.data;
      (window as any).analytics.track('Added to Classroom', {
        birthDate,
        caseManager,
        id,
        name,
      });
      return response.data;
    });
  }

  function removeFromClassroom(studentId: Student['id']) {
    return traceApiClient.put<Student>(classroomApi.studentRemoveFromClassroom({ studentId })).then(response => response.data);
  }

  function getStudentInfo(studentId: Student['id']) {
    return traceApiClient.get<StudentWithDependencies>(classroomApi.student({ studentId })).then(response => response.data);
  }

  function getStudentBehaivorStatistic(studentId: Student['id']) {
    return traceApiClient.get<StudentBehaivorStatistic>(classroomApi.studentBehaivorStatistic({ studentId })).then(response => response.data);
  }

  function getStudentBehaviorStatisticOverview(studentId: Student['id']) {
    return traceApiClient.get<StudentBehaviorStatisticOverview>(classroomApi.StudentBehaviorStatisticOverview({ studentId })).then(response => response.data);
  }

  function getStudentTargetBehaviorOverview(studentId: Student['id'], targetBehavior: BehaviorIncidentInfo['targetBehaivor']) {
    return traceApiClient.get<StudentTargetBehaviorOverview>(classroomApi.studentTargetBehaviorOverview({ studentId, targetBehavior })).then(response => response.data);
  }

  function updateStudentInfo(studentId: Student['id'], studentData: StudentWithDependencies) {
    return traceApiClient.put<StudentWithDependencies>(classroomApi.student({ studentId }), studentData).then(response => response.data);
  }

  return {
    getClassroomStudents,
    searchStudents,
    searchStudentsInClassroom,
    createStudent,
    addToClassroom,
    removeFromClassroom,
    getStudentInfo,
    updateStudentInfo,
    searchAllStudents,
    getStudentBehaivorStatistic,
    getStudentBehaviorStatisticOverview,
    getStudentTargetBehaviorOverview
  };
}

export default ClassroomService();
