import { Box, Collapse, Typography, Grid } from "@material-ui/core";
import { PropsWithChildren, useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useLocation } from 'react-router-dom';

const Section = ({ id, title, children }: PropsWithChildren<{ id: string, title: string }>) => {
  const { hash } = useLocation();
  const initiallyHighlighted = hash === '#' + id;
  const [isSectionOpen, setIsSectionOpen] = useState<boolean>(initiallyHighlighted);

  useEffect(() => {
    if (initiallyHighlighted) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } 
    }
  }, []);

  return (
    <Box my={5} id={id}>
      <Box
        display="flex"
        justifyContent="center"
        onClick={() => {
          setIsSectionOpen(!isSectionOpen);
        }}
        sx={{
          borderBottom: "1px dashed #ffffff",
          cursor: "pointer",
          ".iconBox":{
            transition: "background-color 0.5s ease",
          },
          ":hover .iconBox": {
            backgroundColor: (theme) => theme.palette.primary.main
          }
        }}
        mb={2}
      >
        <Grid container sx={{maxWidth: "800px"}} py={1}>
          <Grid item xs>
            <Typography 
              textAlign={{ sx: "left", sm: "center" }}
              fontSize={{ sx: "20px", sm: "24px" }} 
              fontWeight="bold"
              sx={{ pl: {xs: 0, sm: 5} }} pr={1}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Box className="iconBox" display="flex" sx={{ border: "1px solid", borderRadius: "20px", }}>
              {isSectionOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Collapse in={isSectionOpen}>
        <Box pt={2} pb={4}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default Section;
