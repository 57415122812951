import { Dispatch } from 'redux';
import Student from 'models/Student';
import ClassroomService from 'services/classroom.service';

import {
  STUDENTS_LOADING,
  STUDENTS_ERROR,
  FETCH_STUDENTS,
  ADD_STUDENT,
  REMOVE_STUDENT,
  CLEAR_CLASSROOM,
} from './actionTypes';

function studentsLoading() {
  return {
    type: STUDENTS_LOADING
  };
}

function studentsError(error: string) {
  return {
    type: STUDENTS_ERROR,
    error
  };
}

export function clearClassroom() {
  return {
    type: CLEAR_CLASSROOM
  };
}

export function getStudents(): any {
  return (dispatch: Dispatch) => {
    dispatch(studentsLoading());
    return ClassroomService.getClassroomStudents()
      .then(({ students }) => dispatch({
        type: FETCH_STUDENTS,
        students
      }))
      .catch((error) => {
        dispatch(studentsError(error));
        throw error; 
      });
  };
}



export function getAllStudents(): any {
  return (dispatch: Dispatch) => {
    dispatch(studentsLoading());
    return ClassroomService.searchAllStudents()
      .then(({ students }) => dispatch({
        type: FETCH_STUDENTS,
        students
      }))
      .catch((error) => {
        dispatch(studentsError(error));
        throw error; 
      });
  };
}



export function addStudent(studentData: Student) {
  return (dispatch: Dispatch) => {
    dispatch(studentsLoading());
    const request = studentData.id
      ? ClassroomService.addToClassroom(studentData.id) : ClassroomService.createStudent(studentData);
    return request
      .then((student) => {
        dispatch({
          type: ADD_STUDENT,
          student
        });
        return student;
      })
      .catch((error) => {
        dispatch(studentsError(error));
        throw error; 
      });
  };
}

export function removeStudent(studentId: Student['id']) {
  return (dispatch: Dispatch) => {
    dispatch(studentsLoading());
    return ClassroomService.removeFromClassroom(studentId)
      .then((student) => dispatch({
        type: REMOVE_STUDENT,
        studentId: student.id
      }))
      .catch((error) => {
        dispatch(studentsError(error));
        throw error; 
      });
  };
}
