function buildRoute<T extends string>(route: string, ...paramNames: T[]) {
  return function (params: { [k in typeof paramNames[number]]: string | number }): string {
    let res = route;

    paramNames.forEach((key) => {
      res = res.replace(new RegExp(`\\{${key}\\}`, 'g'), params[key].toString());
    });

    return res;
  };
}

export const ORIGIN = 'https://propellrz.com';
export const API_ENDPOINT = 'https://api.propellrz.com';


const userApi = {
  user: '/user',
  userLogin: '/auth/login',
  userRequestPasswordReset: '/auth/request-password-reset',
  userPasswordReset: buildRoute('/auth/password-reset/{token}', 'token'),
  userExport: '/user/export',
  userData: '/user/data',
};

const schoolApi = {
  schools: '/schools',
  defaultSchool: '/schools/default',
};

const classroomApi = {
  students: '/students',
  student: buildRoute('/students/{studentId}', 'studentId'),
  studentAddToClassroom: buildRoute('/students/{studentId}/add-to-classroom', 'studentId'),
  studentRemoveFromClassroom: buildRoute('/students/{studentId}/remove-from-classroom', 'studentId'),
  studentBehaivorStatistic: buildRoute('/reports/{studentId}/statistic', 'studentId'),
  StudentBehaviorStatisticOverview: buildRoute('/reports/{studentId}/statistic/overview', 'studentId'),
  studentTargetBehaviorOverview: buildRoute('/reports/{studentId}/statistic/overview/{targetBehavior}', 'studentId', "targetBehavior")
};

const trackingApi = {
  incidents: buildRoute('/students/{studentId}/incidents', 'studentId'),
  incident: buildRoute('/students/{studentId}/incidents/{incidentId}', 'studentId', 'incidentId'),
  incidentsExport: buildRoute('/students/{studentId}/incidents/export', 'studentId')
};

const digitalPassportApi = {
  studentRubric: buildRoute('/students/{studentId}/digital-passport/rubric', 'studentId'),
  studentGoals: buildRoute('/students/{studentId}/digital-passport/goals', 'studentId'),
  studentRecords: buildRoute('/students/{studentId}/digital-passport/records', 'studentId'),
  studentRecordsExport: buildRoute('/students/{studentId}/digital-passport/records/export', 'studentId'),

  todaysRecord: buildRoute('/digital-passport/todays-record/{goalId}/{scheduleId}', 'goalId', 'scheduleId'),
};

const paymentApi = {
  checkoutSession: '/payments/subscription-checkout-session',
  customerPortal: '/payments/customer-portal',
  getProducts: '/payments/products',
}

const shortLinksApi = {
  createShortLink: '/links',
  getOriginalLink: buildRoute('/links/{linkId}', 'linkId'),
}

export {
  userApi,
  schoolApi,
  classroomApi,
  trackingApi,
  digitalPassportApi,
  paymentApi,
  shortLinksApi,
};