import { AlertProps, Box } from "@material-ui/core";
import { debounce } from "lodash";
import { ReactElement, useCallback, useEffect, useState } from "react";
import ScoreDefinitionSlot from "./ScoreDefinitionSlot";
import ScoreDefinition from "./../../../../models/DigitalPassport/ScoreDefinition";
import DigitalPassportRubricService from "../../../../services/digital-passport/rubric.service";
import SectionLoader from "pages/StudentPage/SectionLoader";

interface Props {
  studentId: string;
  setAlert: (
    value: React.SetStateAction<
      | {
          severity: AlertProps["severity"];
          message: string | ReactElement;
        }
      | undefined
    >
  ) => void;
}

const SCORES = [0, 1, 2];

function Rubric({ studentId, setAlert }: Props) {
  const [scoreDefinitions, setScoreDefinitions] = useState<ScoreDefinition[]>();

  useEffect(() => {
    const loadScoreDefinitions = async () => {
      try {
        setScoreDefinitions(
          await DigitalPassportRubricService.getStudentRubric(Number(studentId))
        );
      } catch (error: any) {
        console.error(error);
        setAlert({
          severity: "error",
          message: error?.message,
        });
      }
    };

    loadScoreDefinitions();
  }, [studentId]);

  const postScoreDefinitions = async (
    scoreDefinitions: Array<ScoreDefinition>
  ) => {
    try {
      await DigitalPassportRubricService.updateStudentRubric(
        Number(studentId),
        scoreDefinitions
      );
      setAlert({
        severity: "success",
        message: `Student rubric updated successfully`,
      });
    } catch (error: any) {
      console.error(error);
      setAlert({
        severity: "error",
        message: error?.message,
      });
    }
  };

  const debouncedUpdate = useCallback(debounce(postScoreDefinitions, 500), []);

  const updateScoreDefinition = (scoreDefinition: ScoreDefinition) => {
    let updatedScoreDefinitions = [...(scoreDefinitions || [])];
    if (scoreDefinition.definition) {
      const updatedScoreDefinition = updatedScoreDefinitions.find(
        (sd) => sd.score === scoreDefinition.score
      );
      if (updatedScoreDefinition) {
        updatedScoreDefinition.definition = scoreDefinition.definition;
      } else {
        updatedScoreDefinitions.push(scoreDefinition);
      }
    } else {
      updatedScoreDefinitions = updatedScoreDefinitions.filter(
        (sd) => sd.score !== scoreDefinition.score
      );
    }

    setScoreDefinitions(updatedScoreDefinitions);

    debouncedUpdate(updatedScoreDefinitions);
  };

  return (
    <>
      {scoreDefinitions === undefined ? (
        <SectionLoader />
      ) : (
        <Box display="flex" flexWrap="wrap" justifyContent="center">
          {SCORES.map((score) => (
            <ScoreDefinitionSlot
              key={score}
              scoreDefinition={
                scoreDefinitions.find((sd) => sd.score === score) ?? {
                  score,
                  definition: "",
                }
              }
              update={(sd) => updateScoreDefinition(sd)}
            />
          ))}
        </Box>
      )}
    </>
  );
}

export default Rubric;
