import { useState, useEffect } from 'react';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  Box, CircularProgress,
  Typography, Autocomplete,
} from '@material-ui/core';
import TextField from 'components/TextField';
import { getStudents } from 'redux/actions/classroom';
import ClassroomService from 'services/classroom.service';
import RootState from 'redux/RootState';
import Student from 'models/Student';

const mapState = (state: RootState) => ({
  userData: state.auth.userData,
});

const mapDispatch = { getStudents };

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const SearchStudentPage = ({ userData, getStudents }: PropsFromRedux) => {
  const location = useLocation<{ student: Student }>();
  const [student, setStudent] = useState(location.state?.student);
  const [search, setSearch] = useState({
    loading: false,
    open: false,
  });
  const [options, setOptions] = useState<Student[]>([]);

  useEffect(() => {
    getStudents();
  }, [getStudents]);

  const searchStudents = async (e: any) => {
    setSearch((prevState) => ({
      ...prevState,
      loading: true,
    }));

    setOptions([]);

    let students: Student[] = [];

    try {
      const response = await ClassroomService.searchStudentsInClassroom(e.target.value);
      students = response.students;
    } catch (error) {
      console.log(error);
    }

    setSearch({
      open: true,
      loading: false,
    });

    setOptions(students);
  }

  const selectStudent = async (e: any, student: Student | null) => {
    if (student) {
     setStudent(student);
    }
  }

  if (!userData) return null;

  return (
    <Box sx={{ pt: 2, pb: 2 }}>
        <h1>
            <span>Reporting &  </span>
            Insights
        </h1>
        <Box
            sx={{
            maxWidth: '600px',
            ml: 'auto',
            mr: 'auto',
            }}
        >
            <Box sx={{ mb: 3 }}>
                <Autocomplete
                    id="students"
                    options={options}
                    loading={search.loading}
                    getOptionLabel={(option) => option.name}
                    noOptionsText="Type to search student by name"
                    onChange={selectStudent}
                    value={student}
                    renderOption={(optionProps, state) => (
                      <Link to={`/classroom/${state.id}/report-highlight`}>
                        <div {...optionProps as any}>
                          
                            <Typography variant="body2" color="textPrimary">
                              {state.name}
                              <br />
                              <small style={{ fontSize: '80%' }}>
                                  Case Manager:
                                  &nbsp;
                                  {state.caseManager || '-'}
                                  <br />
                                  Date of birth:
                                  &nbsp;
                                  {state.birthDate ? moment(state.birthDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : '-'}
                              </small>
                            </Typography>   
                        </div>
                      </Link>
                    )}
                    renderInput={(params) => (
                      <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          onChange={searchStudents}
                          placeholder="Select a student"
                          InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                              <>
                              {search.loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                              </>
                          ),
                          }}
                      />
                    )}
                />
            </Box>
        </Box>
    </Box>
  );
}

export default connector(SearchStudentPage);
