import {
  UPDATE_USER_DATA,
  FETCH_AUTH_START,
  FETCH_AUTH_SUCCESS,
  FETCH_AUTH_ERROR
} from '../actions/actionTypes';

import { AuthState } from '../RootState';

const initialState: AuthState = {
  loading: true,
  error: null,
  userData: null
};

export default function authReducer(state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return {
        ...state,
        error: null,
        loading: false,
        userData: action.userData === null ? null : {
          growsurfInit: false,
          ...state.userData,
          ...action.userData
        }
      };
    case FETCH_AUTH_START:
      return {
        ...state,
        error: null,
        loading: true
      };
    case FETCH_AUTH_SUCCESS:
      return {
        ...state,
        userData: action.userData,
        error: null,
        loading: false
      };
    case FETCH_AUTH_ERROR:
      return {
        ...state,
        userData: null,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
}
