import { useState } from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/core/Autocomplete';
import {
  Dialog, DialogTitle, DialogContent,
  DialogActions, Button
} from '@material-ui/core';
import TextField from 'components/TextField';
import Dimension from 'models/Dimension';

interface Props {
  dimension: {
    id: Dimension;
    name: string;
    options: string[];
    getOptionDisabled?: (option: string) => boolean;
  };
  setDimension: (val: string | null) => void;
  freeSolo?: boolean;
  value?: string;
}

const filter = createFilterOptions<string>();

const newValueRegexp = new RegExp('Add "(.*?)"');

const DimensionSelect = ({ dimension, value, setDimension, freeSolo }: Props) => {
  const [dialog, setDialog] = useState<string>();
  const freeSoloValue = freeSolo ?? true;

  return (
    <>
      <Autocomplete
        fullWidth
        freeSolo={freeSoloValue}
        id={dimension.id}
        value={value}
        options={dimension.options}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option);
          if (freeSoloValue && inputValue !== '' && !isExisting) {
            filtered.push(`Add "${inputValue}"`);
          }
          return filtered;
        }}
        getOptionDisabled={dimension.getOptionDisabled ? dimension.getOptionDisabled : (option: string) => false}
        onChange={(event, newValue) => {
          const string = newValue?.match(newValueRegexp);
          if (string) {
            setDialog(string[1]);
            return;
          }
          setDimension(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="none"
            variant="outlined"
            fullWidth
            placeholder={`Select ${freeSoloValue ? 'or create ' : ''}${dimension.name.toLowerCase()}`}
          />
        )}
      />
      {dialog && (
        <Dialog open={true} onClose={() => setDialog(undefined)}>
          <DialogTitle>Add new dimension</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              defaultValue={dialog}
              onChange={(e) => setDialog(e.target.value)}
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialog(undefined)}>Cancel</Button>
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                setDimension(dialog);
                setDialog(undefined);
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default DimensionSelect;