import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { delay } from 'lodash';
import history from 'services/history';
import red from '@material-ui/core/colors/red';
import { Prompt } from 'react-router';
import { useParams } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Box, CircularProgress, Snackbar, Alert, AlertProps, Grid, Typography,
} from '@material-ui/core';
import ConfirmationModal, { ConfirmationProps } from 'components/ConfirmationModal';

import Button from 'components/Button';

import ClassroomService from 'services/classroom.service';
import TrackingService from 'services/tracking.service';
import { StudentWithDependencies } from 'models/Student';
import IncidentType from 'models/IncidentType';
import IIncident from 'models/Incident';
import { getLocation, validateIncident } from 'services/helpers';
import Incident from './Incident';
import Clock from './Clock';
import styles from './TrackStudentPage.module.scss';

function TrackStudentPage () {
  const { studentId } = useParams<{ studentId: string }>();
  const [student, setStudent] = useState<StudentWithDependencies | null>();
  const [confirmation, setConfirmation] = useState<ConfirmationProps>();
  const [alert, setAlert] = useState<{ severity: AlertProps['severity']; message: string; }>();
  const [incidentType, setIncidentType] = useState<IncidentType>();
  const [incident, setIncident] = useState<Pick<IIncident, 'setting' | 'startedAt' | 'endedAt'>>();
  const [incidentData, setIncidentData] = useState<Omit<IIncident, 'id' | 'setting' | 'startedAt' | 'endedAt' | 'type'>>();

  useEffect(() => {
    async function start() {
      try {
      const studentData = await ClassroomService.getStudentInfo(Number(studentId));
      setStudent(studentData);
      } catch(e) {
        console.log(e);
        setStudent(null);
      }
    }
    start();
  }, [studentId]);


  useEffect(() => {
    async function processIncident(student: StudentWithDependencies) {
      if (incident && incidentType ) {
        if (incident.startedAt!=incident.endedAt) {
          console.log(incident)
        await TrackingService.createIncident(student.id, { ...incident, ...incidentData, type: incidentType });
        setAlert({
          severity: 'success',
          message: 'Incident is saved successfully'
        });
        setIncident(undefined);
        setIncidentData(undefined);
        console.log("done")
      }
      }
    }

    if(student ) {
    processIncident(student)
  }

}, [incident]);

  const toggleTracking = async(callback?: () => void) => {
    if (!student) return;

    if (incident && incidentType) {
      const errorText = validateIncident({ ...incident, ...incidentData, type: incidentType });
      if (errorText) {
        setConfirmation({
          title: 'Select Data for This Incident',
          text: errorText,
          onConfirm: () => setConfirmation(undefined),
          onCancel: () => setConfirmation(undefined),
        });
        return;
      }

      try {

        setIncident({
          setting: incident.setting,
          startedAt: incident.startedAt,
          endedAt: moment().format(),
        });
        //console.log(incident)
       // console.log("changed data")

        
        
        callback && callback();
      } catch(e) {
        console.log(e);
        setAlert({
          severity: 'error',
          message: e.message
        });
      }
      return;
    }

    if (!location) {
      const currentTime = moment().format("hh:mm A");
      setConfirmation({
        title: 'Location is missing',
        text:  `Uh oh! The current time is ${currentTime}. Propellrz cant find a location on ${student.name}'s schedule for ${currentTime}. Please make sure a setting is created for this time on ${student.name}'s schedule`,
        onConfirm: () => history.push(`/classroom/${student?.id}`),
        confirmText: 'Schedule',
        onCancel: () => setConfirmation(undefined)
      });

      return;
    }

    setAlert(undefined);
    setIncident({
      setting: location,
      startedAt: moment().format(),
      endedAt: moment().format(),
    });
  }

  if (student === null) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={red[500]}
        height="100%"
      >
        Unable to fetch student data. Try to select student from the classroom.
      </Box>
    );
  }

  if (student === undefined) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  const location = getLocation(student.schedules);

  return (
    <div className={styles.TrackStudentPage}>
      <h1>
        <span>{ incident ? 'Now' : 'Start'} tracking,</span>
        &nbsp;
        {student.name}
      </h1>
      {incidentType ? (
        <>
          <Box
            sx={{
              maxWidth: 250,
              fontSize: 24,
              my: 2,
              mx: 'auto',
            }}
          >
            <p>
              <AccessTimeIcon />
              &nbsp;
              <Clock tracking={!!incident} />
            </p>
            {location && (
              <p>
                <LocationOnIcon />
                &nbsp;
                {location}
              </p>
            )}
          </Box>
          <Incident
            type={incidentType}
            onUpdate={setIncidentData}
            processing={!!incident}
            student={student}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              size="large"
              color={incident ? 'error' : 'success'}
              variant="contained"
              onClick={() => toggleTracking()}
            >
              {incident ? 'Complete' : 'Start'}
            </Button>
          </Box>
        </>
      ) : (
        <Box sx={{ maxWidth: '1000px', mt: 10, mx: 'auto', flexWrap: 'nowrap', textAlign: 'center', }} className={styles.StudentInfo}>
          <Typography variant="h6" component="h2">What type of data are you taking?</Typography>

          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={4} md={3}>
              <Button
                size="large"
                color="white"
                variant="contained"
                fullWidth
                onClick={() => setIncidentType(IncidentType.AbcIncident)}
              >
                ABC
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Button
                size="large"
                color="white"
                variant="contained"
                fullWidth
                onClick={() => setIncidentType(IncidentType.FrequencyIncident)}
              >
                Frequency
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Button
                size="large"
                color="white"
                variant="contained"
                fullWidth
                onClick={() => setIncidentType(IncidentType.IntervalIncident)}
              >
                Interval
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                size="large"
                color="white"
                variant="contained"
                fullWidth
                sx={{ "&.MuiButton-sizeLarge": {px: 0}}}
                onClick={() => history.push(`/tracking/${student?.id}/digital-passport`)}
              >
                Digital Passport
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={!!alert} autoHideDuration={6000} onClose={() => setAlert(undefined)}>
          {alert && (
            <Alert elevation={6} variant="filled" onClose={() => setAlert(undefined)} severity={alert.severity}>
              {alert.message}
            </Alert>
          )}
        </Snackbar>
      {confirmation && (
        <ConfirmationModal
          open={true}
          title={confirmation.title}
          text={confirmation.text}
          confirmText={confirmation.confirmText}
          cancelText={confirmation.cancelText}
          onConfirm={confirmation.onConfirm}
          onCancel={confirmation.onCancel}
          onClose={confirmation.onClose}
        />
      )}
      <Prompt
        when={!!incident}
        message={(location, action) => {
          let callback = () => history.push(location);
          if (action === 'POP') {
            callback = history.goBack;
          }

          setConfirmation({
            title: 'Navigation confirmation',
            text: 'Hey there, looks like you are trying to navigate away from this page. Would you like to complete and save this current incident you are tracking?',
            onConfirm: () => toggleTracking(callback),
            confirmText: 'Save incident',
            cancelText: 'Continue',
            onCancel: () => {
              setIncident(undefined);
              delay(callback, 0);
            },
          });
          return false;
        }}
      />
    </div>
  );
}

export default TrackStudentPage;
