import React, { useEffect } from 'react';
import history from 'services/history';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Button from 'components/Button';

import { getStudents } from 'redux/actions/classroom';
import RootState from 'redux/RootState';

import styles from './StartPage.module.scss';

const mapState = (state: RootState) => ({
  userData: state.auth.userData,
  students: state.classroom.students,
  studentsLoading: state.classroom.loading,
});

const mapDispatch = { getStudents };

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

function StartPage({ students, studentsLoading, getStudents, userData }: PropsFromRedux) {
  useEffect(() => {
    if (!students) {
      getStudents();
    }
  }, [students, getStudents]);

  useEffect(() => {
    if (students && !studentsLoading && !students.length) {
      history.replace('/classroom', { isAddModalVisible: true });
    }
  }, [studentsLoading, students]);

  return (
    <Box className={styles.StartPage}>
      <h1>
        <span>Hey there,</span>
        {' '}
        {userData?.name}
      </h1>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Link to="/tracking" className={styles.StartLink}>
          <Button size="large" color="white" variant="contained">
            Start Tracking
          </Button>
        </Link>
        &nbsp;
        <Link to="/classroom" className={styles.StartLink}>
          <Button size="large" color="white" variant="contained">
            View my class
          </Button>
        </Link>
      </Box>
    </Box>
  );
}

export default connector(StartPage);
