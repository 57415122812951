import { useState } from 'react';
import { IconButton, TextField, Palette, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Goal from 'models/DigitalPassport/Goal';
import styles from './GoalSlot.module.scss';
import { useEffect } from 'react';

interface Props {
  goal: Goal;
  update: (goal: Goal) => void;
  remove: () => void;
}

const muiStyles = (theme: any) => ({
  textField: {
    marginTop: 0,
    marginBottom: 0,
  },
  input: {
    backgroundColor: "#ffffffb3",
    "&:hover": {
      backgroundColor: "#ffffffb3",
    },
    "&.Mui-focused": {
      backgroundColor: "#ffffffb3",
    }
  },
});

function GoalSlot({ goal, update, remove }: Props) {
  const [name, setName] = useState<string>(goal.name);
  const [errorText, setErrorText] = useState<string>(" ");

  const classes = makeStyles(muiStyles)();

  useEffect(() =>{
    setName(goal.name);
  }, [goal]);
  
  const updateName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const updatedName = e.target.value;
    if (updatedName.trim().length === 0 ) {
      setErrorText("Required")
    } else {
      setErrorText(" ");
    }
    setName(updatedName)

    update({ id: goal.id, name: updatedName })
  }

  const removeDefinition = (e: React.MouseEvent) => {
    e.preventDefault();

    remove();
  }

  return (
    <div className={styles.Container}>
      <div>
        <TextField
          type="text"
          variant='filled'
          fullWidth
          margin="normal"
          label="Goal"
          placeholder="Enter the student's goal"
          name="name"
          value={name}
          onChange={updateName}
          error={errorText !== " "}
          helperText={errorText}
          multiline
          maxRows={3}
          inputProps={{ maxLength: 100 }}
          className={classes.textField}
          InputProps={{
            className: classes.input,
         }}
        />
      </div>
      <IconButton
        size="small"
        onClick={removeDefinition}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  )
}

export default GoalSlot;