import React, {Component, Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {some} from 'lodash';
import green from '@material-ui/core/colors/green';
import { connect, useDispatch } from 'react-redux';
import CustomAutocomplete from "../Autocomplete";

import {
    Box,
    Button,
    TextField,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Divider,
    CircularProgress,
    Typography,
    IconButton,
    Tooltip,
    Autocomplete
} from '@material-ui/core';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PersonIcon from '@material-ui/icons/Person';
import {DatePicker} from '@material-ui/lab';
import ClassroomService from '../../services/classroom.service';
import {prepareFullImagePath} from 'services/api.helpers';
import styles from './AddStudentModal.module.scss';
import {GENERIC_SET_DATA} from "../../redux/actions/actionTypes";

const STAGES = {
    INITIAL: 'INITIAL',
    CREATE_NEW_STUDENT: 'CREATE_NEW_STUDENT'
};
let AC_TIMEOUT_ID_REF = null;

function AddStudentModal(props) {

    const dispatch = useDispatch();
    const [state, setState] = useState({
        searchStudentActions: {}
    });

    useEffect(() => {
        // reset on init
        dispatch({ type: GENERIC_SET_DATA, payload: [
            { path: 'addStudentModal', value: {
                activeStage: STAGES.INITIAL,
                isLoading: false,
                errors: {},
                autocompleteStudentNameLoading: false,
                autocompleteStudentNameTrigger: false,
                autocompleteStudentNameValue: '',
                autocompleteStudentNameSelected: null,
                autocompleteStudentNameOptions: [],
                newStudentCaseManager: '',
                newStudentDateOfBirth: ''
            } },
        ] });
    }, []);

    useEffect(() => {
        // run online search by autocomplete
        if (!props.autocompleteStudentNameTrigger) {
            return;
        }
        dispatch({ type: GENERIC_SET_DATA, payload: [
            { path: 'addStudentModal.autocompleteStudentNameLoading', value: true },
            { path: 'addStudentModal.autocompleteStudentNameTrigger', value: false }
        ] });
        state.searchStudentActions.setIsLoading(true);
        state.searchStudentActions.clearSelectedItem();
        setTimeout(async () => {
            let results = await searchForStudentName(props.autocompleteStudentNameValue);
            results = results.filter(item => !some(props.classroomStudents, ['id', item.id]));
            state.searchStudentActions.setList(results || []);
            state.searchStudentActions.setOpen(true);
            state.searchStudentActions.setIsLoading(false);
            dispatch({ type: GENERIC_SET_DATA, payload: [
                { path: 'addStudentModal.autocompleteStudentNameLoading', value: false },
                { path: 'addStudentModal.autocompleteStudentNameTrigger', value: false },
                { path: 'addStudentModal.autocompleteStudentNameOptions', value: results || [] }
            ] });
        });
    }, [ props.autocompleteStudentNameTrigger ]);


    return (
        <div className={styles.AppPageModal} tabIndex="-1">
            <form className={styles.AppPageModalContent} onSubmit={(e) => e.preventDefault()}>
                {props.isLoading && (
                    <Box className={styles.AppModalLoading}>
                        <CircularProgress color="primary"/>
                    </Box>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PersonAddIcon/>&nbsp;&nbsp;<Typography variant="h5" component="h2">Add student</Typography>
                </Box>
                {props.activeStage === STAGES.INITIAL && <>
                    <CustomAutocomplete
                        textfieldProps={{
                            error: !!props.errors['studentNameValue'],
                            helperText: props.errors['studentNameValue'] ? props.errors['studentNameValue'] : '',
                            label: 'Student',
                            id: "students",
                            fullWidth: true,
                            placeholder: "Type to search student by name",
                            variant: "outlined",
                            margin: "normal",
                            InputProps: {
                                endAdornment: (<>
                                    {props.autocompleteStudentNameLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                </>)
                            }
                        }}
                        actions={state.searchStudentActions}
                        onChange={async (e) => {
                            if (!e) {
                                return;
                            }
                            if (e && e.stopPropogation) {
                                e.stopPropogation();
                            }
                            state.searchStudentActions.setOpen(false);
                            dispatch({ type: GENERIC_SET_DATA, payload: [
                                { path: 'addStudentModal.errors', value: { ...props.errors, studentNameValue: false } },
                                { path: 'addStudentModal.autocompleteStudentNameSelected', value: null },
                                { path: 'addStudentModal.autocompleteStudentNameValue', value: e.target.value }
                            ] });
                            if (AC_TIMEOUT_ID_REF) {
                                clearTimeout(AC_TIMEOUT_ID_REF);
                            }
                            AC_TIMEOUT_ID_REF = setTimeout(async () => {
                                dispatch({ type: GENERIC_SET_DATA, payload: [
                                    { path: 'addStudentModal.autocompleteStudentNameTrigger', value: true }
                                ] });
                            }, 250);
                        }}
                        onItemClick={(item) => {
                            state.searchStudentActions.setValue(item.name);
                            state.searchStudentActions.setOpen(false);
                            dispatch({ type: GENERIC_SET_DATA, payload: [
                                { path: 'addStudentModal.autocompleteStudentNameValue', value: item.name },
                                { path: 'addStudentModal.autocompleteStudentNameSelected', value: item }
                            ] });
                        }}
                        itemRender={(item) => {
                            return <Typography variant="body2" color="textPrimary">
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                                    <ListItemAvatar>
                                        <Avatar alt="" src={prepareFullImagePath(item.avatarUrl)}/>
                                    </ListItemAvatar>
                                    <div className={styles.ExistingStudentData}>

                                        <Typography data-id={item.id} variant="h6" component="h5">{item.name}</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, }}>
                                            <DateRangeIcon style={{fontSize: 20, marginRight: 5}}/>
                                            {' '}
                                            {item.birthDate ? moment(item.birthDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : '-'}
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, }}>
                                            <PersonIcon style={{fontSize: 20, marginRight: 5}}/>{' '}{item.caseManager}
                                        </Box>
                                    </div>
                                </Box>
                                <div style={{ display: 'none'}}>
                                    {item.name}
                                    <br />
                                    <ListItemAvatar>
                                        <Avatar alt="" src={prepareFullImagePath(item.avatarUrl)}/>
                                    </ListItemAvatar>
                                    <small style={{ fontSize: '80%' }}>
                                        Case Manager:
                                        &nbsp;
                                        {item.caseManager || '-'}
                                        <br />
                                        Date of birth:
                                        &nbsp;
                                        {item.birthDate ? moment(item.birthDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : '-'}
                                    </small>
                                </div>
                            </Typography>;
                        }}
                        id="students"
                        freeSolo
                        disableClearable
                        options={props.autocompleteStudentNameOptions}
                        value={props.autocompleteStudentNameSelected}
                        inputValue={props.autocompleteStudentNameValue}
                        loading={props.autocompleteStudentNameLoading}
                        clearOnBlur={false}
                        getOptionLabel={(option) => option?.name}
                        placeholder="Type to search student by name"
                        onChangeX={(e, value) => {
                            if (e && e.stopPropogation) {
                                e.stopPropogation();
                            }
                            dispatch({ type: GENERIC_SET_DATA, payload: [
                                { path: 'addStudentModal.autocompleteStudentNameSelected', value: value }
                            ] });
                        }}
                        onInputChange={(e, newInputValue) => {
                            if (!e) {
                                return;
                            }
                            if (e && e.stopPropogation) {
                                e.stopPropogation();
                            }
                            dispatch({ type: GENERIC_SET_DATA, payload: [
                                { path: 'addStudentModal.autocompleteStudentNameSelected', value: null },
                                { path: 'addStudentModal.autocompleteStudentNameValue', value: newInputValue }
                            ] });
                            // setAutocompleteStudentNameValue(newInputValue);
                            if (AC_TIMEOUT_ID_REF) {
                                clearTimeout(AC_TIMEOUT_ID_REF);
                            }
                            AC_TIMEOUT_ID_REF = setTimeout(async () => {
                                dispatch({ type: GENERIC_SET_DATA, payload: [
                                    { path: 'addStudentModal.autocompleteStudentNameTrigger', value: true }
                                ] });
                                // setAutocompleteStudentNameTrigger(true);
                            }, 250);
                        }}
                        renderOption={(optionProps, student) => {
                            return <div {...optionProps}>
                                <Typography variant="body2" color="textPrimary">
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                                        <ListItemAvatar>
                                            <Avatar alt="" src={prepareFullImagePath(student.avatarUrl)}/>
                                        </ListItemAvatar>
                                        <div className={styles.ExistingStudentData}>

                                            <Typography data-id={student.id} variant="h6" component="h5">{student.name}</Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, }}>
                                                <DateRangeIcon style={{fontSize: 20, marginRight: 5}}/>
                                                {' '}
                                                {student.birthDate ? moment(student.birthDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : '-'}
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, }}>
                                                <PersonIcon style={{fontSize: 20, marginRight: 5}}/>{' '}{student.caseManager}
                                            </Box>
                                        </div>
                                    </Box>
                                    <div style={{ display: 'none'}}>
                                        {student.name}
                                        <br />
                                        <ListItemAvatar>
                                            <Avatar alt="" src={prepareFullImagePath(student.avatarUrl)}/>
                                        </ListItemAvatar>
                                        <small style={{ fontSize: '80%' }}>
                                            Case Manager:
                                            &nbsp;
                                            {student.caseManager || '-'}
                                            <br />
                                            Date of birth:
                                            &nbsp;
                                            {student.birthDate ? moment(student.birthDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : '-'}
                                        </small>
                                    </div>
                                </Typography>
                            </div>;
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                placeholder="Student's name"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {props.autocompleteStudentNameLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                    <Box sx={{mt: 2}}>
                        <Button disabled={!props.autocompleteStudentNameSelected} size="large" variant="contained" color="primary" fullWidth onClick={() => { addToClassroom(props.autocompleteStudentNameSelected); }}>Add to the classroom</Button>
                    </Box>
                </>}

                {props.activeStage == STAGES.CREATE_NEW_STUDENT && <>
                    <TextField
                        type="text"
                        disabled
                        value={props.autocompleteStudentNameValue}
                        label="Student name"
                        fullWidth
                        id="student-name"
                        margin="normal"
                        name="studentName"
                    />

                    <TextField
                        type="text"
                        value={props.newStudentCaseManager}
                        onChange={(e) => {
                            let errors = { ...props.errors };
                            errors.caseManager = false;
                            dispatch({ type: GENERIC_SET_DATA, payload: [ { path: 'addStudentModal.newStudentCaseManager', value: e.target.value }, { path: 'addStudentModal.errors', value: errors } ] });
                        }}
                        error={!!props.errors['caseManager']}
                        helperText={props.errors['caseManager'] ? props.errors['caseManager'] : null}
                        label="Case manager"
                        fullWidth
                        id="student-caseManager"
                        margin="normal"
                        name="caseManager"
                    />

                    <DatePicker
                        autoOk
                        variant="inline"
                        format="MM/DD/YYYY"
                        id="birthDate"
                        label="Date of birth"
                        value={props.newStudentDateOfBirth && moment(props.newStudentDateOfBirth, 'YYYY-MM-DD')}
                        onChange={(date) => {
                            let errors = { ...props.errors };
                            try {
                                errors.dateOfBirth = false;
                                dispatch({ type: GENERIC_SET_DATA, payload: [ { path: 'addStudentModal.newStudentDateOfBirth', value: date.format('YYYY-MM-DD') }, { path: 'addStudentModal.errors', value: errors } ] });
                            }
                            catch (err) {
                                // nothing to do
                                errors.dateOfBirth = 'invalid date format';
                                dispatch({ type: GENERIC_SET_DATA, payload: [ { path: 'addStudentModal.newStudentDateOfBirth', value: null }, { path: 'addStudentModal.errors', value: errors } ] });
                            }
                        }}
                        renderInput={(textfieldProps) => <TextField {...textfieldProps} error={!!props.errors['dateOfBirth']} helperText={props.errors['dateOfBirth'] ? props.errors['dateOfBirth'] : null} fullWidth margin="normal"/>}
                    />
                </>}
                <Box sx={{mt: 2}}>
                    <Button size="large" variant="contained" color="primary" fullWidth onClick={closeModal}>Close</Button>
                </Box>
                <Box sx={{mt: 2}}>
                    <Button size="large" variant="contained" color="primary" fullWidth onClick={createStudent}>Create New Student</Button>
                </Box>
            </form>
        </div>
    );

    async function addToClassroom(student) {
        dispatch({ type: GENERIC_SET_DATA, payload: [ { path: 'addStudentModal.isLoading', value: true } ]});
        await props.addStudent(student);
        props.close();
    }


    async function closeModal() {
        props.close();
    }

    async function createStudent() {
        if (props.isLoading) {
            // prevent double-click
            return;
        }
        if (props.activeStage == STAGES.INITIAL) {
            // validate
            if (!props.autocompleteStudentNameValue) {
                // missing name
                let errors = { ...props.errors };
                errors.studentNameValue = 'Please enter a first and last name';
                dispatch({ type: GENERIC_SET_DATA, payload: [ { path: 'addStudentModal.errors', value: errors } ] });
                return;
            }
            // all ok - change active stage
            dispatch({ type: GENERIC_SET_DATA, payload: [ { path: 'addStudentModal.activeStage', value: STAGES.CREATE_NEW_STUDENT } ] });
            return;
        }
        else if (props.activeStage == STAGES.CREATE_NEW_STUDENT) {
            // validate fields
            let haveErrors = false;
            let errors = { ...props.errors };
            if (!props.newStudentDateOfBirth) {
                haveErrors = true;
                errors.dateOfBirth = 'Invalid Value';
            }
            if (!props.newStudentCaseManager) {
                haveErrors = true;
                errors.caseManager = 'Missing Value';
            }
            if (haveErrors) {
                dispatch({ type: GENERIC_SET_DATA, payload: [ { path: 'addStudentModal.errors', value: errors } ] });
                return;
            }
            // all ok - save to server
            let studentData = {
                name: props.autocompleteStudentNameValue,
                caseManager: props.newStudentCaseManager,
                birthDate: props.newStudentDateOfBirth
            };
            dispatch({ type: GENERIC_SET_DATA, payload: [ { path: 'addStudentModal.isLoading', value: true }, { path: 'addStudentModal.errors', value: {} } ] });
            await props.addStudent(studentData);
            props.close();
        }
    }

    function onChange(e) {
        const {name, value} = e.target;

        setState({
            ...state,
            student: {
                ...state.student,
                [name]: value
            }
        });
    }

    function filterDuplicates(studentsArray) {
        let output = [];
        let idHash = {};
        studentsArray.forEach(student => {
            if (!idHash[student.id]) {
                output.push(student);
                idHash[student.id] = true;
            }
        });
        return output;
    }

    async function searchForStudentName(studentName) {
        if (!studentName) {
            return [];
        }
        let nameParts = studentName.split(' ');
        let firstName = nameParts[0];
        let lastName = nameParts.slice(1).join(' ');
        const responseForFirstName = await ClassroomService.searchStudents(firstName);
        let responseForLastName = {students: []}; // default (if no search will be done)
        if (lastName) {
            responseForLastName = await ClassroomService.searchStudents(lastName);
        }
        let uniqueStudentsArray = filterDuplicates(responseForFirstName.students.concat(responseForLastName.students));
        return uniqueStudentsArray;
    }
}

AddStudentModal.propTypes = {
    classroomStudents: PropTypes.arrayOf(PropTypes.shape({})),
    addStudent: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired
};

AddStudentModal.defaultProps = {
    classroomStudents: []
};

const mapState = (state) => ({
    activeStage: state.classroom.addStudentModal.activeStage,
    isLoading: state.classroom.addStudentModal.isLoading,
    errors: state.classroom.addStudentModal.errors,
    autocompleteStudentNameLoading: state.classroom.addStudentModal.autocompleteStudentNameLoading,
    autocompleteStudentNameValue: state.classroom.addStudentModal.autocompleteStudentNameValue,
    autocompleteStudentNameTrigger: state.classroom.addStudentModal.autocompleteStudentNameTrigger,
    autocompleteStudentNameSelected: state.classroom.addStudentModal.autocompleteStudentNameSelected,
    autocompleteStudentNameOptions: state.classroom.addStudentModal.autocompleteStudentNameOptions,
    newStudentCaseManager: state.classroom.addStudentModal.newStudentCaseManager,
    newStudentDateOfBirth: state.classroom.addStudentModal.newStudentDateOfBirth
});

export default connect(mapState, null)(AddStudentModal);
