import { Box, Typography } from "@material-ui/core";
import { prepareFullImagePath } from 'services/api.helpers';
import QRCode from "react-qr-code";
import OperationalDefinition from "models/OperationalDefinition";

import styles from './QuickCard.module.scss';

interface Props {
  studentName: string;
  studentShortLink: string;
  avatarUrl: string;
  operationalDefinitions: OperationalDefinition[];
}

function QuickCard({ studentName, studentShortLink, avatarUrl, operationalDefinitions }: Props) {
  const qrCodeSize = 128;

  return (
    <Box className={styles.Container}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
        <Box sx={{ width: qrCodeSize, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <img className={styles.Avatar} src={prepareFullImagePath(avatarUrl)} alt="" />
          <QRCode size={qrCodeSize} value={studentShortLink} />
        </Box>
        <Box pl={2} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%' }}>
          <Typography variant="h4" component="div">{studentName}</Typography>
          <Typography mb={2} variant="h5" component="div">Operational Definitions</Typography>
          {operationalDefinitions.map(definition =>
            <Box mb={1.5}>
              <Typography className={styles.OpLabel} variant="subtitle2" component="div">{definition.targetBehavior}</Typography>
              <span>{definition.behaviorDefinition}</span>
            </Box>
          )}
        </Box>
      </Box>
      <div className={styles.PrintingNote}>
        This card can be printed and attached to your student's desk.
        <br/>
        Scan the QR code with your mobile device to immediately open the app and start tracking this particular student.
      </div>
    </Box>
  )
}

export default QuickCard;