import React from 'react';
import { Button as MuiButton, PaletteColor, ButtonProps, makeStyles, createStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';

interface Props extends ButtonProps {
  color: any;
};

const customButtonsType = ['black', 'white', 'success', 'error'];

const useStyles = makeStyles((theme) => createStyles({
  root: {
    textTransform: 'none'
  },
  contained: (props: ButtonProps) => {
    const color = theme.palette[props.color as keyof typeof theme.palette] as PaletteColor;
    return {
      color: color?.contrastText,
      backgroundColor: color?.main,
      '&:hover': {
        backgroundColor: color?.dark,
      },
      '&.Mui-disabled': {
        backgroundColor: color?.main,
        opacity: 0.6
      }
    };
  },
  outlined: (props: ButtonProps) => {
    const color = theme.palette[props.color as keyof typeof theme.palette] as PaletteColor;
    return {
      borderColor: alpha(color?.main, 0.7),
      color: color?.main,
      '&:hover': {
        borderColor: color.main,
        backgroundColor: alpha(color?.main, 0.1),
      },
      '&.Mui-disabled': {
        borderColor: color?.main,
        opacity: 0.6
      }
    };
  },
  large: {
    padding: '15px 30px',
    fontSize: ' 1.5rem'
  }
}));

function Button({
  variant = 'text',
  ...props
}: Props) {
  const { color, className, ...rest } = props;
  const classes = useStyles(props);
  let filterClasses = classes.root;

  const initColor = customButtonsType.includes(color) ? undefined : color;

  if (!initColor) {
    filterClasses = `${filterClasses} ${classes[variant as keyof typeof classes] || ''}`;
  }

  if (props.size === 'large') {
    filterClasses = `${filterClasses} ${classes.large}`;
  }
  return <MuiButton className={`${filterClasses} ${className || ''}`} color={initColor as any} {...rest} variant={variant} />;
}

export default Button;
