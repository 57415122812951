import moment from 'moment';
import { Box } from '@material-ui/core';
import Incident from 'models/Incident';
import IncidentRowEdit from './IncidentRowEdit';
import IncidentRowView from './IncidentRowView';

import styles from '../ReportingResultPage.module.scss';

interface Props {
  currentUserId: number;
  incidents?: Incident[];
  updateIncident: (incident: Incident) => void;
}

const FrequencyIncidentTable = ({ incidents = [], currentUserId, updateIncident }: Props) => {
  return incidents.length ? (
    <Box className={styles.IncidentsTable}>
      <div className={styles.IncidentsTableHead}>
        <div className={styles.IncidentsTableRow}>
          <div className={styles.IncidentsTableCell} />
          <div className={styles.IncidentsTableCell}>Started At</div>
          <div className={styles.IncidentsTableCell}>Ended At</div>
          <div className={styles.IncidentsTableCell}>Reporter</div>
          <div className={styles.IncidentsTableCell}>Setting</div>
          <div className={styles.IncidentsTableCell}>Frequency</div>
        </div>
      </div>
      <div>
        {incidents.map((incident, index) => {
          if (currentUserId === incident.user?.id && moment(incident.startedAt) > moment().subtract(31, 'days')) {
            return <IncidentRowEdit key={incident.id} index={index} incident={incident} updateIncident={updateIncident} />
          }
          return <IncidentRowView key={incident.id} index={index} incident={incident} />;
        })}
      </div>
    </Box>
  ) : null;
};

export default FrequencyIncidentTable;