import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ItemInfo } from 'models/Report';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  itemList: ItemInfo[];
}

const FunctionOfBehavior = ({itemList} : Props) => {
  const theme = useTheme();
  const labels = itemList.map((item, i) => {
    return item.itemName;
  });
  const dataList = itemList.map((item, i) => {
    return item.count;
  })
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      }
    },
    
    scales: {
      x: {
        title: {
          display: true,
          text: 'Perceived Motivations',
          font: {
            size: useMediaQuery(theme.breakpoints.down('sm')) ? 10 : 15
          },
          padding: {top: 30, left: 0, right: 0, bottom: 0}
        },
        ticks: {
          font: {
            size: useMediaQuery(theme.breakpoints.down('sm')) ? 10 : 20
          }
        }
      },
      y: {
        title: {
          display: true,
          text: '# of Incidents',
          font: {
            size: useMediaQuery(theme.breakpoints.down('sm')) ? 10 : 15
          },
          padding: {top: 0, left: 0, right: 0, bottom: 30}
        },
        ticks: {
          precision: 0,
        }
      }
    }
  };
  const data = {
    labels,
    datasets: [
      {
        label: 'Student behavior',
        data: dataList,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      },
    ],
  };
  return <Bar data={data} options={options}/>;
}

export default FunctionOfBehavior;