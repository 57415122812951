import { InputBase, withStyles } from '@material-ui/core';

const TraceBaseInput = withStyles((theme) => ({
  input: {
    fontSize: 18,
    fontWeight: 300,
    background: 'rgba(255,255,255,0.7)',
    padding: '14px',
    border: '1px solid #ffffff',
    '&:focus': {
      background: 'rgba(255,255,255,0.7)',
      borderColor: '#ffffff',
      boxShadow: 'none',
    },
  },
}))(InputBase);

export default TraceBaseInput;