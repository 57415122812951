import { Box, CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import shortLinkService from 'services/short-link.service';

const ShortLinkPage = () => {
  const { linkId } = useParams<{ linkId: string }>();

  const [originalLink, setOriginalLink] = useState<string | null>(null);

  useEffect(() => {
    async function loadOriginalLink () {
      try {
        const link = await shortLinkService.getOriginalLink(linkId);
        setOriginalLink(link.slice(window.location.origin.length));
      } catch (error) {
        console.log(error);
        setOriginalLink(null);
      }
    }
    loadOriginalLink();
  }, [linkId]);

  if (originalLink === null) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <CircularProgress color="primary" />
        </Box>
    );
  }

  return (
    <Redirect
      to={{
        pathname: originalLink,
      }}
    />
  );
};

export default ShortLinkPage;
