import moment from 'moment';
import ScheduleSlot from 'models/ScheduleSlot';
import { MAX_LESSON_TIME } from 'assets/constants';
import IIncident from 'models/Incident';
import IncidentType from 'models/IncidentType';

export const getLocation = (schedules: ScheduleSlot[] = []) => {
  const timeSlot = schedules.find((slot) => {
    const time = moment();
    const startClassTime = moment(slot.timeframe, 'HH:mm');
    const endClassTime = moment(slot.timeframe, 'HH:mm').add(MAX_LESSON_TIME, 'minutes');
    return startClassTime.isBefore(time) && time.isBefore(endClassTime) && slot.location;
  });
  return timeSlot && timeSlot.location;
}

export const validateIncident = (incident: Omit<IIncident, 'id'>) => {
  const hasAbcData = incident.antecedent && incident.consequence && incident.studentReaction && incident.targetBehavior;
  if (incident.type === IncidentType.AbcIncident && !hasAbcData) {
    return 'Please select all tracking dimensions before completing this incident.';
  }
  if (incident.type === IncidentType.FrequencyIncident && !incident.frequency) {
    return 'Please select frequency before completing this incident.';
  }
};

export const secondsFormat = (total_seconds: number) => {
    let hours, minutes, seconds, total_hours, total_minutes;
    
    total_minutes = parseInt(`${Math.floor(total_seconds / 60)}`);
    total_hours = parseInt(`${Math.floor(total_minutes / 60)}`);
  
    seconds = parseInt(`${total_seconds % 60}`);
    minutes = parseInt(`${total_minutes % 60}`);
    hours = parseInt(`${total_hours % 24}`);

    const renderNumber = (number: number) => number < 10 ? `0${number}` : number;
    return `${renderNumber(hours)}:${renderNumber(minutes)}:${renderNumber(seconds)}`;
}