import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { secondsFormat } from 'services/helpers';

function Clock ({ tracking }: { tracking: boolean }) {
  const clockInterval = useRef<number>();
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    return () => {
      window.clearTimeout(clockInterval.current);
    }
  }, []);


  useEffect(() => {
    if (tracking) {
      clockInterval.current = window.setInterval(() => {
        setTime((prevState) => prevState + 1);
      }, 1000);
    }

    if (!tracking) {
      window.clearTimeout(clockInterval.current);
      setTime(0);
    }
  }, [tracking]);

  return <>{secondsFormat(time)}</>;
}

export default Clock;