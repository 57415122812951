import { AlertProps, Box } from "@material-ui/core";
import Button from "components/Button";
import { debounce } from "lodash";
import { ReactElement, useCallback, useEffect, useState } from "react";
import Goal from "models/DigitalPassport/Goal";
import GoalSlot from "./GoalSlot";
import { ConfirmationProps } from "components/ConfirmationModal";
import SectionLoader from "pages/StudentPage/SectionLoader";
import DigitalPassportGoalService from "../../../../services/digital-passport/goal.service";

interface Props {
  studentId: string;
  setAlert: (
    value: React.SetStateAction<
      | {
          severity: AlertProps["severity"];
          message: string | ReactElement;
        }
      | undefined
    >
  ) => void;
  setConfirmation: (value: React.SetStateAction<ConfirmationProps>) => void;
}

const MAX_GOALS = 3;
const DEFAULT_ID = 0;

function Goals({ studentId, setAlert, setConfirmation }: Props) {
  const [goals, setGoals] = useState<Goal[]>();

  useEffect(() => {
    const loadGoals = async () => {
      try {
        setGoals(
          await DigitalPassportGoalService.getStudentGoals(Number(studentId))
        );
      } catch (error: any) {
        console.error(error);
        setAlert({
          severity: "error",
          message: error?.message,
        });
      }
    };

    loadGoals();
  }, [studentId]);

  const postGoals = async (goals: Array<Goal>) => {
    try {
      if (goals.filter((g) => g.name === "").length > 0) {
        return;
      }
      setGoals(
        await DigitalPassportGoalService.updateStudentGoals(
          Number(studentId),
          goals
        )
      );
      setAlert({
        severity: "success",
        message: `Student goals updated successfully`,
      });
    } catch (error: any) {
      console.error(error);
      setAlert({
        severity: "error",
        message: error?.message,
      });
    }
  };

  const debouncedUpdate = useCallback(debounce(postGoals, 500), []);

  const updateGoal = (goal: Goal, index: number) => {
    const updatedGoals = [...(goals || [])];
    updatedGoals[index] = goal;
    setGoals(updatedGoals);

    if (updatedGoals.filter((g) => g.name.trim().length === 0).length > 0) {
      setAlert({
        severity: "error",
        message: "Student goals are not saved because of validation error.",
      });
    } else {
      debouncedUpdate(updatedGoals);
    }
  };

  const removeGoal = (index: number) => {
    if (goals === undefined) {
      return;
    }
    if (goals[index].id === DEFAULT_ID) {
      const updatedGoals = [...(goals || [])];
      updatedGoals.splice(index, 1);
      setGoals(updatedGoals);
      return;
    }

    setConfirmation({
      open: true,
      title: "Goal Removal",
      text: "Are you sure you would like to remove this goal? And all related tracking information if any?",
      onConfirm: () => {
        removeGoalConfirmed(index);
        setConfirmation({ open: false, title: "", text: "" });
      },
      confirmText: "Delete",
      onCancel: () => setConfirmation({ open: false, title: "", text: "" }),
    });
  };

  const removeGoalConfirmed = (index: number) => {
    const updatedGoals = [...(goals || [])];
    updatedGoals.splice(index, 1);
    setGoals(updatedGoals);

    debouncedUpdate(updatedGoals);
  };

  const addGoal = () => {
    if ([goals || []].length >= MAX_GOALS) {
      setAlert({
        severity: "warning",
        message: "No more than 3 goals can be defined at the time.",
      });
      return;
    }

    const updatedGoals = [
      ...(goals || []),
      {
        id: DEFAULT_ID,
        name: "",
      },
    ];
    setGoals(updatedGoals);
  };

  return (
    <>
      {goals === undefined ? (
        <SectionLoader />
      ) : (
        <>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {goals.map((goal, index) => (
              <GoalSlot
                key={index}
                goal={goal}
                update={(g) => updateGoal(g, index)}
                remove={() => removeGoal(index)}
              />
            ))}
          </Box>
          <Box display="flex" justifyContent="center" marginY={2}>
            <Button
              size="large"
              color="white"
              variant="contained"
              onClick={addGoal}
              disabled={goals.length >= MAX_GOALS}
            >
              Add Goal
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

export default Goals;
