import {
  Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography

} from '@material-ui/core';
import OperationalDefinition from 'models/OperationalDefinition';
import { StudentWithDependencies } from 'models/Student';
import { useEffect, useState } from 'react';
import ClassroomService from 'services/classroom.service';

import styles from './OperationalDefinitionModal.module.scss';

interface OperationalDefinitionModalProps {
  student: StudentWithDependencies | null;
  onClose: () => void;
};

const OperationalDefinitionModal = ({ student, onClose }: OperationalDefinitionModalProps) => {
  const [open, setOpen] = useState(true);
  const opDefinitions = student == null ? null : student.operationalDefinitions;
  
  const close = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ mt: 2 }}>
        Operational Definitions
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'grid' }}>
          {opDefinitions === null
          ? <CircularProgress color="inherit" size={20} />
          : opDefinitions.length == 0
            ? <>
              <p>No operational definitions available.</p>
              <p>You can fill them in on the student's profile page.</p>
            </>
            : opDefinitions.map(def => (
              <Box key={def.behaviorDefinition} className={styles.box} sx={{ display: 'grid' }}>
                <Typography variant="h6" color="primary" align="center" sx={{ mb: 1 }}>
                  {def.targetBehavior}
                </Typography>
                <div>{def.behaviorDefinition}</div>
              </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
          <Button onClick={close} color="primary">
            Close
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OperationalDefinitionModal;
