import moment from 'moment';
import Incident from 'models/Incident';

import styles from '../ReportingResultPage.module.scss';

interface Props {
  incident: Incident;
  index: number;
}

const IncidentRowView = ({ incident, index }: Props) => (
  <div className={styles.IncidentsTableRow}>
    <div className={styles.IncidentsTableCell}>{index}</div>
    <div className={styles.IncidentsTableCell}>
      <div className={styles.IncidentsTableCellHeading}>Started At</div>
      {moment(incident.startedAt).format('MM/DD/YYYY hh:mm:ssa')}
    </div>
    <div className={styles.IncidentsTableCell}>
      <div className={styles.IncidentsTableCellHeading}>Ended At</div>
      {moment(incident.endedAt).format('MM/DD/YYYY hh:mm:ssa')}
    </div>
    <div className={styles.IncidentsTableCell}>
      <div className={styles.IncidentsTableCellHeading}>Reporter</div>
      {incident.user?.name}
    </div>
    <div className={styles.IncidentsTableCell}>
      <div className={styles.IncidentsTableCellHeading}>Setting</div>
      {incident.setting}
    </div>
    <div className={styles.IncidentsTableCell} style={{ width: 100 }}>
      <div className={styles.IncidentsTableCellHeading}>Interval</div>
      {incident.timeInterval}
    </div>
    <div className={styles.IncidentsTableCell} style={{ width: 120 }}>
      <div className={styles.IncidentsTableCellHeading}>Occurrences</div>
      {incident.occurrences}
    </div>
    <div className={styles.IncidentsTableCell} style={{ width: 100 }}>
      <div className={styles.IncidentsTableCellHeading}>Results</div>
      {incident.results}
    </div>
  </div>
);

export default IncidentRowView;
