import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  TextField, Grid, Button, Box,
  Typography, ClickAwayListener,
  CircularProgress, FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getUser } from 'redux/actions/auth';
import SchoolsSearch from 'components/SchoolsSearch';
import AuthService from 'services/auth.service';

import styles from './Auth.module.scss';

const USER_EXISTS_ERROR = 'IsUserAlreadyExistConstraint';

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      username: '',
      email: '',
      password: '',
      passwordRepeat: '',
      terms: false,
      userExists: null,
      loading: false,
      errors: [],
      alert: null,
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  signup = async () => {
    const userData = {
      name: this.state.name,
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
      passwordRepeat: this.state.passwordRepeat,
      schoolId: this.state.schoolId,
    };

    this.setState({
      userExists: false,
      alert: null
    });

    const isFormValid = this.validateLoginData(userData);
    if (!isFormValid) return;

    this.setState({
      loading: true
    });

    try {
      await AuthService.signup(userData);

      this.setState({
        loading: false,
        alert: {
          variant: 'success',
          message: "A verification link has been sent to your email address."
        }
      });
      // await AuthService.login({
      //   username: this.state.username,
      //   password: this.state.password,
      //   rememberMe: false
      // });

      // await this.props.getUser();

      // this.props.history.replace({
      //   ...this.props.location.state.background,
      //   state: undefined
      // });
    } catch (error) {
      this.setState({
        loading: false,
        userExists: error.constraints.includes(USER_EXISTS_ERROR),
        alert: {
          variant: 'error',
          message: error.message
        }
      });
    }
  }

  validateLoginData(userData) {
    const errors = [];
    let errorMessage = 'Please, fill required fields';

    Object.keys(userData).forEach((key) => {
      if (!userData[key]) {
        errors.push(key);
      }
    });

    if (userData.passwordRepeat && userData.password !== userData.passwordRepeat) {
      errors.push('passwordRepeat');
      errorMessage = 'Password doesn\'t match';
    }

    const userDomain = userData.email.split("@")[1];

    if(userDomain !== this.state.schoolDomain) {
      errors.push('domainMismatch');
      errorMessage = `hey there, to protect the safety of our kiddos, in order to sign up using ${this.state.schoolName} please use your work email that ends in ${this.state.schoolDomain}. You will receive an activation link to that email here shortly to get started!`;
    }

    this.setState({
      alert: errors.length ? {
        variant: 'error',
        message: errorMessage
      } : null,
      errors
    });

    return !errors.length;
  }

  selectSchool = async (e, school) => {
    if (school) {
      this.setState({
        schoolId: school.id,
        schoolDomain: school.county.domain,
        schoolName: school.name
      })
    }
  }

  render() {
    return (
      <div className={styles.AppModalPageWrapper}>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => this.props.history.push(this.props.location.state.background)}
        >
          <div className={styles.AppPageModal}>
            <form autoComplete="off" className={styles.AppPageModalContent} onSubmit={(e) => e.preventDefault()}>
              {this.state.loading && (
                <Box className={styles.AppModalLoading}>
                  <CircularProgress color="primary" />
                </Box>
              )}
              <h1>Create Account</h1>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    value={this.state.name}
                    onChange={this.onChange}
                    error={this.state.errors.includes('name')}
                    label="Full Name *"
                    fullWidth
                    id="application-name"
                    margin="dense"
                    name="name"
                    autoFocus
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    value={this.state.username}
                    onChange={this.onChange}
                    error={this.state.errors.includes('username')}
                    label="User Name *"
                    fullWidth
                    id="application-user-name"
                    margin="dense"
                    name="username"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    error={this.state.errors.includes('email')}
                    label="Email *"
                    fullWidth
                    id="application-email"
                    margin="dense"
                    name="email"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    value={this.state.password}
                    onChange={this.onChange}
                    error={this.state.errors.includes('password')}
                    label="Password *"
                    fullWidth
                    id="application-password"
                    margin="dense"
                    name="password"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    value={this.state.passwordRepeat}
                    onChange={this.onChange}
                    error={this.state.errors.includes('passwordRepeat')}
                    label="Repeat Password *"
                    fullWidth
                    id="application-password-repeat"
                    margin="dense"
                    name="passwordRepeat"
                  />
                </Grid>
              </Grid>
              <SchoolsSearch onSelect={this.selectSchool} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    role="checkbox"
                    checked={this.state.terms}
                    onChange={(e) => this.onChange({ target: { name: 'terms', value: e.target.checked } })}
                    value="terms"
                    onKeyPress={(event) => {
                      if (event.key === 'Space') {
                        this.onChange({ target: { name: 'terms', value: !this.state.terms } });
                      }
                    }}
                    control={(
                      <Checkbox
                        color="primary"
                        tabIndex="0"
                      />
                    )}
                    label={(
                      <Box sx={{ fontSize: 14 }}>
                        I agree to &nbsp;
                        <a
                          href="https://www.propellrz.com/policies/Propellrz_Terms_and_Conditions.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms
                        </a>
                        &nbsp;and&nbsp;
                        <a
                          href="https://www.propellrz.com/policies/Propellrz_Privacy_Policy.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy
                        </a>
                      </Box>
                    )}
                  />
                </Grid>
              </Grid>
              {this.state.userExists && (
                <Box sx={{ mt: 3 }}>
                  <Typography variant="body2" component="div" color="error">
                    Hey there! Looks like you already have an account
                  </Typography>
                  {' '}
                  <br />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Link
                      color="primary"
                      to={{
                        pathname: '/login',
                        state: { background: this.props.location.state.background }
                      }}
                    >
                      Sign In
                    </Link>
                    &nbsp;
                    <Link
                      color="primary"
                      to={{
                        pathname: '/request-password-reset',
                        state: { background: this.props.location.state.background }
                      }}
                    >
                      Forgot a password?
                    </Link>
                  </Box>
                </Box>
              )}
              {(!this.state.userExists && this.state.alert) && (
                <Box sx={{ mt: 3 }}>
                  <Typography variant="body2" component="div" className={`${this.state.alert.variant}-alert`}>
                    {this.state.alert.message}
                  </Typography>
                </Box>
              )}
              <Box sx={{ mt: 3 }}>
                <Button
                  disabled={!this.state.terms}
                  variant="contained"
                  color="primary"
                  onClick={this.signup}
                  fullWidth
                  size="large"
                  role="button"
                  type="submit"
                >
                  Join
                </Button>
              </Box>
              <Box sx={{ mt: 2 }} component="p">
                Do you have an account already? &nbsp;
                <Link
                  color="primary"
                  to={{
                    pathname: '/login',
                    state: { background: this.props.location.state.background }
                  }}
                >
                  Sign In
                </Link>
              </Box>
            </form>
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

Signup.propTypes = {
  getUser: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      background: PropTypes.shape({})
    })
  }).isRequired,
};

export default connect(
  null,
  { getUser }
)(Signup);
