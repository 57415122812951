import { PropsWithChildren, ComponentType } from 'react';
import Header from 'components/Header';

import styles from './DefaultLayout.module.scss'

const DefaultLayout = ({ children, backgroundColor }: PropsWithChildren<{ backgroundColor: string; }>) => {
  return (
    <div className={styles.DefaultPageLayout} style={{ backgroundColor }}>
      <div className="page-wrapper with-floating">
        <Header backgroundColor={backgroundColor} />
        <div className="container d-flex">
          {children}
        </div>
      </div>
    </div>
  );
}

const withDefaultLayout = <T extends {}>(
  WrappedComponent: ComponentType<T>,
  color: string,
) => (props: T) => (
  <DefaultLayout backgroundColor={color}>
    <WrappedComponent {...props} />
  </DefaultLayout>
);

export default withDefaultLayout;