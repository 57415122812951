import { ReactElement, useEffect, useState } from "react";
import history from "services/history";
import red from "@material-ui/core/colors/red";
import { useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  AlertProps,
  Grid,
  Typography,
} from "@material-ui/core";
import Button from "components/Button";
import RecordService from "services/digital-passport/record.service";
import Goal from "models/DigitalPassport/Goal";
import Record from "models/DigitalPassport/Record";
import RecordSlot from "./RecordSlot";
import RubricDialog from "./RubricDialog";
import ScheduleSlot from "models/ScheduleSlot";
import moment from "moment";
import RedirectBanner from "./RedirectBanner";

function TrackStudentDigitalPassportPage() {
  const { studentId } = useParams<{ studentId: string }>();

  const [records, setRecords] = useState<Array<Record>>();
  const [studentName, setStudentName] = useState<string>();
  const [studentGoals, setStudentGoals] = useState<Array<Goal>>();
  const [studentSchedules, setStudentSchedules] =
    useState<Array<ScheduleSlot & { id: number }>>();
  const [dataLoadingFailed, setDataLoadingFailed] = useState<boolean>(false);

  const [rubricOpen, setRubricOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<{
    severity: AlertProps["severity"];
    message: string | ReactElement;
  }>();

  useEffect(() => {
    async function start() {
      try {
        const startDateString = moment(moment.utc(), "MM/DD/YYYY")
          .startOf("day")
          .toISOString();
        const endDateString = moment(moment.utc(), "MM/DD/YYYY")
          .endOf("day")
          .toISOString();
        const recordData = await RecordService.getRecordData(
          Number(studentId),
          startDateString,
          endDateString
        );

        setRecords(recordData.records);
        setStudentName(recordData.studentName);
        setStudentGoals(recordData.studentGoals);
        setStudentSchedules(recordData.studentSchedules);
      } catch (e) {
        console.error(e);
        setDataLoadingFailed(true);
      }
    }
    start();
  }, [studentId]);

  if (dataLoadingFailed) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={red[500]}
        height="100%"
      >
        Unable to fetch student data. Try to select student from the classroom.
      </Box>
    );
  }

  if (
    records === undefined ||
    studentName === undefined ||
    studentGoals === undefined ||
    studentSchedules === undefined
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  if (studentGoals.length === 0) {
    return (
      <RedirectBanner
        studentId={studentId}
        studentsPageSectionKey="dp-goals"
        title="Please fill out your student’s goals and objectives to begin using the digital passport."
        actionText="Add Goals"
      />
    );
  }

  if (studentSchedules.filter(x => x.location).length === 0) {
    return (
      <RedirectBanner
        studentId={studentId}
        studentsPageSectionKey="dp-schedule"
        title="Please fill out your student’s schedules to begin using the digital passport."
        actionText="Add Schedules"
      />
    );
  }

  return (
    <div>
      <h1>
        <span>Digital Passport,</span>
        &nbsp;
        {studentName}
      </h1>
      <Typography textAlign="center">
        {moment().format("dddd, MMMM Do YYYY")}
      </Typography>
      <Box
        pt={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box maxWidth="1200px" width="100%" sx={{ px: { xs: 0, sm: 2 } }}>
          <Grid
            container
            spacing={3}
            pt={3}
            pb={4}
            display={{ xs: "none", md: "flex" }}
          >
            <Grid item xs={12} sm>
              <Typography variant="h6" textAlign="center">
                Goal/Objective
              </Typography>
            </Grid>
            <Grid item xs={12} sm>
              <Typography variant="h6" textAlign="center">
                Class/Setting
              </Typography>
            </Grid>
            <Grid item xs={12} sm>
              <Typography variant="h6" textAlign="center">
                Points
              </Typography>
            </Grid>
          </Grid>
          {studentGoals.map((goal) => (
            <RecordSlot
              key={goal.id}
              goal={goal}
              schedules={studentSchedules}
              records={records}
              onRecordsChange={(records) => {
                setRecords(records);
              }}
              setAlert={setAlert}
            />
          ))}
        </Box>
        <Box mt={6}>
          <Button
            size="large"
            color="white"
            variant="contained"
            onClick={() => {
              setRubricOpen(true);
            }}
          >
            Point Rubric
          </Button>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!alert}
        autoHideDuration={6000}
        onClose={() => setAlert(undefined)}
      >
        {alert && (
          <Alert
            elevation={6}
            variant="filled"
            onClose={() => setAlert(undefined)}
            severity={alert.severity}
          >
            {alert.message}
          </Alert>
        )}
      </Snackbar>
      <RubricDialog
        studentId={studentId}
        open={rubricOpen}
        onClose={() => setRubricOpen(false)}
        setAlert={setAlert}
      />
    </div>
  );
}

export default TrackStudentDigitalPassportPage;
