import { Link } from 'react-router-dom';
import {
    Box, Grid, Typography
} from '@material-ui/core';

import Button from 'components/Button';

interface Props {
    studentId: number;
    percent: number;
    targetBehaviorCount: number;
    allIncidentsCount: number;
    title: string;
    viewDetailsButton: boolean;
    isTargetPage: boolean;
}


function TargetBehaviorBox( {studentId, percent, targetBehaviorCount, allIncidentsCount, title, viewDetailsButton, isTargetPage} : Props) {
    

    const getViewDetailsButton = () => {
        const targetBehavior = encodeURIComponent(title);

        return (
            <Box>
                <Link
                    to={{
                        pathname: `/classroom/${studentId}/report/${targetBehavior}`
                    }}
                >
                    <Button 
                        size="medium"
                        color="white"
                        variant="contained"
                        sx={{mb: 1}}
                    >
                        View details
                    </Button>
                </Link>
            </Box>
        )
    }

    return (
            <Grid item xs={6} sm={6} md={4} lg={isTargetPage ? 2 : 3}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center', 
                        alignItems: 'center',
                        border: '3px solid #fff',
                        height: 300,
                        overflow: 'hidden',
                        flexGrow: 2,
                    }}
                >
                    <Box sx={{
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            bgcolor: '#000', 
                            width: '100%', 
                            borderBottom: '3px solid #fff', 
                            height: 140,
                            }}  
                            
                    >
                        <Typography 
                            variant="body1" 
                            sx={{
                                fontSize: {xs: 14, md: 18},
                                textAlign: 'center',
                                pr: {xs: 1, sm: 4},
                                pl: {xs: 1, sm: 4},
                                maxHeight: 140,
                        }}>
                            {title}
                        </Typography>
                    </Box>
                    <Box sx={{
                            display: 'flex', 
                            flexDirection: "column",
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            width: '100%', 
                            height: "100%",
                            }}>
                        <Typography 
                            variant="h5" 
                            sx={{ 
                                fontSize: {xs: 25, md:40},
                                mt: 3, 
                            }}
                        >
                            {percent}%
                        </Typography>
                        <Box sx={{
                                minWidth: '55%', 
                                textAlign: 'center',
                                border: '1px solid #fff'
                            }}></Box>
                        <Typography 
                            variant="body1"
                            sx={{ 
                                fontSize: 20,
                                mb: 3, 
                            }}
                        >
                            {targetBehaviorCount}/{allIncidentsCount}
                        </Typography>
                        {viewDetailsButton && getViewDetailsButton()}
                    </Box>
                </Box>
            </Grid>
    );
}

export default TargetBehaviorBox;