import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNull, map } from 'lodash';
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  withRouter
} from 'react-router-dom';
import {
  Box, CircularProgress,
} from '@material-ui/core';

import { getUser, updateUser } from 'redux/actions/auth';

import HomePage from 'pages/HomePage';
import StartPage from 'pages/StartPage';
import withDefaultLayout from 'components/DefaultLayout';
import { ROUTER_CONFIG, ROUTER_CONFIG_MODALS } from 'router.config';
import { matchPath } from "react-router";
import authService from 'services/auth.service';

const script = document.createElement('script');
script.src = 'https://growsurf.com/growsurf.js?v=2.0.0';
script.setAttribute('grsf-campaign', 'h8fdgv');
script.async = true;

class App extends Component {
  constructor(props) {
    super(props);
    this.initGrowSurf = this.initGrowSurf.bind(this);
  }

  componentDidMount() {
    this.props.getUser();

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      e.prompt();
    }, true);

    document.head.appendChild(script);
    window.addEventListener('grsfReady', this.initGrowSurf);
  }

  componentDidUpdate() {
    if (this.props.userData && window.growsurf && !this.props.userData.growsurfInit) {
      this.initGrowSurf();
    }
  }
 
  async initGrowSurf() {
    window.removeEventListener('grsfReady', this.initGrowSurf);
    if (this.props.userData) {
      const hash = window.localStorage.getItem('growsurf-token');
      const { email } = this.props.userData;
      window.growsurf.init({ email, hash });
      this.props.updateUser({ growsurfInit: true });
    }
  }

  render() {
    const background = this.props.location.state && this.props.location.state.background;
    const isModal = map(ROUTER_CONFIG_MODALS, 'path')
      .filter(path => matchPath(this.props.location.pathname, {
          path: path,
          exact: true,
          strict: false
        }) !== null)
      .length > 0;
    if (isModal && !background) {
      this.props.history.replace(
        this.props.location.pathname,
        {
          background: {
            ...this.props.location,
            pathname: '/'
          },
          from: this.props.location.state?.from ?? "",
        }
      );
    }

    if (this.props.userDataLoading) {
      return <Box sx={{ m: 'auto' }}><CircularProgress color="inherit" size={50} /></Box>;
    }

    const logInRequiredRedirect = <Redirect
      to={{
        pathname: '/login',
        state: { 
          background: {
            ...this.props.location,
            pathname: '/'
          },
          from: this.props.location.pathname
        }
      }}
    />;

    if ((authService.isTrialExpired(this.props.userData)
        || authService.isMembershipExpired(this.props.userData))
      && this.props.location.pathname !== "/pricing") {
      return <Redirect to="/pricing" />;
    }

    const defaultRedirect = <Redirect to="/" />;

    return (
      <>
        <Switch location={background || this.props.location}>
          { ROUTER_CONFIG.map((route) => (isNull(route.auth) || (route.auth && this.props.userData)
            || (!route.auth && !this.props.userData) ? (
              <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
            ) : null))}
          <Route exact path="/" component={this.props.userData ? withDefaultLayout(StartPage, '#f48d55') : HomePage} />
          {
            map(ROUTER_CONFIG.concat(ROUTER_CONFIG_MODALS), 'path')
              .filter(path => matchPath(this.props.location.pathname, {
                  path: path,
                  exact: true,
                  strict: false
                }) !== null)
              .length > 0
                ? logInRequiredRedirect
                : defaultRedirect
          }
        </Switch>
        {isModal && (
          <Switch>
            {ROUTER_CONFIG_MODALS.map((route) => (isNull(route.auth) || (route.auth && this.props.userData)
              || (!route.auth && !this.props.userData) ? (
                <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
              ) : null))}
            {defaultRedirect}
          </Switch>
        )}
      </>
    );
  }
}

App.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      background: PropTypes.shape({})
    })
  }).isRequired,
  userDataLoading: PropTypes.bool.isRequired,
  userData: PropTypes.shape({
    email: PropTypes.string,
  }),
  getUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

App.defaultProps = {
  userData: null
};

const mapStateToProps = (state) => ({
  userDataLoading: state.auth.loading,
  userData: state.auth.userData,
});

export default connect(
  mapStateToProps,
  { getUser, updateUser }
)(withRouter(App));
