import { IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { DIMENSIONS } from 'assets/constants';
import OperationalDefinition from 'models/OperationalDefinition';

import DimensionSelect from 'components/DimensionSelect';
import { useState } from 'react';
import styles from './OperationalDefinitionSlot.module.scss';

interface Props {
  opDefinition: OperationalDefinition;
  index: number;
  disabledTargetBehaviors: string[];
  update: (opDefinition: OperationalDefinition, index: number) => void;
  remove: (index: number) => void;
}

function OperationalDefinitionSlot({ opDefinition, index, disabledTargetBehaviors, update, remove }: Props) {
  const [targetBehavior, setTargetBehavior] = useState<string>(opDefinition.targetBehavior);
  const [behaviorDefinition, setBehaviorDefinition] = useState<string>(opDefinition.behaviorDefinition);
  const [errorText, setErrorText] = useState<string | null>(null);

  const targetBehaviorDimention = DIMENSIONS.filter(x => x.name == 'Target Behavior')[0];

  const updateTargetBehavior = (val: string | null) => {
    if (val === null) {
      return;
    }
    setTargetBehavior(val)

    update({ 
      targetBehavior: val,
      behaviorDefinition: behaviorDefinition,
    }, index)
  }

  const updateDefinition = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const updatedDefinition = e.target.value;

    if (updatedDefinition.length === 0) {
      setErrorText("")
    } else {
      setErrorText(null);
    }

    setBehaviorDefinition(updatedDefinition)

    update({ 
      targetBehavior: targetBehavior,
      behaviorDefinition: updatedDefinition,
    }, index)
  };

  const removeDefinition = (e: React.MouseEvent) => {
    e.preventDefault();

    remove(index);
  }

  return (
    <div className={styles.Container}>
      <div>
        <div className={styles.DimensionsCell}>
          <DimensionSelect
            dimension={{ 
              ...targetBehaviorDimention,
              getOptionDisabled: (option: string) => disabledTargetBehaviors.includes(option),
            }}
            value={targetBehavior}
            setDimension={updateTargetBehavior}
            freeSolo={false}
          />
        </div>
        <TextField
          type="text"
          fullWidth
          margin="normal"
          label="Operational Definition"
          name="name"
          value={behaviorDefinition}
          onChange={updateDefinition}
          error={errorText !== null}
          helperText={errorText}
          multiline
          maxRows={3}
          inputProps={{ maxLength: 255 }}
        />
      </div>
      <IconButton
        size="small"
        onClick={removeDefinition}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  )
}

export default OperationalDefinitionSlot;