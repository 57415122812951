import Student from 'models/Student';
import User from 'models/User';
import moment from 'moment';
import { ReportRootLevelType, ReportType } from 'pages/ReportingSelectPage/ReportTypeSelect';
import { API_ENDPOINT, userApi, trackingApi, digitalPassportApi } from './api.routes';
import IncidentType from './../models/IncidentType';

export async function downloadIncidentsCsv(
  studentData: {
    studentId: Student['id'];
    startDate: string;
    endDate: string;
    type: ReportType;
  },
  userData: User
) {
  const { email } = userData;
  const startDateFormatted = moment(studentData.startDate, 'MM/DD/YYYY').toISOString();
  const endDateFormatted = moment(studentData.endDate, 'MM/DD/YYYY').endOf('day').toISOString();
  const { studentId, type } = studentData;

  window.analytics.track('Created Report', {
    studentId,
    startDate: startDateFormatted,
    endDate: endDateFormatted,
    type,
  });

  if (window.growsurf) {
    window.growsurf.addParticipant({ email });
    window.growsurf.triggerReferral({ email });
  }

  const token = window.localStorage.getItem('tracce-token');

  const form = document.createElement('form');
  const element1 = document.createElement('input');
  const element2 = document.createElement('input');
  const element3 = document.createElement('input');
  const element4 = document.createElement('input');

  form.target = '_blank';
  form.method = 'POST';
  form.action = getActionUrl(studentId, type);

  element1.type = 'hidden';
  element1.name = 'x-user-jwt';
  element1.value = token!;
  form.appendChild(element1);

  element2.type = 'hidden';
  element2.name = 'startedAt';
  element2.value = startDateFormatted;
  form.appendChild(element2);

  element3.type = 'hidden';
  element3.name = 'endedAt';
  element3.value = endDateFormatted;
  form.appendChild(element3);

  const elementType = getType(type);
  if (elementType !== null) {
    element4.type = 'hidden';
    element4.name = 'type';
    element4.value = elementType;
    form.appendChild(element4);
  }

  document.body.appendChild(form);

  form.submit();
  form.remove();
}

export function downloadUserDataCsv() {
  const token = window.localStorage.getItem('tracce-token');

  const form = document.createElement('form');
  const element1 = document.createElement('input');

  form.target = '_blank';
  form.method = 'POST';
  form.action = `${API_ENDPOINT}${userApi.userExport}`;

  element1.type = 'hidden';
  element1.name = 'x-user-jwt';
  element1.value = token!;
  form.appendChild(element1);

  document.body.appendChild(form);

  form.submit();
  form.remove();
}

function getActionUrl(studentId: number, reportType: ReportType) {
  let path = "";

  switch (reportType) {
    case ReportRootLevelType.DigitalPassport:
      path = digitalPassportApi.studentRecordsExport({ studentId });
      break;
    case ReportRootLevelType.AllIncidentTypes:
    case IncidentType.AbcIncident:
    case IncidentType.FrequencyIncident:
    case IncidentType.IntervalIncident:
      path = trackingApi.incidentsExport({ studentId });
      break;
    default:
      throw new Error();
  }

  return `${API_ENDPOINT}${path}`;  
}

function getType(reportType: ReportType) {
  switch (reportType) {
    case ReportRootLevelType.AllIncidentTypes:
      return "";
    case IncidentType.AbcIncident:
    case IncidentType.FrequencyIncident:
    case IncidentType.IntervalIncident:
      return reportType;
    default:
      return null;
  }
}
