import {
  Grid,
  TextField,
  Select,
  MenuItem,
  makeStyles,
  AlertProps,
  Typography,
  Box,
  useMediaQuery
} from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import BaseInput from "components/BaseInput";
import Goal from "models/DigitalPassport/Goal";
import ScheduleSlot from "models/ScheduleSlot";
import Record from "models/DigitalPassport/Record";
import ScoreSlider, { UNDEFINED_SCORE_VALUE } from "./ScoreSlider";
import { ReactElement, useState } from "react";
import RecordService from "../../../../services/digital-passport/record.service";
import moment from "moment";
import { orderBy } from 'lodash';

const muiStyles = (theme: any) => ({
  textField: {
    marginTop: 0,
    marginBottom: 0,
  },
  input: {
    backgroundColor: "#ffffffb3",
    "&:hover": {
      backgroundColor: "#ffffffb3",
    },
    "&.Mui-focused": {
      backgroundColor: "#ffffffb3",
    },
    "& input": {
      textOverflow: "ellipsis",
    }
  },
});

interface Props {
  goal: Goal;
  schedules: Array<ScheduleSlot & {id: number}>;
  records: Array<Record>;
  onRecordsChange: (records: Array<Record>) => void;
  setAlert: (
    value: React.SetStateAction<
      | {
          severity: AlertProps["severity"];
          message: string | ReactElement;
        }
      | undefined
    >
  ) => void;
}

function RecordSlot({
  goal,
  schedules,
  records,
  onRecordsChange,
  setAlert,
}: Props) {
  const futureSchedule = orderBy(
    schedules.filter(x => moment().isAfter(moment(x.timeframe, "HH:mm").utc())),
    [ 'timeframe' ],
    [ 'desc' ]);
  
  const [scheduleId, setScheduleId] = useState<number | undefined>(
    futureSchedule.length === 0 ? undefined : futureSchedule[0].id);

  const findRecordIfExists = (scheduleId: number | undefined): Record | undefined =>
    records.find(
      (r) =>
        r.goalId === goal.id && r.scheduleId === scheduleId
    );
  const [score, setScore] = useState<number | null>(
    findRecordIfExists(scheduleId)?.score ?? null
  );

  const classes = makeStyles(muiStyles)();

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const onScoreChange = (score: number) => {
    setScore(score);
  };

  const onChangeCommitted = async (score: number) => {
    if (!scheduleId) {
      return;
    }

    setScore(score);

    const recordsCopy = [...(records || [])];
    const record = findRecordIfExists(scheduleId);
    if (record) {
      record.score = score;
    } else {
      recordsCopy.push({
        goalId: goal.id,
        scheduleId: scheduleId,
        score,
      });
    }

    onRecordsChange(recordsCopy);

    try {
      if (score === UNDEFINED_SCORE_VALUE) {
        await RecordService.deleteRecord(goal.id, scheduleId);
      } else {
        await RecordService.createOrUpdateRecord({
          goalId: goal.id,
          scheduleId,
          score,
        });
      }
      setAlert({
        severity: "success",
        message: `Successfully saved score for a class.`,
      });
    } catch (e) {
      console.error(e);
      setAlert({
        severity: "error",
        message: "Error ocurred while trying to save score for a class.",
      });
    }
  };

  const onScheduleIdChange = async (scheduleId: number) => {
    setScheduleId(scheduleId);
    setScore(findRecordIfExists(scheduleId)?.score ?? null);
  };

  const whiteBoxStyles = { border: "3px solid white", px: 3, pt: 2, pb: 4, mb: 5 };
  return (
    <Box sx={isSmallDevice ? whiteBoxStyles : {}}>
      <Grid container spacing={2} py={1}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="caption" display={{ sx: "block", md: "none" }}>
            Goal/Objective
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Enter the student's goal"
            name="name"
            value={goal.name}
            disabled
            className={classes.textField}
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="caption" display={{ sx: "block", md: "none" }}>
            Class/Setting
          </Typography>
          <Select
            value={scheduleId}
            onChange={(event) => {
              onScheduleIdChange(Number(event.target.value));
            }}
            displayEmpty
            fullWidth
            variant="outlined"
            input={<BaseInput />}
          >
            {schedules
              .filter((s) => s.location)
              .map((schedule) => (
                <MenuItem value={schedule.id!}>
                  {moment(schedule.timeframe, 'HH:mm').format('hh:mm a')} - {schedule.location}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box px={1}>
            <ScoreSlider
              disabled={scheduleId === undefined}
              value={score}
              onChange={onScoreChange}
              onChangeCommitted={onChangeCommitted}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default RecordSlot;
