import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';

export interface ConfirmationProps {
  open?: boolean;
  title: string;
  text: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
  onCancel?: () => void;
  disablePortal?: boolean;
};

const ConfirmationModal = ({
  confirmText = 'OK',
  cancelText = 'Cancel',
  disablePortal = false,
  open = false,
  title,
  text,
  onConfirm,
  onClose,
  onCancel,
}: ConfirmationProps) => (
  <Dialog
    open={open}
    onClose={onClose || onCancel}
    aria-labelledby=""
    aria-describedby=""
    maxWidth="md"
    disablePortal={disablePortal}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText dangerouslySetInnerHTML={{ __html: text }} />
    </DialogContent>
    <DialogActions>
      {onCancel ? (
        <Button onClick={onCancel} color="primary">
          {cancelText}
        </Button>
      ) : null}
      {onConfirm ? (
        <Button onClick={onConfirm} color="primary" autoFocus>
          {confirmText}
        </Button>
      ) : null}
    </DialogActions>
  </Dialog>
);

export default ConfirmationModal;
