import { Select, MenuItem, Typography } from "@material-ui/core";
import BaseInput from "components/BaseInput";
import IncidentType, { IncidentTypeName } from "models/IncidentType";

interface Props {
  value: ReportType;
  onChange: (reportType: ReportType) => void;
}

export enum ReportRootLevelType {
  AllIncidentTypes = "AllIncidentTypes",
  DigitalPassport = "DigitalPassport",
}

export type ReportType = ReportRootLevelType | IncidentType;

export const ReportTypeName = {
  ...IncidentTypeName,
  [ReportRootLevelType.AllIncidentTypes]: "All Incident Types",
  [ReportRootLevelType.DigitalPassport]: "Digital Passport",
};

const getMenuItem = (type: ReportType, level?: "root" | "leaf") => (
  <MenuItem value={type}>
    <Typography
      pl={level === "leaf" ? 3 : 0}
      fontWeight={level === "root" ? "bold" : ""}
    >
      {ReportTypeName[type]}
    </Typography>
  </MenuItem>
);

export default function ReportTypeSelect({ value, onChange }: Props) {
  const onSelectChange = (
    event: React.ChangeEvent<{
      value: ReportType;
    }>
  ) => {
    console.log("change", event.target.value);
    onChange(event.target.value);
  };

  return (
    <Select
      value={value}
      onChange={onSelectChange}
      displayEmpty
      renderValue={(selected) => {
        console.log("selected", selected);
        return ReportTypeName[selected];
      }}
      fullWidth
      variant="outlined"
      sx={{ mb: 3 }}
      input={<BaseInput />}
    >
      <MenuItem disabled value="">
        Select Incident Type
      </MenuItem>
      {getMenuItem(ReportRootLevelType.AllIncidentTypes)}
      {getMenuItem(IncidentType.AbcIncident, "leaf")}
      {getMenuItem(IncidentType.FrequencyIncident, "leaf")}
      {getMenuItem(IncidentType.IntervalIncident, "leaf")}
      {getMenuItem(ReportRootLevelType.DigitalPassport)}
    </Select>
  );
}
