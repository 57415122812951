import React, { useEffect, useState, SyntheticEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import history from 'services/history';
import moment from 'moment';
import {
  Box,
  Modal,
  CircularProgress,
  Typography,
  Autocomplete,
} from '@material-ui/core';
import Button from 'components/Button';
import TextField from 'components/TextField';
import AddStudentModal from 'components/AddStudentModal';
import { addStudent, getStudents,getAllStudents } from 'redux/actions/classroom';
import ClassroomService from 'services/classroom.service';
import RootState from 'redux/RootState';
import Student from 'models/Student';

import styles from './SelectStudentPage.module.scss';

import { some, truncate } from 'lodash';
import ConfirmationModal, { ConfirmationProps } from 'components/ConfirmationModal';


const mapState = (state: RootState) => ({
  students: state.classroom.students,
});

const mapDispatch = { addStudent, getStudents,getAllStudents };

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

function SelectStudentPage ({ students, ...actions }: PropsFromRedux) {
  const [modalVisible, setModalVisible] = useState(false);
  const [isclass,setIsClass] =useState(false)
  const [studentToAddId,setStudentToAddId] =useState(-1)
  const [search, setSearch] = useState({
    loading: false,
    open: false,
  });
  const [options, setOptions] = useState<Student[]>([]);

  useEffect(() => {
    if (!students) {
      actions.getStudents();
    }
  }, [actions, students]);

  const searchStudents = async (e: any) => {
    setSearch({
      open: true,
      loading: true,
    });
    setOptions([]);

    let students = [];

    try {
      const response = await ClassroomService.searchStudents(e.target.value);
      students = (response as any).students;
    } catch (error) {
      console.log(error);
    }

    setSearch({
      open: true,
      loading: false,
    });
    setOptions(students);
  }

  const selectStudent = async (e: SyntheticEvent<Element, Event>, student: Student | null) => {

    if (student) {
      
    const isInClassroom = some(students, ['id', student.id]);
     if(isInClassroom) {
      setStudentToAddId(-1)
      history.push(`/tracking/${student.id}`);
     } else {
      setIsClass(true)
      setStudentToAddId(student.id)
     }
    }
  }

  const addStudent = async (studentData: Student) => {
    try {
      const student = await actions.addStudent(studentData);
      history.push(`/tracking/${student.id}`);
    } catch (error) {
      //console.log(error);
    }
  }

  const noConfirmation = () => {
    setIsClass(false)
  }

  
  const yesConfirmation = async() => {
    try {
      const response = await ClassroomService.addToClassroom(studentToAddId);
      students = (response as any).students;
      setStudentToAddId(-1)      
      setIsClass(false)
      history.push(`/tracking/${studentToAddId}`);
    } catch (error) {
      setStudentToAddId(-1)
      //console.log(error);
      setIsClass(false)
    }

    
  }



  return (
    <Box className={styles.TrackingSelectPage}>
      <h1>
        <span>Select a </span>
        Student
      </h1>
      <Box sx={{ maxWidth: '500px', ml: 'auto', mr: 'auto' }}>
        <Autocomplete
          id="students"
          options={options}
          loading={search.loading}
          getOptionLabel={(option) => option.name}
          noOptionsText="Type to search student by name"
          onChange={selectStudent}
          renderOption={(optionProps, option) => (
            <div {...optionProps as any}>
              <Typography variant="body2" color="textPrimary">
                {option.name}
                <br />
                <small style={{ fontSize: '80%' }}>
                  Case Manager:
                  &nbsp;
                  {option.caseManager || '-'}
                  <br />
                  Date of birth:
                  &nbsp;
                  {option.birthDate ? moment(option.birthDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : '-'}
                </small>
              </Typography>
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={searchStudents}
              placeholder="Student's name"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {search.loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
      <p>or</p>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          size="large"
          color="white"
          variant="contained"
          onClick={() => setModalVisible(true)}
        >
          Add Student
        </Button>
      </Box>
      <Modal
        aria-labelledby=""
        aria-describedby=""
        open={!!modalVisible}
        onClose={() => setModalVisible(false)}
      >
        <>
          <AddStudentModal
            classroomStudents={students}
            addStudent={addStudent}
            close={() => setModalVisible(false)}
          />
        </>
      </Modal>


      <ConfirmationModal
            open={!!isclass}
            title="Add Student To Class"
            text="Hi, Looks like this student already exists but you have not yet added them to your classroom. If you'd like to track and report on this student, you can add them now"
            confirmText="Yes"
            cancelText="No"
            onConfirm={yesConfirmation}
            onCancel={noConfirmation}
          />
          
      

    </Box>
  );
}

export default connector(SelectStudentPage)