import {
    Box, Typography
} from '@material-ui/core';
import red from '@material-ui/core/colors/red';

interface Props {
    count: string;
    title: string;
}


function StatisticInfo({count, title} : Props) {
    const textColor = count.includes('%') && count.includes('-') ? red[300] : '#fff';


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: {xs: 157, sm: 250, md: 300},
            }}    
        >
            <Box
                sx={{
                    height: {xs: 150, sm: 200},
                    mt: 2,
                    border: '3px solid #fff',
            }}>
                <Typography 
                    variant="body1" 
                    sx={{
                        fontSize: {xs: 14, sm: 18},
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 60,
                        textAlign: 'center', 
                        p: 1,
                        lineHeight: 1,
                        bgcolor: '#000',
                        borderBottom: '3px solid #fff'
                }}>
                    {title}
                </Typography>

                <Box sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            textAlign: 'center',
                            height: {xs: 90, sm: 140},
                    }}>
                    <Typography 
                        variant="h5" 
                        sx={{ 
                            display: 'flex',
                            fontSize: {xs: 30, sm: 50},
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            color: textColor
                    }}>
                        {count}
                    </Typography>
                </Box>
            </Box>
        </Box>
        
    );
}

export default StatisticInfo;