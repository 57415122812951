import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import DateFnsAdapter from '@material-ui/lab/AdapterMoment';
import { LocalizationProvider } from '@material-ui/lab';
import MUI_THEME from 'assets/mui-theme.custom';
import history from 'services/history';
import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from './redux/store';

import 'assets/styles/index.scss';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <StrictMode>
    <MuiThemeProvider theme={MUI_THEME}>
      <LocalizationProvider dateAdapter={DateFnsAdapter}>
        <Provider store={store}>
          <Router history={history}>
            <App />
          </Router>
        </Provider>
      </LocalizationProvider>
    </MuiThemeProvider>
  </StrictMode>,
  rootElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
