import { traceApiClient } from "../api.service";
import { digitalPassportApi } from "../api.routes";
import Record from "models/DigitalPassport/Record";
import Goal from "models/DigitalPassport/Goal";
import ScheduleSlot from "models/ScheduleSlot";

export interface RecordData {
  records: Record[];

  studentName: string;
  studentGoals: Array<Goal>;
  studentSchedules: Array<ScheduleSlot & {id: number}>;
}

function RecordService() {
  async function getRecordData(
    studentId: number,
    startDate: string,
    endDate: string
  ): Promise<RecordData> {
    const query = `?startDate=${startDate}&endDate=${endDate}`;

    const result = await traceApiClient.get<RecordData>(
      `${digitalPassportApi.studentRecords({ studentId })}${query}`
    );
    return result.data;
  }

  async function createOrUpdateRecord(
    record: Record
  ): Promise<void> {
    await traceApiClient.post(
      digitalPassportApi.todaysRecord({ 
        goalId: record.goalId,
        scheduleId: record.scheduleId,
      }),
      { score: record.score }
    );
  }

  async function deleteRecord(
    goalId: number,
    scheduleId: number
  ): Promise<void> {
    await traceApiClient.delete(
      digitalPassportApi.todaysRecord({ goalId, scheduleId }),
    );
  }

  return {
    getRecordData,
    createOrUpdateRecord,
    deleteRecord,
  };
}

export default RecordService();
