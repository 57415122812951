import { AlertProps } from '@material-ui/core';
import Button from 'components/Button';
import { debounce } from 'lodash';
import OperationalDefinition from 'models/OperationalDefinition';
import { ReactElement, useCallback, useState } from 'react';
import OperationalDefinitionSlot from './OperationalDefinitionSlot';

import styles from './OperationalDefinitions.module.scss';

import { DIMENSIONS } from 'assets/constants';

interface Props {
  definitions: OperationalDefinition[];
  update: (opDefinitions: OperationalDefinition[]) => void;
  setAlert: (value: React.SetStateAction<{
    severity: AlertProps['severity'];
    message: string | ReactElement;
  } | undefined>) => void
}

function OperationalDefinitions({ definitions, update, setAlert }: Props) {
  const [opDefinitions, setOpDefinitions] = useState<OperationalDefinition[]>(definitions);

  const targetBehaviorDimention = DIMENSIONS.filter(x => x.name == 'Target Behavior')[0];

  const debouncedUpdate = useCallback(debounce(update, 500), []);

  const updateOperationalDefinition = (opDefinition: OperationalDefinition, index: number) => {
    const updatedOpDefinitions = [...opDefinitions];
    updatedOpDefinitions[index] = opDefinition;
    setOpDefinitions(updatedOpDefinitions);

    debouncedUpdate(updatedOpDefinitions);
  };

  const removeOperationalDefinition = (index: number) => {
    const updatedOpDefinitions = [...opDefinitions]
    updatedOpDefinitions.splice(index, 1)
    setOpDefinitions(updatedOpDefinitions)

    debouncedUpdate(updatedOpDefinitions);
  };

  const addSlot = () => {
    if (opDefinitions.length >= 4) {
      setAlert({
        severity: 'warning',
        message: 'No more than 4 operational definitions can be defined at the time.'
      });
      return;
    }

    var defaultTargetBehavior = targetBehaviorDimention.options.filter(x =>
      opDefinitions.filter(y => y.targetBehavior == x).length === 0)[0];

    setOpDefinitions([...opDefinitions, {
      targetBehavior: defaultTargetBehavior,
      behaviorDefinition: "",
    }])
    update(opDefinitions);
  }

  return (
    <>
      <div className={styles.Slot}>
        {opDefinitions.map((opDefinition, index) => (
          <OperationalDefinitionSlot
            key={`${index}-${opDefinition.targetBehavior}`}
            opDefinition={opDefinition}
            index={index}
            disabledTargetBehaviors={opDefinitions.map(x => x.targetBehavior)}
            update={updateOperationalDefinition}
            remove={removeOperationalDefinition}
          />
        ))}
      </div>
      <div className={styles.AddButtonContainer}>
        <Button
          size="large"
          color="white"
          variant="contained"
          onClick={addSlot}
          disabled={opDefinitions.length >= 4}
        >
          Add Definition
        </Button>
      </div>
    </>
  )
}

export default OperationalDefinitions;