import { SyntheticEvent, useState, ChangeEvent } from 'react';
import {
  TextField, Autocomplete,
} from '@material-ui/core';
import { traceApiClient } from 'services/api.service';
import { schoolApi } from 'services/api.routes';

import ISchool from 'models/School';
import { DEFAULT_SCHOOL } from 'assets/constants';
import { useEffect } from 'react';

interface Props {
  defaultValue?: ISchool;
  onSelect: (e: SyntheticEvent, school: ISchool | null) => void;
}

const SchoolsSearch = ({ defaultValue, onSelect }: Props) => {
  const [search, setSearch] = useState({
    loading: false,
    open: false,
  });
  const [defaultOption, setDefaultOption] = useState<ISchool>(DEFAULT_SCHOOL);
  const [options, setOptions] = useState<ISchool[]>([]);

  useEffect(() => {
    const getDefaultOption = async () => {
      try {
        const { data } = await traceApiClient.get<ISchool>(schoolApi.defaultSchool);
        if (data.id === DEFAULT_SCHOOL.id) {
          setDefaultOption(data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    getDefaultOption();
  }, []);

  const searchSchool = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!e.target.value) {
      setOptions([]);
      return;
    }

    setSearch((prevState) => ({
      ...prevState,
      loading: true,
    }));

    try {
      const { data } = await traceApiClient.get<{ schools: ISchool[], total: number; }>(schoolApi.schools, { params: { search: e.target.value, limit: 0 }});
      setOptions(data.schools)
    } catch (error) {
      console.error(error);
    }

    setSearch({
      loading: false,
      open: true,
    });
  }

  return (
    <Autocomplete
      id="schools"
      defaultValue={defaultValue}
      options={[...options, defaultOption]}
      loading={search.loading}
      getOptionLabel={(option) => option.name}
      noOptionsText="Type to search school by name"
      onChange={onSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          margin="normal"
          helperText="Select Default if your school is not in list"
          onChange={searchSchool}
          placeholder="School's name"
        />
      )}
    />
  )
}

export default SchoolsSearch;