import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  Box, Modal, IconButton, Snackbar, Alert, AlertProps,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmationModal from 'components/ConfirmationModal';
import Button from 'components/Button';
import AddStudentModal from 'components/AddStudentModal';

import { getStudents, addStudent, removeStudent } from 'redux/actions/classroom';
import { prepareFullImagePath } from 'services/api.helpers';
import RootState from 'redux/RootState';

import styles from './ClassroomPage.module.scss';
import Student from 'models/Student';
import StudentProfile from 'components/StudentProfile/StudentProfile';

const mapState = (state: RootState) => ({
  studentsError: state.classroom.error,
  students: state.classroom.students
});

const mapDispatch = { getStudents, addStudent, removeStudent };

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

function ClassroomPage({
  students,
  getStudents,
  addStudent: addStudentAction,
  removeStudent: removeStudentAction,
}: PropsFromRedux) {
  const location = useLocation<{ isAddModalVisible: boolean; }>();
  const [alert, setAlert] = useState<{ severity: AlertProps['severity']; message: string; }>();
  const [isAddModalVisible, setIsAddModalVisible] = useState(location.state?.isAddModalVisible);
  const [studentRemoval, setStudentRemoval] = useState<Student>();

  useEffect(() => {
    getStudents();
  }, [getStudents]);

  const hideAlert = () => {
    setAlert(undefined);
  }

  const removeFromClass = async () => {
    if (!studentRemoval) return;
    await removeStudentAction(studentRemoval.id);
    setStudentRemoval(undefined);
  }

  const renderConfirmationModal = () => {
    if (!studentRemoval) return;

    const { id, name } = studentRemoval;
    return (
      <ConfirmationModal
        open={!!id}
        title="Delete Student"
        text={`Are you sure you want to remove ${name} from your class? 
          <br /> You will have the ability to add the student back at a later date`}
        onConfirm={removeFromClass}
        onCancel={() => setStudentRemoval(undefined)}
      />
    );
  }

  const addStudent = async (studentData: Student) => {
    const student = await addStudentAction(studentData);

    if (student.id) {
      setAlert({
        severity: 'success',
        message: 'Student added successfully'
      });
      return;
    }
    setAlert({
      severity: 'error',
      message: 'Something went wrong. Failed to add student.'
    });
  }

  return (
    <>
      <Box className={styles.ClassroomPage}>
        <h1>
          <span>Welcome to </span>
          Classroom
        </h1>
        <div className={styles.StudentsListWrapper}>
          <div className={styles.StudentsList}>
            {students?.map((student) => (
              <Link
                to={`/classroom/${student.id}`}
                key={student.id}
              >
                <StudentProfile name={student.name} avatarUrl={student.avatarUrl}/>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setStudentRemoval(student);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Link>
            ))}
            <div className={styles.AddButtonContainer}>
              <Button
                size="large"
                color="white"
                variant="contained"
                onClick={() => setIsAddModalVisible(true)}
              >
                Add Student
              </Button>
            </div>
          </div>
        </div>
      </Box>
      {renderConfirmationModal()}
      <Modal
        aria-labelledby=""
        aria-describedby=""
        open={!!isAddModalVisible}
        onClose={() => setIsAddModalVisible(false)}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <>
          <AddStudentModal
            classroomStudents={students}
            addStudent={addStudent}
            close={() => setIsAddModalVisible(false)}
          />
        </>
      </Modal>
      {alert ? (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={!!alert} autoHideDuration={6000} onClose={hideAlert}>
          <Alert elevation={6} variant="filled" onClose={hideAlert} severity={alert.severity}>
            {alert.message}
          </Alert>
        </Snackbar>
      ) : null }
    </>
  );
}

export default connector(ClassroomPage);
