import { traceApiClient } from './api.service';
import { userApi } from './api.routes';
import User from 'models/User';
import CountyMembershipPlan, { PaidCountyMembershipPlans } from 'models/payments/CountyMembershipPlan';
import moment from 'moment';
import UserMembershipPlan from 'models/payments/UserMembershipPlan';
import County from './../models/County';
import { StrictMode } from 'react';

function saveToken(token: string, growsurfToken: string) {
  window.localStorage.setItem('tracce-token', token);
  window.localStorage.setItem('growsurf-token', growsurfToken);
}

function AuthService() {
  function signup(userData: User) {
    return traceApiClient.post<User>(userApi.user, userData).then((response) => {
      const {
        email, id, name, username,
      } = response.data;

      if (window.growsurf) {
        window.growsurf.addParticipant({
          email,
          id,
          name,
          username,
        });
      }

      window.analytics.track('Account SignUp', {
        email,
        id,
        name,
        username,
      });

      return response;
    });
  }

  function login(userData: User) {
    return traceApiClient.post<{ accessToken: string; growsurfToken: string; }>(userApi.userLogin, userData)
      .then((response) => saveToken(response.data.accessToken, response.data.growsurfToken));
  }

  function requestPasswordReset(username: string) {
    return traceApiClient.post(userApi.userRequestPasswordReset, { username });
  }

  function resetPassword(token: string, password: string, passwordRepeat: string) {
    return traceApiClient.post<{ accessToken: string; growsurfToken: string; }>(userApi.userPasswordReset({ token }), { 
      password,
      passwordRepeat,
    })
      .then((response) => saveToken(response.data.accessToken, response.data.growsurfToken));
  }

  function logout() {
    window.localStorage.removeItem('tracce-token');
    window.localStorage.removeItem('growsurf-token');
    window.analytics.reset();
    window.FullStory.anonymize();
    if (window.growsurf) {
      window.growsurf.logout();
    }
  }

  function getUser() {
    return traceApiClient.get<User>(userApi.user).then((response) => {
      const {
        id, email, name, username,
      } = response.data;
      const userVars = {
        email,
        displayName: name,
        name,
        username,
      };
      window.analytics.identify(id, userVars);
      window.FullStory.identify(id, userVars);

      return response.data;
    })
    .catch((e) => {
      if (window.growsurf) {
        window.growsurf.logout();
      }
      throw e;
    });
  }

  function updateUser(userData: User) {
    return traceApiClient.put(userApi.user, userData).then(response => response.data);
  }

  function deleteUserData() {
    return traceApiClient.delete(userApi.userData).then(response => response.data);
  }

  function deleteUser(userData: User) {
    return traceApiClient.delete<User>(userApi.user).then((response) => {
      const {
        id, email, name, username,
      } = userData;
      window.analytics.track('Account Delete', {
        id, email, name, username,
      });

      return response.data;
    });
  }

  function isTrialExpired(userData: User) {
    if (!userData?.school?.county) {
      return false;
    }

    const isTrialPlan = userData.membershipPlan === UserMembershipPlan.CountyPlan
      && userData.school.county.membershipPlan === CountyMembershipPlan.Trial;
    if (!isTrialPlan) {
      return false;
    }

    return isPastUtcDate(userData.school.county.trialEndsAt);
  }

  function isMembershipExpired(userData: User) {
    if (!userData?.school?.county) {
      return false;
    }

    switch (userData.membershipPlan) {
      case UserMembershipPlan.CountyPlan:
        return isCountyMembershipExpired(userData.school.county);
      case UserMembershipPlan.Individual:
        return isPastUtcDate(userData.membershipExpiresAt);
      default:
        throw new Error(`Not implemented handler for ${userData.membershipPlan} user membership plan.`)
    }
  }

  function isCountyMembershipExpired(county: County) {
    if (!PaidCountyMembershipPlans.includes(county.membershipPlan)) {
      return false;
    }
    return isPastUtcDate(county.membershipExpiresAt);
  }

  function isPastUtcDate(date: Date) {
    if (!date) {
      return false;
    }

    const now = moment();
    return moment(date).utc().isBefore(now);
  }

  return {
    signup,
    login,
    requestPasswordReset,
    resetPassword,
    logout,
    getUser,
    updateUser,
    deleteUserData,
    deleteUser,
    isTrialExpired,
    isMembershipExpired,
  };
}

export default AuthService();
