import { traceApiClient } from "../api.service";
import { digitalPassportApi } from "../api.routes";
import Goal from "models/DigitalPassport/Goal";

function DigitalPassportGoalService() {
  async function getStudentGoals(
    studentId: number
  ): Promise<Array<Goal>> {
    const result = await traceApiClient.get<Goal[]>(
      digitalPassportApi.studentGoals({ studentId })
    );
    return result.data;
  }

  async function updateStudentGoals(
    studentId: number,
    goals: Array<Goal>
  ): Promise<Array<Goal>> {
    const result = await traceApiClient.post<Array<Goal>>(
      digitalPassportApi.studentGoals({ studentId }),
      { goals }
    );
    return result.data;
  }

  return {
    getStudentGoals,
    updateStudentGoals,
  };
}

export default DigitalPassportGoalService();
