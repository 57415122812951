import { traceApiClient } from './api.service';
import { trackingApi } from './api.routes';
import Student from 'models/Student';
import Incident from 'models/Incident';
import IncidentType from 'models/IncidentType';

function TrackingService() {
  function createIncident(studentId: Student['id'], incidentData: Omit<Incident, 'id'>) {
    return traceApiClient.post<Incident>(trackingApi.incidents({ studentId }), incidentData).then((response) => {
      (window as any).analytics.track('Tracked Student', {
        studentId,
        incidentId: response.data.id,
      });
      return response.data;
    });
  }

  function getStudentIncidents(studentId: Student['id'], dates: { startDate: string; endDate: string }, incidentType: IncidentType) {
    const query = `?startedAt=${dates.startDate}&endedAt=${dates.endDate}&limit=0&type=${incidentType}`;
    return traceApiClient.get<{ incidents: Incident[] }>(`${trackingApi.incidents({ studentId })}${query}`).then(response => response?.data);
  }

  function updateIncident(studentId: Student['id'], incidentId: Incident['id'], incidentData: Incident) {
    return traceApiClient.put(trackingApi.incident({ studentId, incidentId }), incidentData);
  }

  return {
    createIncident,
    getStudentIncidents,
    updateIncident
  };
}

export default TrackingService();
