import { Link, useLocation } from 'react-router-dom';
import {
  Grid, Box, Button} from '@material-ui/core';
import Header from 'components/Header';
import Banner from 'assets/img/home-page-banner.png';
import Banner2x from 'assets/img/home-page-banner@2x.png';

import styles from './HomePage.module.scss';

const BACKGROUND_COLOR = "#66cdcc";

const HomePage = () => {
  const location = useLocation();

  return (
    <Box className={`page-wrapper ${styles.HomePage}`} sx={{ backgroundColor: BACKGROUND_COLOR }}>
      <Header backgroundColor={BACKGROUND_COLOR} />
      <Grid container alignItems="stretch">
        <Grid item lg={5} xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <div className="container">
              <h1>
                Automate Behavioral Tracking
                <small>Taking Data Has Never Been Easier</small>
              </h1>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Link
                  to={{
                    pathname: '/signup',
                    state: { background: location }
                  }}
                >
                  <Button size="large" variant="contained" color="primary">
                    Sign Up for free
                  </Button>
                </Link>
              </Box>
            </div>
          </Box>
        </Grid>
        <Grid item lg={7} xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              height: '100%',
            }}
          >
            <img src={Banner} srcSet={`${Banner2x} 2x`} alt="" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePage;
