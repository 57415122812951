import { useState, useEffect, ReactElement } from 'react';
import red from '@material-ui/core/colors/red';
import { Link, useParams } from 'react-router-dom';
import {
  Box, CircularProgress, Grid
} from '@material-ui/core';

import Button from 'components/Button';

import ClassroomService from 'services/classroom.service';
import StudentBehaivorStatistic from 'models/Report';
import StudentProfile from 'components/StudentProfile/StudentProfile';
import ShortBehaivorStatistic from '../../components/ReportBehaviorComponents/ShortBehaviorStatistic';



const ReportHighlightPage = () => {
    const { studentId } = useParams<{ studentId: string }>();
    const [studentBehaivorStatistic, setStudent] = useState<StudentBehaivorStatistic | null>();
   
    useEffect(() => {
        async function loadStudent () {
          try {
            const studentBehaivorStatistic = await ClassroomService.getStudentBehaivorStatistic(Number(studentId));
            setStudent(studentBehaivorStatistic);
          } catch (error) {
            console.log(error);
            setStudent(null);
          }
        }
        loadStudent();

    }, [studentId]);

    if (studentBehaivorStatistic === undefined) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress color="inherit" />
            </Box>
        );
    }

    if (studentBehaivorStatistic === null) {
        return (
            <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            color={red[500]}
            height="100%"
            >
                Unable to fetch student report data. Try to request a student report again.
            </Box>
        );
    }

    return (
        <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", mt: {xs: 5, md: 10}, mx: {xs: 0, md: 8}, mb: 4 }}>
            <Grid container sx={{display: "flex", justifyContent: "center", maxWidth: '1200px', gap: { xs: 3, md: 8 }, flexWrap: "nowrap" }}>
                <Grid item xs="auto">
                    <Box sx={{width: {xs: 150, sm: 200}}}>
                        <StudentProfile name={studentBehaivorStatistic.name} avatarUrl={studentBehaivorStatistic.avatarUrl}/>
                    </Box>
                </Grid>
                <Grid item xs="auto" lg> 
                    <Box display="flex" sx={{
                        gap: 3,
                        width: 1,
                        height: 1,
                        flexDirection: { xs: "column", lg: "row" },
                        justifyContent: { xs: "center", lg: "space-around" },
                        alignItems: { xs: "flex-start", lg: "center" },
                    }}>
                        <ShortBehaivorStatistic count={`${studentBehaivorStatistic.countIncidents}`} description={"total behavior incidents"}/>
                    </Box>
                </Grid>
            </Grid>                 
            <Grid container  sx={{mt: 10, width: "100%", textAlign: "center", alignItems: 'center', justifyContent: 'center'}}>
                <Link 
                    to={`/classroom/${studentBehaivorStatistic.id}/report-highlight/overview`} 
                >
                    <Button 
                        sx={{px: {xs: 1.5, md: 2.5}}}
                        size="large" 
                        color="white" 
                        variant="contained" 
                    >
                        Function of Behavior Report
                    </Button>
                </Link>
            </Grid>
            <Grid container sx={{mt: 2, width: "100%", textAlign: "center", alignItems: 'center', justifyContent: 'center'}}>
                <Link
                    to={{
                        pathname: `/classroom/${studentBehaivorStatistic.id}/reporting`,
                        state: { studentBehaivorStatistic: studentBehaivorStatistic }
                    }}
                    >
                    <Button
                        sx={{px: {xs: 1.5, md: 2.5}}}
                        size="large"
                        color="white"
                        variant="contained"
                    >
                        Export Data
                    </Button>
                </Link>
            </Grid>
        </Box>
      );
}

export default ReportHighlightPage;