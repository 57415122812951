import {
  Box, Typography
} from '@material-ui/core';
import { prepareFullImagePath } from 'services/api.helpers';
import styles from "./StudentProfile.module.scss"

interface Props {
    name: string;
    avatarUrl: string;
  }

const StudentProfile = ({ name, avatarUrl } : Props) => {
    return (
        <Box sx={{
                fontSize: "20px",
                border: "3px solid #fff",
                position: "relative",
                height: 1,
                display: "flex",
                flexDirection: "column",
            }} className={styles.StudentInfo}>
            <img src={prepareFullImagePath(avatarUrl)} alt="" />
            <Typography sx={{
                py: 1,
                px: 3,
                lineHeight: 1.2,
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center"
            }}>
                {name}
            </Typography>
        </Box>
    )
}

export default StudentProfile;