import { useState } from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/core/Autocomplete';
import {
  Dialog, DialogTitle, DialogContent,
  DialogActions, Button
} from '@material-ui/core';
import { SETTINGS } from 'assets/constants';
import TextField from 'components/TextField';

interface Props {
  setSetting: (newValue: string) => void;
  value?: string;
}

const filter = createFilterOptions<string>();

const newValueRegexp = new RegExp('Add "(.*?)"');

const SettingSelect = ({ value, setSetting }: Props) => {
  const [dialog, setDialog] = useState<string>();

  return (
    <>
      <Autocomplete
        freeSolo
        value={value}
        options={SETTINGS}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option);
          if (inputValue !== '' && !isExisting) {
            filtered.push(`Add "${inputValue}"`);
          }
          return filtered;
        }}
        onChange={(event, newValue) => {
          const string = newValue?.match(newValueRegexp);
          if (string) {
            setDialog(string[1]);
            return;
          }
          setSetting(newValue || '');
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="none"
            variant="outlined"
            fullWidth
            placeholder="Select or create setting"
          />
        )}
      />
      {dialog && (
        <Dialog open={true} onClose={() => setDialog(undefined)}>
          <DialogTitle>Add new setting</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              defaultValue={dialog}
              onChange={(e) => setDialog(e.target.value)}
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialog(undefined)}>Cancel</Button>
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                setSetting(dialog);
                setDialog(undefined);
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default SettingSelect;