import axios from 'axios';
import { isString, isArray } from 'lodash';
import { Cache, cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import { API_ENDPOINT } from './api.routes';

const timeout = 30000;
const headers = { 'Cache-Control': 'no-cache' };

function handleError(errResponse: { error: string; message: string | any[] }) {
  let error = {
    constraints: [],
    message: errResponse.error
  };

  if (errResponse.message && isString(errResponse.message)) {
    error.message = errResponse.message;
  }

  if (errResponse.message && isArray(errResponse.message)) {
    error = errResponse.message.reduce((acc, elem, index) => {
      const divider = index === 0 ? '' : ', ';
      acc.constraints = [...acc.constraints, ...Object.keys(elem.constraints)];
      acc.message = acc.message + divider + Object.values(elem.constraints).join(', ');
      return acc;
    }, { constraints: [], message: '' });
  }

  return {
    ...errResponse,
    ...error
  };
}

const requestAuthInterceptor = (config: any) => {
  config.headers.Authorization = `Bearer ${window.localStorage.getItem('tracce-token')}`;

  return config;
};


const responseErrorInterceptor = ({ response }: any) => {
  const errResponse = response && response.data;
  return Promise.reject(handleError(errResponse) || 'An unknown error occurred. Please, try later.');
};

export const traceApiClient = axios.create({
  baseURL: API_ENDPOINT,
  timeout,
  headers,
  adapter:
    axios.defaults.adapter
    && throttleAdapterEnhancer(
      cacheAdapterEnhancer(axios.defaults.adapter, {
        defaultCache: new Cache({ max: 3, maxAge: 3000 }),
      }),
    ),
});

traceApiClient.interceptors.request.use(requestAuthInterceptor);
traceApiClient.interceptors.response.use(
  undefined,
  responseErrorInterceptor
);