import { traceApiClient } from './api.service';
import { shortLinksApi } from './api.routes';

function ShortLinkService() {
  function createShortLink(originalLink: string) {
    return traceApiClient.post(shortLinksApi.createShortLink, { originalLink }).then((response) => {
      const linkId = response.data;
      return `${window.location.origin}/link/${linkId}`;
    });
  }

  function getOriginalLink(linkId: string) {
    return traceApiClient.get(shortLinksApi.getOriginalLink({ linkId })).then((response) => {
      return response.data;
    });
  }

  return {
    createShortLink,
    getOriginalLink,
  };
}

export default ShortLinkService();
