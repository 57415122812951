import { Button, ClickAwayListener, Typography } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from 'react-router';
import RootState from 'redux/RootState';
import history from 'services/history';

import styles from './SuccessfulPaymentThankYou.module.scss';

const mapState = (state: RootState) => ({
  userData: state.auth.userData,
});

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const SuccessfulPaymentThankYou = ({ userData }: PropsFromRedux) => {
  const location = useLocation();

  const closeModal = () => history.push((location?.state as any)?.background ?? "/");
  console.log("success");
  return (
    <div className={styles.AppModalPageWrapper}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={closeModal}
      >
        <div className={styles.AppPageModal}>
          <Typography variant='subtitle2' textAlign="center" gutterBottom>
            Thank you for the purchase!
          </Typography>
          <Button variant='contained' fullWidth onClick={closeModal}>
            Explore Propellrz
          </Button>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default connector(SuccessfulPaymentThankYou);
