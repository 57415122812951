import {
  Alert,
  AlertTitle,
  Box,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import RootState from "redux/RootState";
import PaymentService from "../../services/payment.service";
import Product from "models/Product";
import ProductSelector from "./ProductSelector";
import { Redirect } from "react-router-dom";
import CountyMembershipPlan, { PaidCountyMembershipPlans } from '../../models/payments/CountyMembershipPlan';
import authService from "services/auth.service";
import UserMembershipPlan from "models/payments/UserMembershipPlan";
import SubscriptionType from "models/payments/SubscriptionType";

const mapState = (state: RootState) => ({
  userData: state.auth.userData,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

const PricingPage = ({ userData }: PropsFromRedux) => {
  const [error, setError] = useState<string>();

  const subscribeToProduct = async (product: Product) => {
    try {
      var checkoutUrl = await PaymentService.getCheckoutUrl(product.priceId);

      if (!checkoutUrl) {
        setError("Can not find a link to the checkout page.");
        return;
      }

      window.location.replace(checkoutUrl);
    } catch (e) {
      setError(
        "An error occurred while trying to retrieve a link to the checkout page."
      );
      console.error(e);
    }
  };

  if (userData === null || userData === undefined) {
    return <Redirect to="/pricing" />;
  }

  // is on county membership but the user is not the one who paid for it (not admin)
  if (userData.membershipPlan === UserMembershipPlan.CountyPlan
    && PaidCountyMembershipPlans.includes(userData.school.county.membershipPlan)
    && userData.id !== userData.school.county.membershipAdminId) {
    const isMembershipExpired = authService.isMembershipExpired(userData);
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="90%">
        <Typography align="center" variant="h5" gutterBottom>
          {
            isMembershipExpired 
              ? "Oh no! Your county subscription has expired."
              : "Hooray! You are on an active county subscription."
          }
        </Typography>
        <Typography align="center" variant="caption">
          {
            isMembershipExpired 
              ? "Contact your county admin to renew your subscription or contact Propellrz support to change the subscription to an individual plan."
              : "In case of questions, don't hesitate to contact your county admin."
          }
        </Typography>
      </Box>
    );
  }

  const expiredTrialAlert = <Box display="flex" justifyContent="center">
    <Alert severity="warning" sx={{ my: 3 }}>
      <AlertTitle>Oh no! It looks like your trial has expired.</AlertTitle>
      Click on the chat widget, or select a plan to start propelling your students to success!
    </Alert>
  </Box>;

  const expiredMembershipAlert = <Box display="flex" justifyContent="center">
    <Alert severity="warning" sx={{ my: 3}}>
      <AlertTitle>Oh no! It looks like your subscription has expired.</AlertTitle>
      Click on the chat widget, or select a plan to start propelling your students to success!
    </Alert>
  </Box>;

  const getHeader = () => {
    return userData.customerId === null || userData.customerId === undefined
      ? <h1><span>Select a</span> Pricing Plan</h1>
      : <h1><span>Manage</span> Pricing Plan</h1>
  }

  const getCurrentUserPlan = (): SubscriptionType | null => {
    if (userData.membershipPlan === UserMembershipPlan.Individual) {
      return SubscriptionType.Individual;
    }
    if (userData.membershipPlan === UserMembershipPlan.CountyPlan) {
      const county = userData.school.county;
      if (county.membershipPlan === CountyMembershipPlan.SmallCounty) {
        return SubscriptionType.SmallCounty;
      }
      if (county.membershipPlan === CountyMembershipPlan.LargeCounty) {
        return SubscriptionType.LargeCounty;
      }
    }
    return null;
  }

  return (
    <Box>
      {getHeader()}
      {authService.isTrialExpired(userData) && expiredTrialAlert}
      {authService.isMembershipExpired(userData) && expiredMembershipAlert}
      <ProductSelector
        currentUserPlan={getCurrentUserPlan()} 
        onSelect={subscribeToProduct}
      />
      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default connector(PricingPage);
