import withDefaultLayout from 'components/DefaultLayout';
import withPublicLayout from 'components/PublicLayout';

import Login from 'pages/Auth/Login';
import Signup from 'pages/Auth/Signup';
import RequestPasswordReset from 'pages/Auth/RequestPasswordReset';
import Profile from 'pages/Profile';

import PrivacyPage from 'pages/PrivacyPage';
import FeaturesPage from 'pages/FeaturesPage';

import TrackingSelectStudentPage from 'pages/Tracking/SelectStudentPage';
import TrackingTrackStudentPage from 'pages/Tracking/TrackStudentPage';
import ReportingSelectPage from 'pages/ReportingSelectPage';
import ReportingResultPage from 'pages/ReportingResultPage';
import ClassroomPage from 'pages/ClassroomPage';
import StudentPage from 'pages/StudentPage';
import ShortLinkPage from 'pages/ShortLinkPage';
import PasswordReset from 'pages/Auth/PasswordReset';
import PricingPage from 'pages/PricingPage/PricingPage';
import SuccessfulPaymentThankYou from 'pages/SuccessfulPaymentThankYou';
import TrackStudentDigitalPassportPage from 'pages/Tracking/TrackStudentDigitalPassportPage';
import ReportHighlightPage from 'pages/ReportHighlightPage';
import ReportBehaviorOverviewPage from 'pages/ReportBehaviorOverviewPage';
import SearchStudentPage from 'pages/SearchStudentPage';
import ReportTargetBehaviorOverviewPage from 'pages/ReportTargetBehaviorPage';

export const ROUTER_CONFIG = [
  {
    path: '/privacy',
    component: withPublicLayout(PrivacyPage),
    exact: true,
    auth: null
  },
  {
    path: '/features',
    component: FeaturesPage,
    exact: true,
    auth: null
  },
  {
    path: '/link/:linkId',
    component: withDefaultLayout(ShortLinkPage, '#66cdcc'),
    exact: true,
    auth: null,
  },
  {
    path: '/tracking',
    component: withDefaultLayout(TrackingSelectStudentPage, '#e8925c'),
    exact: true,
    auth: true
  },
  {
    path: '/tracking/:studentId',
    component: withDefaultLayout(TrackingTrackStudentPage, '#3ec3b3'),
    exact: true,
    auth: true
  },
  {
    path: '/tracking/:studentId/digital-passport',
    component: withDefaultLayout(TrackStudentDigitalPassportPage, '#3ec3b3'),
    exact: true,
    auth: true
  },
  {
    path: '/search-reporting-student',
    component: withDefaultLayout(SearchStudentPage, '#3ec3b3'),
    exact: true,
    auth: true
  },
  {
    path: '/classroom/:studentId/reporting',
    component: withDefaultLayout(ReportingSelectPage, '#3ec3b3'),
    exact: true,
    auth: true
  },
  {
    path: '/classroom/:studentId/report',
    component: withDefaultLayout(ReportingResultPage, '#3ec3b3'),
    exact: true,
    auth: true
  },
  {
    path: '/classroom/:studentId/report/:targetBehavior',
    component: withDefaultLayout(ReportTargetBehaviorOverviewPage, '#3ec3b3'),
    exact: true,
    auth: true
  },
  {
    path: '/classroom',
    component: withDefaultLayout(ClassroomPage, '#3ec3b3'),
    exact: true,
    auth: true
  },
  {
    path: '/classroom/:studentId',
    component: withDefaultLayout(StudentPage, '#3ec3b3'),
    exact: true,
    auth: true
  },
  {
    path: '/classroom/:studentId/report-highlight',
    component: withDefaultLayout(ReportHighlightPage, '#3ec3b3'),
    exact: true,
    auth: true
  },
  {
    path: '/classroom/:studentId/report-highlight/overview',
    component: withDefaultLayout(ReportBehaviorOverviewPage, '#3ec3b3'),
    exact: true,
    auth: true
  },
  {
    path: '/pricing',
    component: withDefaultLayout(PricingPage, '#66cdcc'),
    exact: true,
    auth: true
  },
];

export const ROUTER_CONFIG_MODALS = [
  {
    path: '/login',
    component: Login,
    exact: true,
    auth: false
  },
  {
    path: '/signup',
    component: Signup,
    exact: true,
    auth: false
  },
  {
    path: '/request-password-reset',
    component: RequestPasswordReset,
    exact: true,
    auth: false,
  },
  {
    path: '/password-reset/:token',
    component: PasswordReset,
    exact: true,
    auth: false,
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
    auth: true
  },
  {
    path: '/successful-payment',
    component: SuccessfulPaymentThankYou,
    exact: true,
    auth: true,
  }
];
