import { traceApiClient } from "../api.service";
import { digitalPassportApi } from "../api.routes";
import ScoreDefinition from "../../models/DigitalPassport/ScoreDefinition";

function DigitalPassportRubricService() {
  async function getStudentRubric(
    studentId: number
  ): Promise<Array<ScoreDefinition>> {
    const result = await traceApiClient.get<ScoreDefinition[]>(
      digitalPassportApi.studentRubric({ studentId })
    );
    return result.data;
  }

  async function updateStudentRubric(
    studentId: number,
    scoreDefinitions: Array<ScoreDefinition>
  ): Promise<void> {
    await traceApiClient.post<{ scoreDefinitions: ScoreDefinition[] }>(
      digitalPassportApi.studentRubric({ studentId }),
      { scoreDefinitions }
    );
  }

  return {
    getStudentRubric,
    updateStudentRubric,
  };
}

export default DigitalPassportRubricService();
