import { traceApiClient } from './api.service';
import { paymentApi } from './api.routes';

function PaymentService() {
  function getCheckoutUrl(priceId: string) {
    return traceApiClient.post(paymentApi.checkoutSession, { priceId }).then((response) => {
      return response.data;
    });
  }

  function getCustomerPortalUrl() {
    return traceApiClient.post(paymentApi.customerPortal).then((response) => {
      return response.data;
    });
  }

  function getProducts() {
    return traceApiClient.get(paymentApi.getProducts).then((response) => {
      return response.data;
    });
  }

  return {
    getCheckoutUrl,
    getCustomerPortalUrl,
    getProducts,
  };
}

export default PaymentService();
