import moment from 'moment';
import Incident from 'models/Incident';

import styles from '../ReportingResultPage.module.scss';
import { StudentWithDependencies } from 'models/Student';

interface Props {
  student: StudentWithDependencies;
  incident: Incident;
  index: number;
}

const IncidentRowView = ({ student, incident, index }: Props) => (
  <div className={styles.IncidentsTableRow}>
    <div className={styles.IncidentsTableCell}>{index}</div>
    <div className={styles.IncidentsTableCell}>
      <div className={styles.IncidentsTableCellHeading}>Started At</div>
      {moment(incident.startedAt).format('MM/DD/YYYY hh:mm:ssa')}
    </div>
    <div className={styles.IncidentsTableCell}>
      <div className={styles.IncidentsTableCellHeading}>Ended At</div>
      {moment(incident.endedAt).format('MM/DD/YYYY hh:mm:ssa')}
    </div>
    <div className={styles.IncidentsTableCell}>
      <div className={styles.IncidentsTableCellHeading}>Reporter</div>
      {incident.user?.name}
    </div>
    <div className={styles.IncidentsTableCell}>
      <div className={styles.IncidentsTableCellHeading}>Setting</div>
      {incident.setting}
    </div>
    <div className={styles.IncidentsTableCell} style={{ width: 210 }}>
      <div className={styles.IncidentsTableCellHeading}>Target Behavior</div>
      {incident.targetBehavior}
    </div>
    <div className={styles.IncidentsTableCell} style={{ width: 210 }}>
      <div className={styles.IncidentsTableCellHeading}>Operational Definition</div>
      {student.operationalDefinitions.filter(x => x.targetBehavior === incident.targetBehavior)[0]?.behaviorDefinition ?? ""}
    </div>
    <div className={styles.IncidentsTableCell} style={{ width: 210 }}>
      <div className={styles.IncidentsTableCellHeading}>Antecedent</div>
      {incident.antecedent}
    </div>
    <div className={styles.IncidentsTableCell} style={{ width: 210 }}>
      <div className={styles.IncidentsTableCellHeading}>Consequence</div>
      {incident.consequence}
    </div>
    <div className={styles.IncidentsTableCell} style={{ width: 210 }}>
      <div className={styles.IncidentsTableCellHeading}>Student Reaction</div>
      {incident.studentReaction}
    </div>
  </div>
);

export default IncidentRowView;
