import { useState, useEffect, ReactElement } from 'react';
import { isEmpty, last } from 'lodash';
import moment from 'moment';
import red from '@material-ui/core/colors/red';
import { Link, useParams } from 'react-router-dom';
import {
  Box, CircularProgress, Grid, Snackbar, Alert, AlertProps,
} from '@material-ui/core';
import { DIMENSIONS } from 'assets/constants';
import ReactToPrint from "react-to-print";

import Button from 'components/Button';

import ClassroomService from 'services/classroom.service';
import { prepareFullImagePath } from 'services/api.helpers';
import { StudentWithDependencies } from 'models/Student';
import IScheduleSlot from 'models/ScheduleSlot';
import ScheduleSlot, { DEFAULT_TIMEFRAME } from './ScheduleSlot';

import styles from './StudentPage.module.scss';
import OperationalDefinition from 'models/OperationalDefinition';
import OperationalDefinitions from './OperationalDefinitions';
import QuickCard from './QuickCard';
import React from 'react';
import shortLinkService from 'services/short-link.service';
import { Flipper, Flipped } from "react-flip-toolkit";
import { orderBy } from "lodash";
import ScheduleTimeframesGenerator from './ScheduleTimeframesGenerator';
import Section from './Section';
import Goals from './DigitalPassport/Goals';
import Rubric from './DigitalPassport/Rubric';
import { Color } from '@material-ui/lab';
import ConfirmationModal, { ConfirmationProps } from 'components/ConfirmationModal';

const DEFAULT_SLOT = { location: '', timeframe: DEFAULT_TIMEFRAME };

const StudentPage = () => {
  const { studentId } = useParams<{ studentId: string }>();
  const [student, setStudent] = useState<StudentWithDependencies | null>();
  const [shortLink, setShortLink] = useState<string | null>();
  const [alert, setAlert] = useState<{ severity: AlertProps['severity']; message: string | ReactElement; }>();
  const [confirmation, setConfirmation] = useState<ConfirmationProps>({ open: false, title: "", text: ""});
  const [newScheduleSlot, setNewScheduleSlot] = useState<IScheduleSlot | undefined>();
  const [lastFlippedSlot, setLastFlippedSlot] = useState<IScheduleSlot | undefined>();

  let qrCodeCardRef = React.createRef();

  useEffect(() => {
    async function loadStudent () {
      try {
        const student = await ClassroomService.getStudentInfo(Number(studentId));
        setStudent(student);
      } catch (error) {
        console.log(error);
        setStudent(null);
      }
    }
    loadStudent();

    async function loadStudentShortLink () {
      try {
        const link = await shortLinkService.createShortLink(`${window.location.origin}/tracking/${studentId}`);
        setShortLink(link);
      } catch (error) {
        console.log(error);
        setShortLink(null);
      }
    }
    loadStudentShortLink();
  }, [studentId]);

  const generateSchedule = (times: string[]) => {
    if (!student) return;

    const updatedStudent = {
      ...student,
      schedules: times.map((timeframe) => ({ location: '', timeframe })),
    }

    setStudent(updatedStudent);

    updateStudent(updatedStudent);
  }

  const updateOperationalDefinitions = (opDefinitions: Array<OperationalDefinition>) => {
    if (!student) return;

    const targetBehaviorDimention = DIMENSIONS.filter(x => x.name == 'Target Behavior')[0];
    const updatedStudent = { ...student, 
      operationalDefinitions: opDefinitions.filter(x => 
        (x.targetBehavior !== null && x.targetBehavior.length !== 0 && targetBehaviorDimention.options.includes(x.targetBehavior))
        && (x.behaviorDefinition !== null && x.behaviorDefinition.length !== 0)
      ),
    };
    setStudent(updatedStudent);
    
    updateStudent(updatedStudent);
  }

  const updateNewScheduleSlot = (slot: IScheduleSlot, index: number) => {
    if (!student) return;

    if (slot.timeframe === DEFAULT_TIMEFRAME) {
      setNewScheduleSlot(slot);
    } else {
      updateSchedule(slot, student.schedules.length);
      setNewScheduleSlot(undefined);
    }
  }

  const updateSchedule = (slot: IScheduleSlot, index: number) => {
    if (!student) return;

    const toMoment = (date: string) => moment(date, 'HH:mm');

    const isNewTimeUnique = student.schedules
      .filter((x, i) => toMoment(x.timeframe).isSame(toMoment(slot.timeframe)) && i !== index)
      .length === 0;
    if (!isNewTimeUnique) {
      setAlert({
        severity: 'warning',
        message: `Oh no! It looks like there's another class that starts at ${toMoment(slot.timeframe).format('hh:mm A')}.`,
      });
      setStudent({ ...student });
      return;
    }

    student.schedules[index] = slot;

    const schedulesCopy = [...student.schedules];
    const updatedSchedules = orderBy(schedulesCopy.filter(x => x.timeframe !== DEFAULT_TIMEFRAME), [ 'timeframe' ], [ 'asc' ]);

    // schedule with a missing timestamp should always be the last one if any
    const newSchedule = schedulesCopy.filter(x => x.timeframe === DEFAULT_TIMEFRAME)[0];
    if (newSchedule) {
      updatedSchedules.push(newSchedule);
    }

    const updatedStudent = { ...student, schedules: updatedSchedules }
    setStudent(updatedStudent);

    if (!slot.timeframe) return;

    const orderedScheduleIndex = updatedSchedules.indexOf(slot);
    if (index !== orderedScheduleIndex) {
      setLastFlippedSlot(slot);
    }

    if (orderedScheduleIndex !== 0) {
      const previous = toMoment(updatedSchedules[orderedScheduleIndex - 1].timeframe);
      const isNewTimeAfterPreviousSlot = toMoment(slot.timeframe).isAfter(previous);
      if (!isNewTimeAfterPreviousSlot) {
        setAlert({
          severity: 'warning',
          message: `Uh oh! This class period can not start before the student's previous class at ${previous.format('hh:mm A')}.`,
        });
        return;
      }
    }

    if (orderedScheduleIndex !== updatedSchedules.length - 1) {
      const next = toMoment(updatedSchedules[orderedScheduleIndex + 1].timeframe);
      const isNewTimeBeforeNextSlot = toMoment(slot.timeframe).isBefore(next);
      if (!isNewTimeBeforeNextSlot) {
        setAlert({
          severity: 'warning',
          message: `Uh oh! This class period can not start later than the student's next class at ${next.format('hh:mm A')}.`,
        });
        return;
      }
    }

    for (let i = 1; i < updatedSchedules.length; i++) {
      const prev = toMoment(updatedSchedules[i - 1].timeframe);
      const current = toMoment(updatedSchedules[i].timeframe);

      if (prev.isSame(current)) {
        setAlert({
          severity: 'warning',
          message: <>Uh oh! In order to save this change, please go back and duplicated {prev.format('hh:mm A')} class time.<br/> Class times must be unique.</>,
        });
        return;
      }

      if (!prev.isBefore(current)) {
        setAlert({
          severity: 'warning',
          message: <>Uh oh! In order to save this change, please go back and check the order of {prev.format('hh:mm A')} and {current.format('hh:mm A')} class times.<br/> Start times must be in sequential order.</>,
        });
        return;
      }
    }

    updateStudent(updatedStudent);
  }

  const removeNewSlot = () => {
    if (!student) return;
    setNewScheduleSlot(undefined);
  }

  const removeSlot = (index: number) => {
    if (!student) return;

    var updatedSchedules = [...student.schedules];
    updatedSchedules.splice(index, 1)
    const updatedStudent = {
      ...student,
      schedules: updatedSchedules,
    };

    setStudent(updatedStudent);
    updateStudent(updatedStudent);
  }

  const addSlot = () => {
    if (!student) return;
    setNewScheduleSlot(DEFAULT_SLOT);
  }

  const updateStudent = async (student: StudentWithDependencies) => {
    student.schedules = student.schedules.filter(x => x.timeframe !== DEFAULT_TIMEFRAME);

    try {
      await ClassroomService.updateStudentInfo(
        Number(student.id),
        student
      );
      setAlert({
        severity: 'success',
        message: `Student updated successfully`
      });
    } catch (error: any) {
      console.log(error);
      setAlert({
        severity: 'error',
        message: error?.message
      });
    }
  }


  const hideAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert(undefined);
  };

  if (student === undefined || shortLink === undefined) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  if (student === null) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={red[500]}
        height="100%"
      >
        Unable to fetch student data. Try to select student from the classroom.
      </Box>
    );
  }

  return (
    <Box>
      <h1>
        <span>Now viewing,</span>
        {' '}
        {student.name}
        {' '}
      </h1>
      <Box sx={{ maxWidth: '600px', m: 'auto' }} className={styles.StudentInfo} pb={4}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={6}>
            <img src={prepareFullImagePath(student.avatarUrl)} alt="" />
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <div className={styles.CaseManagerBlock}>
                <small>case manager:</small>
                {student.caseManager}
              </div>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} pb={2}>
          <Grid item xs={12} md={4}>
            <Link to={`/tracking/${student.id}`}>
              <Button size="large" color="white" variant="contained">
                Track
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Link
              to={{
                pathname: `/classroom/${student.id}/report-highlight`,
                state: { student: student }
              }}
            >
              <Button
                size="large"
                color="white"
                variant="contained"
              >
                Reporting
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <ReactToPrint
              bodyClass="printBody"
              trigger={() =>
                <Button
                  size="large"
                  color="white"
                  variant="contained"
                  disabled={shortLink === null}
                >
                  Quick Card
                </Button>
              }
              content={() => qrCodeCardRef.current as any}
              documentTitle={`${student.name}OperationalDefinitions`}
            />
          </Grid>
        </Grid>
        <div ref={qrCodeCardRef as any}>
          { shortLink && <QuickCard 
            studentName={student.name}
            studentShortLink={shortLink}
            avatarUrl={student.avatarUrl}
            operationalDefinitions={student.operationalDefinitions}
          /> }
        </div>
      </Box>
      <Section id="op-definitions" title="Operational Definitions">
        <OperationalDefinitions 
          definitions={student.operationalDefinitions}
          update={updateOperationalDefinitions}
          setAlert={setAlert}
        />
      </Section>
      <Section id="dp-goals" title="Digital Passport Goals">
        <Goals
          studentId={studentId}
          setAlert={setAlert}
          setConfirmation={setConfirmation}
        />
      </Section>
      <Section id="dp-rubric" title="Digital Passport Rubric">
        <Rubric studentId={studentId} setAlert={setAlert} />
      </Section>
      <Section id="dp-schedule" title="Schedule">
        <ScheduleTimeframesGenerator 
          regerenate={student.schedules.length > 0}
          onGenerate={generateSchedule}
          setAlert={setAlert}
        />
        <Flipper flipKey={student.schedules.map(x => x.timeframe).join("")}>
          <Grid container spacing={2} display="flex" justifyContent="center" className={styles.ScheduleRow}>
            {student.schedules.map((slot, index) => (
              <Flipped key={slot.timeframe} 
                flipId={slot.timeframe}
                spring="veryGentle"
                transformOrigin=''
                onAppear={(element: HTMLElement, index: number, decisionData) => {
                  element.style.opacity = "1";
                  element.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
              >
                <Grid item xs={12} sm="auto" display="flex" justifyContent="center">
                  <ScheduleSlot
                    updateSchedule={updateSchedule}
                    removeSlot={removeSlot}
                    slot={slot}
                    index={index}
                    flashHighlight={lastFlippedSlot?.timeframe === slot.timeframe}
                    disabledTimes={student.schedules.filter(x => x !== slot).map(x => x.timeframe)}
                    setAlert={setAlert}
                  />
                </Grid>
              </Flipped>
            ))}
            {newScheduleSlot && <Grid item xs={12} sm="auto" display="flex" justifyContent="center">
              <ScheduleSlot
                updateSchedule={updateNewScheduleSlot}
                removeSlot={removeNewSlot}
                slot={newScheduleSlot}
                index={-1}
                disabledTimes={student.schedules.map(x => x.timeframe)}
                setAlert={setAlert}
              />
            </Grid>}
          </Grid>
        </Flipper>
        { student.schedules.length > 0 &&
          <Box display="flex" justifyContent="center" pt={2}>
            <div className={styles.AddButtonContainer}>
              <Button
                size="large"
                color="white"
                variant="contained"
                onClick={addSlot}
                disabled={newScheduleSlot !== undefined}
              >
                Add Setting
              </Button>
            </div>
          </Box>
        }
      </Section>
      {alert && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!alert}
          autoHideDuration={6000}
          onClose={hideAlert}
        >
          <Alert elevation={6} variant="filled" onClose={hideAlert} severity={alert?.severity}>
            {alert.message}
          </Alert>
        </Snackbar>
      )}
      <ConfirmationModal {...confirmation} />
    </Box>
  );

}

export default StudentPage;
