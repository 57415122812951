import React from 'react';
import { Fab as MuiFab, FabProps, Palette, PaletteColor, makeStyles, createStyles } from '@material-ui/core';

const customFabType = ['white', 'success'];

const useStyles = makeStyles((theme) => createStyles({
  color: (props: any): any => ({
    backgroundColor: theme.palette[props.color as keyof Palette] && (theme.palette[props.color as keyof Palette] as PaletteColor).main
  })
}));

interface Props extends FabProps {
  color: any
};

function Fab(props: Props) {
  const { color = 'inherit', className, ...rest } = props;
  const classes = useStyles(props) as any;
  let filterClasses = '';

  const initColor = customFabType.includes(color) ? undefined : color;

  if (!initColor) {
    filterClasses = `${filterClasses} ${classes?.contained}`;
  }

  if (customFabType.includes(color)) {
    filterClasses = `${filterClasses} ${classes.color}`;
  }

  return <MuiFab className={`${filterClasses} ${className || ''}`} color={initColor} {...rest} />;
}

export default Fab;
