import React, { useState, useEffect, useRef } from 'react';
import {CircularProgress, TextField, Popper, Paper, makeStyles, createStyles, Palette, PaletteColor, MenuList, MenuItem, ClickAwayListener} from '@material-ui/core';
import styles from './Autocomplete.scss';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        maxHeight: 200,
        overflowY: 'auto',
        zIndex: 1300
    }
}));

function CustomAutocomplete(props) {
    const {
        onChange,
        onItemClick,
        initialValue = '',
        itemsList = [],
        itemRender,
        initialIsOpen = false,
        openOnFocus = true,
        textfieldProps = {},
        actions, // object with callback hooks - setValue, setList, setOpen, setIsLoading
    } = props;

    const classes = useStyles();

    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ value, setValue ] = useState('');
    const [ list, setList ] = useState(itemsList);
    const [ isOpen, setIsOpen ] = useState(initialIsOpen);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ selectedItem, setSelectedItem ] = useState(null);
    const elRef = useRef();

    if (actions) {
        actions.setValue = function(newValue) {
            setValue(newValue);
        };
        actions.setList = function(newList) {
            setList(newList);
        };
        actions.setOpen = function(newValue) {
            setIsOpen(newValue);
        };
        actions.setIsLoading = function(newValue) {
            setIsLoading(newValue);
        };
        actions.clearSelectedItem = function() {
            setSelectedItem(null);
        };
        // getters
        actions.getValue = function() {
            return value;
        };
        actions.getList = function() {
            return [ ...list ];
        };
        actions.isOpen = function() {
            return isOpen;
        };
        actions.isLoading = function() {
            return isLoading;
        };
    }

    useEffect(() => {
        document.addEventListener('keyup', onKeyDown, false);
        return () => {
            document.removeEventListener('keyup', onKeyDown, false);
        };
    });

    return <>
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => { setIsOpen(false); }}>
            <div ref={elRef} className={'ac-container'} style={styles.acContainer}>
                <TextField
                    {...textfieldProps}
                    onKeyDown={(e) => { if (e.keyCode == 27 && isOpen) { e.stopPropagation(); setIsOpen(false); } }}
                    value={value}
                    onFocus={(e) => { setAnchorEl(e.target); if (openOnFocus && list.length) { setIsOpen(true); } }}
                    onChange={(e) => { setValue(e.target.value); if (onChange) onChange(e); }}
                    InputProps={{
                        endAdornment: (<>
                            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        </>)
                    }}
                />
                {isOpen && <Paper open={anchorEl != null} placement={'bottom-left'} anchorEl={anchorEl} className={classes.paper}>
                    {list.length > 0 && <MenuList>
                        {list.map((item) => { return (<MenuItem selected={selectedItem === item} onClick={() => { setSelectedItem(item); if (onItemClick) onItemClick(item); }}>{itemRender(item)}</MenuItem>); })}
                    </MenuList>}
                    {list.length == 0 && <MenuList><MenuItem disabled>No results</MenuItem></MenuList>}
                </Paper>}
            </div>
        </ClickAwayListener>
    </>;

    function onKeyDown(e) {
        if (!isDescendant(elRef.current, e.target)) {
            // close list
            setIsOpen(false);
        }
    }

    function isDescendant(parent, child) {
        let node = child.parentNode;
        while (node) {
            if (node === parent) {
                return true;
            }
            // traverse up to the parent
            node = node.parentNode;
        }
        // go up until the root but couldn't find the `parent`
        return false;
    }
}



export default CustomAutocomplete;