import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  TextField, Grid, Button, Box, ClickAwayListener,
  CircularProgress, FormHelperText, List, ListItem, ListItemText,
  IconButton, ListItemSecondaryAction, Typography, Alert,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadUserDataCsv } from '../../services/download-csv.helper';
import ConfirmationModal from '../../components/ConfirmationModal';

import { getStudents } from '../../redux/actions/classroom';
import { updateUser, logoutUser } from '../../redux/actions/auth';
import AuthService from '../../services/auth.service';
import Tab from './Tab';
import Tabs from './Tabs';

import styles from './Profile.module.scss';
import ManagePayment from '../../components/Payments/ManagePayment';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.userData.name,
      username: props.userData.username,
      email: props.userData.email,
      schoolId: props.userData.school.id,
      oldPassword: '',
      password: '',
      passwordRepeat: '',
      loading: false,
      errors: [],
      alert: null,
      activeTab: 0
    };
  }

  get isUserChanged() {
    return this.props.userData.name !== this.state.name
    || this.props.userData.username !== this.state.username
    || this.props.userData.email !== this.state.email
    || this.props.userData.schoolId !== this.state.schoolId
    || this.state.password !== '';
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleTabChange = (event, activeTab) => {
    this.setState({
      activeTab
    });
  }

  hideAlert = () => {
    this.setState({
      alert: null
    });
  }

  update = async () => {
    const userData = {
      name: this.state.name,
      username: this.state.username,
      email: this.state.email,
      oldPassword: this.state.oldPassword,
      password: this.state.password,
      passwordRepeat: this.state.passwordRepeat,
      schoolId: this.state.schoolId,
    };

    const isFormValid = this.validateUserData(userData);
    if (!isFormValid) return;

    this.setState({
      loading: true
    });

    try {
      const updatedUser = await AuthService.updateUser(userData);
      if (this.props.userData.school.id !== updatedUser.school.id) {
        this.props.getStudents();
      }
      this.props.updateUser(updatedUser);
      this.setState({
        loading: false,
        password: '',
        passwordRepeat: '',
        alert: {
          type: 'success',
          message: 'Updated successfully'
        }
      });
    } catch (error) {
      this.setState({
        loading: false,
        password: '',
        passwordRepeat: '',
        alert: {
          type: 'error',
          message: error.message
        }
      });
    }
  }

  deleteUserData = async () => {
    try {
      await AuthService.deleteUserData();
      this.props.clearClassroom();
      this.setState({
        confirmation: null,
        alert: {
          type: 'success',
          message: 'Data deleted'
        }
      });
    } catch (error) {
      this.setState({
        confirmation: null,
        loading: false,
        password: '',
        passwordRepeat: '',
        alert: {
          type: 'error',
          message: error.message
        }
      });
    }
  }

  deleteUser = async () => {
    try {
      await AuthService.deleteUser(this.props.userData);
      this.props.logoutUser();
    } catch (error) {
      this.setState({
        confirmation: null,
        loading: false,
        password: '',
        passwordRepeat: '',
        alert: {
          type: 'error',
          message: error.message
        }
      });
    }
  }

  openDataRemovalConfirmation = () => {
    this.setState({
      confirmation: {
        title: 'Data Removal',
        text: 'You are going to delete all your data. It cannot be reverted',
        onConfirm: this.deleteUserData,
        confirmText: 'Delete',
        onCancel: () => this.setState({ confirmation: null })
      }
    });
  }

  openUserRemovalConfirmation = () => {
    this.setState({
      confirmation: {
        title: 'Account Removal',
        text: 'You are going to delete your account. It cannot be reverted',
        onConfirm: this.deleteUser,
        confirmText: 'Delete',
        onCancel: () => this.setState({ confirmation: null })
      }
    });
  }

  validateUserData(userData) {
    const errors = [];
    let errorMessage = 'Please, fill required fields';

    const required = ['name', 'email', 'username', 'schoolId'];

    Object.keys(userData).forEach((key) => {
      if (!userData[key] && required.includes(key)) {
        errors.push(key);
      }
    });

    if (userData.password && !userData.oldPassword) {
      errors.push('passwordRepeat');
      errorMessage = 'To update password you must provide correct old password';
    }

    if (userData.password && userData.password !== userData.passwordRepeat) {
      errors.push('passwordRepeat');
      errorMessage = 'Password doesn\'t match';
    }

    this.setState({
      alert: errors.length ? {
        type: 'error',
        message: errorMessage
      } : null,
      errors
    });

    return !errors.length;
  }

  selectSchool = async (e, school) => {
    if (school) {
      this.setState({
        schoolId: school.id,
      });
    }
  }

  render() {
    return (
      <div className={styles.AppModalPageWrapper}>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => this.props.history.push(this.props.location.state.background)}
        >
          <div className={styles.AppPageModal}>
            {this.state.loading && (
              <Box className={styles.AppModalLoading}>
                <CircularProgress color="primary" />
              </Box>
            )}
            <div className={styles.ProfileHeading}>
              {this.props.userData.name}
            </div>

            <Tabs value={this.state.activeTab} onChange={this.handleTabChange} aria-label="">
              <Tab icon={<PersonIcon />} label="Profile" key="tab1" />
              <Tab icon={<SettingsIcon />} label="Settings" key="tab12" />
              <Tab icon={<CardMembershipIcon />} label="Subscription" key="tab3" />
            </Tabs>

            <div className={styles.AppPageModalContent}>
              {this.state.activeTab === 0 && (
                <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        value={this.state.name}
                        onChange={this.onChange}
                        error={this.state.errors.includes('name')}
                        label="Full Name"
                        fullWidth
                        id="application-name"
                        margin="normal"
                        name="name"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        onChange={this.onChange}
                        value={this.state.username}
                        label="User Name"
                        fullWidth
                        id="application-username"
                        margin="normal"
                        name="username"
                        error={this.state.errors.includes('username')}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        type="email"
                        onChange={this.onChange}
                        value={this.state.email}
                        label="Email"
                        fullWidth
                        id="application-email"
                        margin="normal"
                        name="email"
                        error={this.state.errors.includes('email')}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        onChange={this.onChange}
                        value={this.state.oldPassword}
                        label="Old Password"
                        fullWidth
                        id="application-oldPassword"
                        margin="normal"
                        name="oldPassword"
                      />
                      <FormHelperText>
                        Forgot a password? &nbsp;
                        <Link
                          to={{
                            pathname: '/request-password-reset',
                            state: { background: this.props.location.state.background }
                          }}
                        >
                          Click here
                        </Link>
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        type="password"
                        value={this.state.password}
                        onChange={this.onChange}
                        error={this.state.errors.includes('password')}
                        label="Password *"
                        fullWidth
                        id="application-password"
                        margin="normal"
                        name="password"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        type="password"
                        value={this.state.passwordRepeat}
                        onChange={this.onChange}
                        error={this.state.errors.includes('passwordRepeat')}
                        label="Repeat Password *"
                        fullWidth
                        id="application-password-repeat"
                        margin="normal"
                        name="passwordRepeat"
                      />
                    </Grid>
                  </Grid>
                  {/* <SchoolsSearch onSelect={this.selectSchool} defaultValue={this.props.userData.school} /> */}
                  <TextField
                    type="text"
                    value={this.props.userData.school.name}
                    label="School"
                    fullWidth
                    id="application-school"
                    margin="normal"
                    name="school"
                    inputProps={
                      { readOnly: true, }
                    }
                  />
                  {console.log(this.props.userData.school)}
                  {this.state.alert && (
                    <Alert variant="filled" onClose={this.hideAlert} severity={this.state.alert.type}>
                      {this.state.alert.message}
                    </Alert>
                  )}
                  <Box sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.update}
                      disabled={!this.isUserChanged}
                      fullWidth
                      size="large"
                      role="button"
                      type="submit"
                    >
                      Update
                    </Button>
                  </Box>
                </form>
              )}
              {this.state.activeTab === 1 && (
                <div value={this.state.activeTab}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary="View My Data"
                        secondary="Download CSV"
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={downloadUserDataCsv}>
                          <GetAppIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Delete My Data"
                        secondary="Delete Incidents and Tracking Data"
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={this.openDataRemovalConfirmation}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Delete My Account"
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={this.openUserRemovalConfirmation}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                  {this.state.alert && (
                    <Alert variant="filled" onClose={this.hideAlert} severity={this.state.alert.type}>
                      {this.state.alert.message}
                    </Alert>
                  )}
                </div>
              )}
              {this.state.activeTab === 2 && (
                <div value={this.state.activeTab}>
                  <ManagePayment/>
                </div>
              )}
            </div>
            {!isEmpty(this.state.confirmation) && (
              <ConfirmationModal
                open={!isEmpty(this.state.confirmation)}
                title={this.state.confirmation.title}
                text={this.state.confirmation.text}
                confirmText={this.state.confirmation.confirmText}
                cancelText={this.state.confirmation.cancelText}
                onConfirm={this.state.confirmation.onConfirm}
                onCancel={this.state.confirmation.onCancel}
                disablePortal
              />
            )}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

Profile.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      background: PropTypes.shape({})
    })
  }).isRequired,
  userData: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  updateUser: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  getStudents: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData
});

export default connect(
  mapStateToProps,
  { updateUser, logoutUser, getStudents }
)(Profile);
