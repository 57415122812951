import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "./ProductCard.module.scss";
import Product from "models/Product";
import { LoadingButton } from "@material-ui/lab";
import getSymbolFromCurrency from "currency-symbol-map";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useState } from "react";
import ManagePayment from "components/Payments/ManagePayment";
import SubscriptionType from "models/payments/SubscriptionType";

interface Props {
  product: Product;
  includeTrial?: boolean;
  currentUserPlan: SubscriptionType | null;
  onSelect: (product: Product) => Promise<void>;
}

const ProductCard = ({ product, includeTrial = false, currentUserPlan, onSelect }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>();

  const theme = useTheme();

  const getCardHeader = (productName: string) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        my={2}
        mx={1}
      >
        <Typography variant="h6" textAlign="center">
          {productName}
        </Typography>
      </Box>
    );
  };

  const onActionButtonClick = async () => {
    setIsLoading(true);
    await onSelect(product);
    setIsLoading(false);
  };

  const onContactUsClick = () => {
    // @ts-ignore
    HelpCrunch('openChat');
  };

  const getActionButton = () => {
    if (currentUserPlan && product.type === currentUserPlan) {
      return (
        <ManagePayment />
      );
    }
    if (currentUserPlan && product.type !== currentUserPlan) {
      return (
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          disabled={true}
        >
          Other Plan
        </Button>
      )
    }

    if (product.contactUs) {
      return (
        <Button variant="contained" color="primary" fullWidth
          onClick={onContactUsClick}
        >
          Chat with our team
        </Button>
      );
    }
    
    return (
      <LoadingButton
        fullWidth
        variant="outlined"
        color="primary"
        pending={isLoading}
        onClick={onActionButtonClick}
        sx={{
          "&.Mui-disabled": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            boxShadow: theme.shadows[2],
          },
        }}
      >
        {includeTrial ? "Start Trial" : "Subscribe"}
      </LoadingButton>
    );
  }

  return (
    <Card
      variant="outlined"
      className={styles.PricingCard}
      sx={{ pb: { xs: 0, md: 1 }, height: "100%" }}
    >
      <CardContent>
        {getCardHeader(product.name)}
        <Box px={1} pb={3} sx={{ textAlign: "center" }}>
          {product.contactUs
            ? (
              <Box display="flex" justifyContent="center" alignItems="center" 
                px={1} pb={3} minHeight={96}
              >
                <Typography variant="h5" color={"primary"} lineHeight={1}>
                  Unlimited
                </Typography>
              </Box>
            ) 
            : (
              <Box display="flex" justifyContent="center" pb={3}>
                <Typography variant="h3" color={"primary"}>
                  {getSymbolFromCurrency(product.currency)}
                  {product.monthlyPrice}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  pl={1}
                >
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{
                      visibility: product.type === SubscriptionType.Individual ? "hidden" : "visible",
                    }}
                  >
                    per teacher
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    per month
                  </Typography>
                </Box>
              </Box>
            )
          }
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="body2" color="textSecondary" component="div">
              billed annually
            </Typography>
            {includeTrial &&
              product.freeTrialDays &&
              product.freeTrialDays > 0 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                >
                  {product.freeTrialDays} days free trial
                </Typography>
              )}
          </Box>
        </Box>
        {getActionButton()}
        {product.featureList.length > 0 && (
          <Box mt={4}>
            {product.featureList.map((feature, i) => (
              <Box key={i} display="flex" alignItems="center" my={1.5} mx={1}>
                <CheckCircleIcon
                  fontSize={"small"}
                  className={styles.CheckCircle}
                  sx={{ mr: 1 }}
                />
                <Typography
                  color="textSecondary"
                  lineHeight={1.2}
                  variant="caption"
                  component="p"
                >
                  {feature}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ProductCard;
