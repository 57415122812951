enum CountyMembershipPlan {
  Trial = 'Trial',
  SmallCounty = 'SmallCounty',
  LargeCounty = 'LargeCounty',
};

export const PaidCountyMembershipPlans = [
  CountyMembershipPlan.SmallCounty,
  CountyMembershipPlan.LargeCounty,
];

export default CountyMembershipPlan;
