import { useEffect, useState } from 'react';
import {
  Box, Grid, IconButton, Typography,
} from '@material-ui/core';
import DimensionSelect from 'components/DimensionSelect';
import { DIMENSIONS } from 'assets/constants';
import Dimension from 'models/Dimension';
import IIncident from 'models/Incident';
import InfoIcon from '@material-ui/icons/Info';

import styles from '../TrackStudentPage.module.scss';
import OperationalDefinitionModal from 'components/OperationalDefinitionModal';
import { StudentWithDependencies } from 'models/Student';

type StudentDimensions = { [key in Dimension]?: string; };

interface Props {
  processing: boolean;
  student: StudentWithDependencies | null;
  onUpdate: (callback: (incident?: Omit<IIncident, 'id' | 'setting' | 'startedAt' | 'endedAt' | 'type'>) => Omit<IIncident, 'id' | 'setting' | 'startedAt' | 'endedAt' | 'type'> | undefined) => void;
}

const initDimensions =  DIMENSIONS.reduce((acc: StudentDimensions, dimension) => {
  acc[dimension.id] = '';
  return acc;
}, {});

function AbcIncident ({ student, onUpdate }: Props) {
  const [dimensions, setDimensions] = useState<StudentDimensions>(initDimensions);
  const [openDefinitionsModal, setOpenDefinitionsModal] = useState<boolean>(false);
  
  useEffect(() => {
    onUpdate((prevIncidentData) => ({ ...prevIncidentData, ...dimensions }));
  }, [ onUpdate, dimensions ]);

  const closeDefinitionModal = () => {
    setOpenDefinitionsModal(false);
  }

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', my: 5 }}>
      <Typography variant="h6" component="h2" align="center" mb={3}>Select your ABC components</Typography>
      <Grid container spacing={2} alignItems="center">
        {DIMENSIONS.map((dimension) => (
          <>
            <Grid item xs={12} sm={4} key={dimension.id}>
              <div className={styles.DimensionLabel}>
                {dimension.name}
              </div>
            </Grid>
            <Grid item xs={12} sm={8} className={styles.DimensionContainer}>
              <DimensionSelect
                dimension={dimension}
                value={dimensions[dimension.id as Dimension]}
                setDimension={(val: string | null) => {
                    setDimensions({ ...dimensions, [dimension.id]: val })
                  }
                }
                freeSolo={dimension.id === 'targetBehavior' ? false : true}
              />
              { dimension.id == 'targetBehavior' && <div>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenDefinitionsModal(true);
                    }}
                    sx={{ ml: 1 }}
                  >
                    <InfoIcon />
                  </IconButton>
                  { openDefinitionsModal && <OperationalDefinitionModal student={student} onClose={closeDefinitionModal} /> }
                </div>}
              </Grid>
          </>
        ))}
      </Grid>
    </Box>
  );
}

export default AbcIncident;