import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  TextField, Grid, Button, Box, ClickAwayListener, FormControl, FormControlLabel, Switch, Typography, CircularProgress, Alert
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getUser } from '../../redux/actions/auth';

import AuthService from '../../services/auth.service';

import styles from './Auth.module.scss';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      rememberMe: false,
      loading: false,
      errors: [],
      alert: null,
      alertInfoPopupText: this.props.location.state?.from
        ? 'Please login to access this page'
        : null
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  login = async () => {
    const userData = {
      username: this.state.username,
      password: this.state.password
    };

    const isFormValid = this.validateLoginData(userData);
    if (!isFormValid) return;

    this.setState({
      loading: true
    });

    try {
      await AuthService.login({ ...userData, rememberMe: this.state.rememberMe });
      await this.props.getUser();

      if (this.props.location.state?.from) {
        this.props.history.replace(
          this.props.location.state?.from, {
          ...this.props.location.state.background,
          state: undefined
        });
      } else {
        this.props.history.replace({
          ...this.props.location.state.background,
          state: undefined
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          variant: 'error',
          message: error.message
        }
      });
    }
  }

  validateLoginData(userData) {
    const errors = [];

    Object.keys(userData).forEach((key) => {
      if (!userData[key]) {
        errors.push(key);
      }
    });

    this.setState({
      alert: errors.length ? {
        variant: 'error',
        message: 'Form is not valid'
      } : null,
      errors
    });

    return !errors.length;
  }

  render() {
    return (
      <div className={styles.AppModalPageWrapper}>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => this.props.history.push(this.props.location.state.background)}
        >
          <div className={styles.AppPageModal}>
            <form autoComplete="off" className={styles.AppPageModalContent} onSubmit={(e) => e.preventDefault()}>
              {this.state.loading && (
                <Box className={styles.AppModalLoading}>
                  <CircularProgress color="primary" />
                </Box>
              )}
              <h1>Welcome Back!</h1>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    value={this.state.username}
                    onChange={this.onChange}
                    error={this.state.errors.includes('username')}
                    label="Email / Username"
                    fullWidth
                    id="application-username"
                    margin="normal"
                    name="username"
                    autoFocus
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    value={this.state.password}
                    onChange={this.onChange}
                    error={this.state.errors.includes('password')}
                    label="Password"
                    fullWidth
                    id="application-password"
                    margin="normal"
                    name="password"
                  />
                </Grid>
              </Grid>
              <FormControl margin="normal">
                <FormControlLabel
                  onKeyPress={(event) => {
                    if (event.code === 'Space') {
                      this.onChange({ target: { name: 'rememberMe', value: !this.state.rememberMe } });
                    }
                  }}
                  checked={this.state.rememberMe}
                  onChange={(e) => this.onChange({ target: { name: 'rememberMe', value: e.target.checked } })}
                  value="rememberMe"
                  control={(
                    <Switch
                      color="primary"
                      tabIndex="0"
                    />
                  )}
                  label="Keep me signed in"
                />
              </FormControl>
              {this.state.alert && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" component="div" className={`${this.state.alert.variant}-alert`}>
                    {this.state.alert.message}
                  </Typography>
                </Box>
              )}
              <Box sx={{ mt: 2 }}>
                <Button variant="contained" color="primary" onClick={this.login} fullWidth size="large" role="button" type="submit">
                  Log In
                </Button>
              </Box>
              <Box sx={{ mt: 2 }} component="p">
                Don&apos;t have an account? &nbsp;
                <Link
                  to={{
                    pathname: '/signup',
                    state: { background: this.props.location.state.background }
                  }}
                >
                  Join
                </Link>
              </Box>
              <Box sx={{ mt: 2 }} component="p">
                Forgot a password? &nbsp;
                <Link
                  to={{
                    pathname: '/request-password-reset',
                    state: { background: this.props.location.state.background }
                  }}
                >
                  Click here
                </Link>
              </Box>
            </form>
          </div>
        </ClickAwayListener>
        {this.state.alertInfoPopupText && (
          <Alert variant="filled" severity="info">
            {this.state.alertInfoPopupText}
          </Alert>
        )}
      </div>
    );
  }
}

Login.propTypes = {
  getUser: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      background: PropTypes.shape({})
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
};

export default connect(
  null,
  { getUser }
)(Login);
