import { PropsWithChildren, ComponentType } from 'react';
import Header from 'components/Header';
import { Box } from '@material-ui/core';

import styles from './PublicLayout.module.scss'

const BACKGROUND_COLOR = "#66cdcc";

const PublicLayout = ({ children, backgroundColor }: PropsWithChildren<{ backgroundColor: string; }>) => {
  return (
    <Box className={styles.PublicLayout} sx={{ backgroundColor: backgroundColor }}>
      <div className="page-wrapper">
        <Header backgroundColor={backgroundColor} />
        <div className="container">
          {children}
        </div>
      </div>
    </Box>
  );
}

const withPublicLayout = <T extends {}>(
  WrappedComponent: ComponentType<T>
) => (props: T) => (
  <PublicLayout backgroundColor={BACKGROUND_COLOR}>
    <WrappedComponent {...props} />
  </PublicLayout>
);

export default withPublicLayout;