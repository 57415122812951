import { TextField, withStyles } from '@material-ui/core';

const textFieldStyles = {
  root: {
    overflow: 'hidden',
    '& .MuiInputBase-input': {
      fontSize: 18,
      fontWeight: 300,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 18,
      background: 'rgba(255,255,255,0.7)',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
        boxShadow: 'none',
      }
    },
  },
};

const TraceTextField = withStyles(textFieldStyles)(TextField);

export default TraceTextField;
