import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const UNDEFINED_SCORE_VALUE = -1;

const StyledSlider = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    height: 2,
    padding: "15px 0",
    margin: "5px 0",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.primary.main,
    marginTop: -12,
    marginLeft: -12,
  },
  rail: {
    opacity: 1,
  },
  track: {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  markLabel: {
    color: "#ffffff",
    fontWeight: "bold",
  },
  markActive: {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },

  mark: {
    backgroundColor: "white",
    height: 8,
    width: 8,
    marginTop: -4,
    marginLeft: -4,
    borderRadius: "15px",
  },
}))(Slider);

interface Props {
  disabled?: boolean,
  value: number | null,
  onChange: (score: number) => void,
  onChangeCommitted: (score: number) => void,
}

function ScoreSlider({ disabled = false, value, onChange, onChangeCommitted }: Props) {
  return (
    <StyledSlider
      disabled={disabled}
      value={value ?? UNDEFINED_SCORE_VALUE}
      marks={[
        {
          value: UNDEFINED_SCORE_VALUE,
          label: "",
        },
        {
          value: 0,
          label: "0",
        },
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
      ]}
      valueLabelDisplay="off"
      min={UNDEFINED_SCORE_VALUE}
      max={2}
      onChange={(e, value) => {
        onChange(value as number)
      }}
      onChangeCommitted={(e, value) => {
        onChangeCommitted(value as number)
      }}
      sx={{
        "& .MuiSlider-mark[data-index='0']": {
          display: "none",
        },
        "& .MuiSlider-thumb.Mui-disabled": {
          display: "none",
        },
      }}
    />
  );
}

export default ScoreSlider;
