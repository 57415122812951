import moment, { Moment } from 'moment';
import { AlertProps, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, MenuItem, TextField, Typography } from '@material-ui/core';
import TimePicker from 'rc-time-picker';
import { ReactElement, useState } from 'react';
import Button from 'components/Button';
import TraceTextField from 'components/TextField';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import styles from './ScheduleTimeframesGenerator.module.scss';
import ConfirmationModal from 'components/ConfirmationModal';

interface Props {
  regerenate?: boolean;
  onGenerate: (times: Array<string>) => void;
  setAlert: (value: React.SetStateAction<{
    severity: AlertProps['severity'];
    message: string | ReactElement;
  } | undefined>) => void;
}

export const LENGTHS = [ 30, 45, 60 ];

function ScheduleTimeframesGenerator({ regerenate = false, onGenerate, setAlert }: Props) {
  const [ startTime, setStartTime ] = useState<Moment>(moment('6:00', 'HH:mm'));
  const [ endTime, setEndTime ] = useState<Moment>(moment('16:00', 'HH:mm'));
  const [ length, setLength ] = useState<number>(30);

  const [ confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [ infoDialogOpen, setInfoDialogOpen] = useState(false);

  const onGenerateClick = () => {
    if (!startTime?.isSameOrBefore(endTime)) {
      setAlert({
        severity: 'warning',
        message: 'End time can not be before Start time.'
      });
      return;
    }

    if (regerenate) {
      setConfirmDialogOpen(true);
    } else {
      generate();
    }
  }

  const generate = () => {
    let time = startTime?.clone();

    const times = [];
    while(time?.isSameOrBefore(endTime)) {
      times.push(time.format('HH:mm'));
      time.add(length, 'minutes');
    }

    onGenerate(times);
  };

  return (
    <Box  pb={8}>
      <Grid container spacing={2} sx={{ px: { xs: 2, sm: 5, md: 10, lg: "20%" } }}>
        <Grid item xs={12} md>
          <Box>
            <Typography variant="caption" component="div">
              Start of the day
            </Typography>
            <TimePicker
              className={styles.TimePicker}
              popupClassName={styles.TimePickerPopup}
              showSecond={false}
              value={startTime}
              onChange={(value) => {
                setStartTime(value);
              }}
              format="hh:mm a"
              inputReadOnly
              use12Hours
              placeholder="hh:mm a"
              allowEmpty={false}
              minuteStep={5}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md>
          <Box>
            <Typography variant="caption" component="div">
              End of the day
            </Typography>
            <TimePicker
              className={styles.TimePicker}
              popupClassName={styles.TimePickerPopup}
              showSecond={false}
              value={endTime}
              onChange={(value) => {
                setEndTime(value);
              }}
              format="hh:mm a"
              inputReadOnly
              use12Hours
              placeholder="hh:mm a"
              allowEmpty={false}
              minuteStep={5}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md>
          <Box>
            <Typography variant="caption" component="div">
              Class Length
            </Typography>
            <TraceTextField
              select
              fullWidth
              size="small"
              variant="outlined"
              className={styles.TextField}
              value={length}
              onChange={(e) => setLength(parseInt(e.target.value))}
              sx={{ fontSize: "16px" }}
            >
              {LENGTHS.map(length => (
                <MenuItem key={length} value={length}>
                  {length} min
                </MenuItem>
              ))}
            </TraceTextField>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} display="flex" justifyContent="center" alignItems="center"
          sx={{ mt: regerenate ? 0 : 2 }}
        >
          <Button
            color="white"
            variant={ regerenate ? "outlined" : "contained" }
            size={ regerenate ? "medium" : "large" }
            onClick={onGenerateClick}
            sx={{ px: 4 }}
          >
            { regerenate ? "Regenerate Schedule" : "Generate Schedule" }
          </Button>
            <ConfirmationModal
              open={confirmDialogOpen}
              title={"Are you sure?"}
              text={"Your current progress on schedule configuration will be lost.<br> And replaced with the newly generated schedule."}
              confirmText={"Generate"}
              cancelText={"Cancel"}
              onConfirm={() => { generate(); setConfirmDialogOpen(false); }}
              onCancel={() => setConfirmDialogOpen(false)}
            />
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              setInfoDialogOpen(true);
            }}
            sx={{ color: "#ffffffb3", ml: 1, mr: { xs: 0, sm: "-65px" } }}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Dialog
        open={infoDialogOpen}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ mt: 2 }}>
          Generate Schedule
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            <List sx={{ listStyleType: 'disc', ml: "30px" }}>
              <ListItem sx={{ display: 'list-item' }}>
                <Box fontWeight='bold' display='inline'>Start of the day</Box> - beginning time of the <Box fontWeight='bold' display='inline'>first</Box> class in the schedule.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Box fontWeight='bold' display='inline'>End of the day</Box> - beginning time of the <Box fontWeight='bold' display='inline'>last</Box> class in the schedule.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Box fontWeight='bold' display='inline'>Class Length</Box> - typical time interval (in minutes) between the beginnings of two nearest classes.
              </ListItem>
            </List>
          </Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setInfoDialogOpen(false)} color="primary">
              Close
            </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default ScheduleTimeframesGenerator;