import Dimension from 'models/Dimension';

export const SETTINGS = [
  'Bathroom',
  'Language Arts',
  'Hallway',
  'Reading',
  'Social Studies',
  'Transition',
  'Community Skills',
  'Lunch/Recess',
  'Math',
  'Science',
  'Specials / Connections / Electives',
  'Writing',
  'Other'
];

export const DIMENSIONS: {
  id: Dimension;
  name: string;
  options: string[];
}[] = [
  {
    id: 'antecedent',
    name: 'Antecedent',
    options: [
      'Given Directions/Task Demand', 'Ignored by Adult',
      'Ignored by Peer', 'Object/Activity Denied', 'Object/Activity Removed',
      'Peer Interaction', 'Alone/Independent with Non-work Activity', 'Alone with Nothing'
    ]
  },
  {
    id: 'targetBehavior',
    name: 'Target Behavior',
    options: [
      'Aggression', 'Disruption', 'Elopement',
      'Inappropriate Vocalization/Verbal Responses',
      'Potentially Dangerous to Self', 'Property Destruction', 'Self-Injury',
      'Socially Inappropriate'
    ]
  },
  {
    id: 'consequence',
    name: 'Consequence',
    options: [
      'Adult/Peer Walked Away', 'Direction Removed',
      'Object/Activity Given', 'Physical Intervention', 'Response Blocking',
      'Verbal Redirection', 'Attention from Adult/Peer', 'Ignored by Adult/Peer',
      'Object/Activity not Given or Removed', 'Redirect to Another Activity', 'Student Removal',
      'Visual/Gestural/Model Prompt', 'Other'
    ]
  },
  {
    id: 'studentReaction',
    name: 'Student Reaction',
    options: ['Behavior Continued', 'Behavior Intensified', 'Behavior Stopped / Student Complied']
  }
];

export const DEFAULT_SCHOOL = {
  id: -1,
  name: 'Default',
  typeId: 1,
  county: {
    domain: "propellrz.com",
  }
};

export const MAX_LESSON_TIME = 60;