export enum SubscriptionType {

  /**
   * Stripe's subscription plan for single teacher.
   */
  Individual = 'Individual',

  /**
   * Stripe's subscription plan for a small county.
   * `number_of_teachers` metadata defines how how many teachers max.
   */
  SmallCounty = 'SmallCounty',

  /**
   * Stripe's subscription plan for a large county.
   * `number_of_teachers` metadata defines how how many teachers max.
   */
  LargeCounty = 'LargeCounty',
};

export default SubscriptionType;
