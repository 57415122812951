import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const MUI_THEME = createMuiTheme({
  typography: {
    fontFamily: 'Arial',
    fontSize: 18,
    fontWeightRegular: 500,
    fontWeightBold: 700
  },
  palette: {
    primary: {
      light: '#fab233',
      main: '#f99f00',
      dark: '#ae6f00',
      contrastText: '#fff'
    },
    secondary: {
      light: '#84d7d6',
      main: '#66cdcc',
      dark: '#478f8e',
      contrastText: '#fff'
    },
    white: {
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#000000'
    },
    black: {
      main: '#252525',
      dark: '#252525',
      contrastText: '#ffffff'
    },
    success: {
      light: green['200'],
      main: green['500'],
      dark: green['800'],
      contrastText: '#ffffff'
    },
    error: {
      light: red['200'],
      main: red['500'],
      dark: red['800'],
      contrastText: '#ffffff'
    }
  }
} as ThemeOptions);

export default MUI_THEME;
