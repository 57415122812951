import { useState, useEffect, ReactElement } from 'react';
import red from '@material-ui/core/colors/red';
import { useParams } from 'react-router-dom';
import {
    useMediaQuery, Box, Grid, Typography, CircularProgress
} from '@material-ui/core';
import { DIMENSIONS } from 'assets/constants';

import ClassroomService from 'services/classroom.service';
import { StudentBehaviorStatisticOverview } from 'models/Report';
import { useTheme } from '@material-ui/core/styles';
import StudentProfile from 'components/StudentProfile/StudentProfile';
import TargetBehaviorBox from '../../components/ReportBehaviorComponents/TargetBehaviorBox';
import StatisticInfo from './StatisticInfo';

const ReportBehaviorOverviewPage = () => {
    const { studentId } = useParams<{ studentId: string }>();
    const [studentBehaviorStatisticOverview, setStudent] = useState<StudentBehaviorStatisticOverview | null>();
    const targetBehaviorList = DIMENSIONS.filter(x => x.name == 'Target Behavior')[0];
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    useEffect(() => {
        async function loadStudent () {
          try {
            const studentBehaviorStatisticOverview = await ClassroomService.getStudentBehaviorStatisticOverview(Number(studentId));
            setStudent(studentBehaviorStatisticOverview);
          } catch (error) {
            console.error(error);
            setStudent(null);
          }
        }
        loadStudent();

    }, [studentId]);

    if (studentBehaviorStatisticOverview === undefined) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress color="inherit" />
            </Box>
        );
    }

    if (studentBehaviorStatisticOverview === null) {
        return (
            <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            color={red[500]}
            height="100%"
            >
                Unable to fetch student report data. Try to request a student report again.
            </Box>
        );
    }

    return (
        <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", mt: 4, mx: {xs: 0, md: 3} }}>
            <Grid container spacing={2} direction={isSmallDevice ? 'column' : 'row'} sx={{display: 'flex', justifyContent: 'center'}}>
                <Grid item xs={12} sm={3}>
                    <Grid 
                        container 
                        sx={{display: "flex", 
                            justifyContent: "center", 
                            gap: 2, 
                        }}
                    >
                        <Grid item sx={{display: "flex", justifyContent: "center"}}>
                            <Box sx={{maxWidth: 300}}>
                                { isMobile ?
                                    <h1>
                                        <span>Now viewing,</span>
                                        {' '}
                                        {studentBehaviorStatisticOverview.name}
                                        {' '}
                                    </h1> : <StudentProfile name={studentBehaviorStatisticOverview.name} avatarUrl={studentBehaviorStatisticOverview.avatarUrl}/>
                                }
                            </Box>
                        </Grid>
                        <Grid item lg sx={{mt: -2}}>
                            <Box display="flex" sx={{
                                width: 1,
                                height: 1,
                                flexDirection: { sm: "row", md: "row", lg: 'column' },
                                justifyContent: "center",
                                alignItems: {lg: "center" },
                                gap: {xs: 1, md: 2, lg: 1},
                            }}>
                                <StatisticInfo count={`${studentBehaviorStatisticOverview.countIncidents}`} title={'Total behavior incidents'}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={9} mt={3}>
                    <Typography variant="h5" sx={{textAlign: 'center', mb: 5}}>Select a target Behavior for more details</Typography>
                    <Grid container spacing={isSmallDevice ? 1 : 2} sx={{display: 'flex'}}>
                        {
                            targetBehaviorList.options.map((option, i) => {
                                const matchingIncident = studentBehaviorStatisticOverview.targetBehaviorIncidents.find(
                                    (incident) => incident.targetBehaivor === option
                                );
                                if (matchingIncident) {
                                    return (
                                        <TargetBehaviorBox
                                            key={i}
                                            studentId={studentBehaviorStatisticOverview.id}
                                            percent={matchingIncident.percent}
                                            targetBehaviorCount={matchingIncident.count}
                                            allIncidentsCount={studentBehaviorStatisticOverview.countIncidents}
                                            title={option}
                                            viewDetailsButton={true}
                                            isTargetPage={false}
                                        />
                                    );
                                } else {
                                    return (
                                        <TargetBehaviorBox
                                            key={i}
                                            studentId={studentBehaviorStatisticOverview.id}
                                            percent={0}
                                            targetBehaviorCount={0}
                                            allIncidentsCount={studentBehaviorStatisticOverview.countIncidents}
                                            title={option}
                                            viewDetailsButton={true}
                                            isTargetPage={false}
                                        />
                                    )
                                }
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ReportBehaviorOverviewPage;