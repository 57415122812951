import { Alert, Box, Button, Typography } from '@material-ui/core';
import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import RootState from 'redux/RootState';
import PaymentService from '../../services/payment.service';

const mapState = (state: RootState) => ({
  userData: state.auth.userData,
});

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const ManagePayment = ({ userData }: PropsFromRedux) => {
  const [error, setError] = useState<string>();

  const location = useLocation();

  const manageBilling = async () => {
    const customerPortalUrl = await PaymentService.getCustomerPortalUrl();

    if (!customerPortalUrl) {
      setError("Link to payment customer portal is missing!");
      return;
    }

    window.location.replace(customerPortalUrl);
  }

  if (userData?.customerId === null) {
    return (
      <>
        <Typography color="primary" align="center" variant="subtitle1">Beta Tester</Typography>
        <Link
          to={{
            pathname: '/pricing',
            state: { background: location }
          }}
        ></Link>
      </>
    );
  }

  return (
  <>
    <Button
      variant="contained"
      color="primary"
      onClick={manageBilling}
      fullWidth
      size="large"
      role="button"
      type="submit"
    >
      Manage billing
    </Button>
    {error && (
      <Alert variant="filled" severity="error">
        {error}
      </Alert>
    )}
  </>
);};

export default connector(ManagePayment);
