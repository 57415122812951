import { Typography, Box } from '@material-ui/core';

interface Props {
    count: string,
    description: string
}

function ShortBehaivorStatistic({ count, description }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Typography component={'span'} sx={{
          fontSize: { xs: 25, sm: 40, md: 60 },
          fontWeight: "bold",
          lineHeight: 1,
        }}
      >
        {count}{' '}
        <Typography component={'small'} sx={{fontSize: {xs: 20, sm: 30}, lineHeight: 0.5}}>{description}</Typography>
      </Typography>    
    </Box>
  );
}

export default ShortBehaivorStatistic;