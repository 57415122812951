import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Box, Typography, Slider, IconButton, Modal
} from '@material-ui/core';
import IIncident from 'models/Incident';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { secondsFormat } from 'services/helpers';

const STEPS = [
  {
    value: 5,
    label: '5m',
  },
  {
    value: 10,
    label: '10m',
  },
  {
    value: 15,
    label: '15m',
  },
  {
    value: 20,
    label: '20m',
  },
];

const DEFAULT_SELECTED = 5;

interface Props {
  processing: boolean;
  onUpdate: (callback: (incident?: Pick<IIncident, 'timeInterval' | 'occurrences' | 'results'>) => Pick<IIncident, 'timeInterval' | 'occurrences' | 'results'> | undefined) => void;
}

const data = { soundurl : 'http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3'}

const getSeconds = (val: number) => val * 60;
const getMinutes = (val: number) => val / 60;

function IntervalIncident ({ onUpdate, processing }: Props) {
  const timersStarted = useRef(false);
  const audioRef = useRef<any>();
  const stateRef = useRef<any>({
    timeInterval: getSeconds(DEFAULT_SELECTED),
    total: getSeconds(DEFAULT_SELECTED),
    occurrences: 0
  });

  const timeoutIntervalRef = useRef<number>();
  const timeIntervalRef = useRef<number>();

  const [timeInterval, setTimeInterval] = useState(getSeconds(DEFAULT_SELECTED));
  const [total, setTotal] = useState(getSeconds(DEFAULT_SELECTED));
  const [occurrences, setOccurrences] = useState(0);
  const [results, setResults] = useState(0);
  const [selectionVisibility, setSelectionVisibility] = useState(false);

  useEffect(() => {
    onUpdate(() => ({ timeInterval: getMinutes(stateRef.current.timeInterval), occurrences, results }));
  }, [onUpdate, occurrences, results]);

  const startTimers = useCallback(() => {
    timersStarted.current = true;
    setSelectionVisibility(false);
    setTimeInterval(stateRef.current.timeInterval);
    const newTotal = stateRef.current.total - stateRef.current.timeInterval * stateRef.current.occurrences;
    setTotal(newTotal > 0 ? newTotal : stateRef.current.total);
    audioRef.current?.pause();

    timeoutIntervalRef.current = window.setTimeout(() => {
      setSelectionVisibility(true);
      window.clearInterval(timeIntervalRef.current);
      stateRef.current.occurrences = stateRef.current.occurrences + 1
      setOccurrences(stateRef.current.occurrences);
      audioRef.current = new Audio(data.soundurl);
      audioRef.current.play();

    }, stateRef.current.timeInterval * 1000);

    timeIntervalRef.current = window.setInterval(() => {
      setTotal((prev) => prev - 1);
      setTimeInterval((prev) => prev - 1);
    }, 1000);
  }, []);

  const resetTimers = useCallback(() => {
    timersStarted.current = false;
    window.clearTimeout(timeoutIntervalRef.current);
    window.clearInterval(timeIntervalRef.current);
    setTimeInterval(getSeconds(DEFAULT_SELECTED));
    setTotal(getSeconds(DEFAULT_SELECTED));
    setOccurrences(0);
    setResults(0);
    setSelectionVisibility(false);
    stateRef.current = {
      timeInterval: getSeconds(DEFAULT_SELECTED),
      total: getSeconds(DEFAULT_SELECTED),
      occurrences: 0
    };
  }, []);

  useEffect(() => {
    if (processing && !timersStarted.current) {
      startTimers();
    }

    if (!processing && timersStarted.current) {
      resetTimers();
    }
  }, [startTimers, resetTimers, processing]);

  useEffect(() => {
    return resetTimers;
  }, [resetTimers]);

  return (
    <Box sx={{ maxWidth: 650, mx: 'auto'}}>
      <Box
        sx={{
          maxWidth: 250,
          fontSize: 24,
          my: 2,
          mx: 'auto',
        }}
      >
        <p>
          <AvTimerIcon />
          &nbsp;
          Interval
        </p>
      </Box>

      {processing ? (
        <Box sx={{  p: 2, textAlign: 'center', mb: 7 }}>
          <Typography variant="h4" component="h2" mb={2}>
            Check in interval: {secondsFormat(timeInterval)}
          </Typography>
          <Typography variant="h4" component="h2" >
            Total time remaining: {secondsFormat(total)}
          </Typography>
          <Modal
            open={selectionVisibility}
          >
            <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ width: 200, background: '#fff', p: 2, display: 'flex' }}>
                <IconButton style={{ color: green[500] }} onClick={() => {
                  setResults((prev) => prev + 1);
                  startTimers();
                }}>
                  <CheckCircleOutlineIcon style={{ width: 60, height: 60 }} />
                </IconButton>
                <IconButton style={{ color: red[500] }} onClick={startTimers}>
                  <HighlightOffIcon style={{ width: 60, height: 60 }} />
                </IconButton>
              </Box>
            </Box>
          </Modal>
        </Box>
      ) : (
        <Box sx={{  p: 2, textAlign: 'center' }}>
          <Typography variant="h6" component="h2" mb={7}>
            Choose your interval settings
          </Typography>

          <Slider
            defaultValue={DEFAULT_SELECTED}
            step={null}
            marks={[{
              value: 1,
              label: '1m',
            }, ...STEPS]}
            valueLabelDisplay="on"
            min={1}
            max={20}
            onChange={(e, value) => {
              const seconds = getSeconds(value as number)
              setTimeInterval(seconds);
              stateRef.current.timeInterval = seconds;
            }}
          />

          <Typography variant="body1" component="h2" mb={7}>
            How often should we check in?
          </Typography>

          <Slider
            defaultValue={DEFAULT_SELECTED}
            step={null}
            marks={[{
              value: 2,
              label: '2m',
            }, ...STEPS]}
            valueLabelDisplay="on"
            min={2}
            max={20}
            onChange={(e, value) => {
              const seconds = getSeconds(value as number)
              setTotal(seconds);
              stateRef.current.total = seconds;
            }}
          />

          <Typography variant="body1" component="h2" mb={7}>
            For How long?
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default IntervalIncident;