import {
  Tabs as MuiTabs, withStyles
} from '@material-ui/core';

const Tabs = withStyles({
  root: {
    padding: '10px 40px 40px',
    paddingBottom: 0,
    flex: '0 0 auto',
    backgroundColor: '#ffffff',
    borderTopLeftRadius: 80,
    marginTop: -80
  },
  flexContainer: {
    justifyContent: 'space-between',
  }
})(MuiTabs);

export default Tabs;
