import { useState, useEffect } from 'react';
import history from 'services/history';
import moment, { Moment } from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  Box,
  Typography, Grid,
} from '@material-ui/core';
import { DateRangePicker } from '@material-ui/lab';
import CalendarTodayIcon from '@material-ui/icons/Today';
import ConfirmationModal, { ConfirmationProps } from 'components/ConfirmationModal';
import Button from 'components/Button';
import TextField from 'components/TextField';
import { getStudents } from 'redux/actions/classroom';
import ClassroomService from 'services/classroom.service';
import { downloadIncidentsCsv } from 'services/download-csv.helper';
import RootState from 'redux/RootState';
import Student from 'models/Student';
import IncidentType from 'models/IncidentType';
import ReportTypeSelect, { ReportType, ReportRootLevelType } from './ReportTypeSelect';

import styles from './ReportingSelectPage.module.scss';

const mapState = (state: RootState) => ({
  userData: state.auth.userData,
});

const mapDispatch = { getStudents };

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

const ReportingSelectPage = ({ userData, getStudents }: PropsFromRedux) => {
  const { studentId } = useParams<{ studentId: string }>();

  const location = useLocation<{ student: Student }>();
  const [student, setStudent] = useState(location.state?.student);
  const [reportType, setReportType] = useState<ReportType>(ReportRootLevelType.AllIncidentTypes);
  const [dates, setDates] = useState<Array<Moment | null>>([null, null]);
  const [confirmation, setConfirmation] = useState<ConfirmationProps>();

  useEffect(() => {
      async function loadStudent () {
        try {
          const student = await ClassroomService.getStudentInfo(Number(studentId));
          setStudent(student);
        } catch (error) {
          console.log(error);
        }
      }
      loadStudent();

  }, [studentId]);


  const getEditReportType = () => {
    switch (reportType) {
      case ReportRootLevelType.AllIncidentTypes:
        // all incident types
        return "";
      case IncidentType.AbcIncident:
      case IncidentType.FrequencyIncident:
      case IncidentType.IntervalIncident:
        return reportType;
      default:
        return null;
    }
  }

  const loadReport = () => {
    if (!student || !dates[0] || !dates[1]) return;

    history.push({
      pathname: `/classroom/${student.id}/report`,
      search: `?startDate=${dates[0].format('MM/DD/YYYY')}&endDate=${dates[1].format('MM/DD/YYYY')}&type=${getEditReportType()}`,
    });
  }

  const openConfirmation = () => {
    setConfirmation({
      title: 'View/Edit Report',
      text: 'Please note, you are only able to edit incidents for the past 31 days. For a longer time frame, please select "Download Report" and you will receive a CSV file',
      onConfirm: loadReport,
      confirmText: 'Continue',
      cancelText: 'Back',
      onCancel: () => setConfirmation(undefined)
    });
  }

  const onReportTypeChange = (reportType: ReportType) => {
    setReportType(reportType);
  }

  if (!userData) return null;

  return (
    <Box sx={{ pt: 2, pb: 2 }}>
      <h1>
        <span>Create </span>
        New Report
      </h1>
      <Box
        sx={{
          maxWidth: '600px',
          ml: 'auto',
          mr: 'auto',
        }}
      >
        <ReportTypeSelect value={reportType} onChange={onReportTypeChange} />
        <Box sx={{ mb: 3 }}>
          <DateRangePicker
            startText="Start date"
            endText="End date"
            value={dates as any}
            minDate={moment().subtract(1, 'years')}
            onChange={setDates}
            disableFuture
            renderInput={(startProps, endProps) => (
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Typography variant="body1">Start Date: </Typography>
                  <TextField
                    ref={startProps.inputRef}
                    {...startProps}
                    label={null}
                    helperText={null}
                    fullWidth
                    InputProps={{
                      endAdornment: <CalendarTodayIcon opacity={0.5} style={{ pointerEvents: 'none' }} />
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="body1">End Date: </Typography>
                  <TextField
                    ref={endProps.inputRef}
                    {...endProps}
                    label={null}
                    helperText={null}
                    fullWidth
                    InputProps={{
                      endAdornment: <CalendarTodayIcon opacity={0.5} style={{ pointerEvents: 'none' }} />
                    }}
                  />
                </Grid>
              </Grid>
            )}
          />
        </Box>
        <div className={styles.ActionButtonsRow}>
          <Button
            size="large"
            color="white"
            variant="contained"
            onClick={openConfirmation}
            disabled={!student || !dates[0] || !dates[1] || getEditReportType() === null}
          >
            View / Edit Report
          </Button>
          <Button
            size="large"
            color="white"
            variant="contained"
            onClick={() => downloadIncidentsCsv({
              studentId: student.id,
              startDate: dates[0]!.format('MM/DD/YYYY'),
              endDate: dates[1]!.format('MM/DD/YYYY'),
              type: reportType,
            }, userData)}
            disabled={!student || !dates[0] || !dates[1]}
          >
            Download Report
          </Button>
        </div>
        {confirmation && (
          <ConfirmationModal
            open={!isEmpty(confirmation)}
            title={confirmation.title}
            text={confirmation.text}
            confirmText={confirmation.confirmText}
            cancelText={confirmation.cancelText}
            onConfirm={confirmation.onConfirm}
            onCancel={confirmation.onCancel}
          />
        )}
      </Box>
    </Box>
  );
}

export default connector(ReportingSelectPage);
