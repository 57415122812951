import {
  Tab as MuiTab, withStyles
} from '@material-ui/core';

const Tab = withStyles((theme) => ({
  root: {
    fontSize: 12,
    minWidth: '33.33%',
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      color: '#66cdcc',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  selected: {},
}))(MuiTab);

export default Tab;
