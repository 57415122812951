import moment from 'moment';
import { AlertProps, IconButton } from '@material-ui/core';
import SettingSelect from 'components/SettingSelect';
import IScheduleSlot from 'models/ScheduleSlot';
import DeleteIcon from '@material-ui/icons/Delete';
import TimePicker from 'rc-time-picker';

import styles from './ScheduleSlot.module.scss';
import { ReactElement, useState } from 'react';
interface Props {
  slot: IScheduleSlot;
  updateSchedule: (slot: IScheduleSlot, index: number) => void;
  removeSlot: (index: number) => void;
  index: number;
  flashHighlight?: boolean;
  disabledTimes: Array<string>;
  setAlert: (value: React.SetStateAction<{
    severity: AlertProps['severity'];
    message: string | ReactElement;
  } | undefined>) => void;
}

export const DEFAULT_TIMEFRAME = '';

function ScheduleSlot({ slot, updateSchedule, removeSlot, index, flashHighlight = false, disabledTimes = [], setAlert }: Props) {
  const defaultTimeframe = slot.timeframe === DEFAULT_TIMEFRAME ? undefined : moment(slot.timeframe, 'HH:mm');
  const [timeframe, setTimeframe] = useState(defaultTimeframe);

  const disabledTimesDictionary: { [hour: number]: Array<number> } = {};
  for(const time of disabledTimes) {
    const timeArray = time.split(':');
    const hour = parseInt(timeArray[0]);
    const minutes = parseInt(timeArray[1]);
    if (disabledTimesDictionary[hour] === undefined) {
      disabledTimesDictionary[hour] = [ minutes ];
    } else {
      disabledTimesDictionary[hour].push(minutes);
    }
  }

  function disabledMinutes(hour: number) {
    return disabledTimesDictionary[hour];
  }

  return (
    <div className={`${styles.ScheduleCell} ${flashHighlight ? styles.FlashHighlight : ""}`}>
      <TimePicker
        showSecond={false}
        value={timeframe}
        onChange={(value) => {
          setTimeframe(value);
        }}
        onClose={() => {
          if (timeframe === defaultTimeframe) {
            return;
          }

          const time = timeframe?.format('HH:mm');
          if (disabledTimes.filter(x => x === time).length !== 0) {
            setAlert({
              severity: 'warning',
              message: `Oh no! It looks like there's another class that starts at ${time}.`,
            });
            
            // set value back to the one we started from
            setTimeframe(defaultTimeframe);
            return;
          }
          // value didn't change
          if (time === slot.timeframe) {
            return;
          }
          updateSchedule({ 
            location: slot.location,
            timeframe: timeframe?.format('HH:mm') ?? DEFAULT_TIMEFRAME,
          }, index);
        }}
        format="hh:mm a"
        inputReadOnly
        use12Hours
        disabledMinutes={disabledMinutes}
        placeholder="hh:mm a"
        className={styles.TimePicker}
        allowEmpty={false}
        minuteStep={5}
      />
      <SettingSelect
        value={slot.location}
        setSetting={(newValue: string) => updateSchedule({ location: newValue ?? '', timeframe: slot.timeframe }, index)}
      />
      <IconButton
        size="small"
        onClick={(e) => {
          e.preventDefault();
          removeSlot(index);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  )
}

export default ScheduleSlot;