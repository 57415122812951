import {
  Alert,
  Box,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import PaymentService from "../../../services/payment.service";
import Product from "models/Product";
import ProductCard from "./ProductCard";
import styles from "./ProductSelector.module.scss";
import SubscriptionType from "models/payments/SubscriptionType";

interface Props {
  includeTrial?: boolean;
  currentUserPlan: SubscriptionType | null;
  onSelect: (product: Product) => Promise<void>;
}

const ProductSelector = ({ includeTrial = false, currentUserPlan, onSelect }: Props) => {
  const [products, setProducts] = useState<Array<Product> | null | undefined>(
    undefined
  );

  const [error, setError] = useState<string>();

  useEffect(() => {
    const loadProducts = async () => {
      try {
        var response = await PaymentService.getProducts();

        if (!response) {
          setError("Can not find pricing plans.");
          setProducts(null);
          return;
        }

        setProducts(response);
      } catch (e) {
        setError("An error occurred while trying to retrieve pricing plans.");
        console.error(e);

        setProducts(null);
      }
    };

    loadProducts();
  }, []);

  const loader = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: { xs: "100px", md: "200px" },
      }}
    >
      <CircularProgress color="inherit" />
    </Box>
  );

  if (products === null) {
    return (
      <Alert variant="filled" severity="error">
        {error ?? "No products found."}
      </Alert>
    );
  }

  return (
    <>
      {products === undefined ? (
        loader
      ) : (
        <Grid
          container
          rowGap={2}
          sx={{ py: { xs: 2, md: 6 } }}
          display="flex"
          justifyContent="center"
        >
          {products.map((product) => (
            <Grid
              item
              key={product.productId}
              xs="auto"
              className={styles.PricingGridItem}
              sx={{ mx: { xs: 0, sm: 1, md: 2 } }}
            >
              <ProductCard
                includeTrial={includeTrial}
                currentUserPlan={currentUserPlan}
                product={product}
                onSelect={onSelect}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </>
  );
};

export default ProductSelector;
