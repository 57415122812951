import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { isEqual, debounce } from 'lodash';
import SettingSelect from 'components/SettingSelect';
import Incident from 'models/Incident';
import TextField from 'components/TextField';
import styles from '../ReportingResultPage.module.scss';

interface Props {
  incident: Incident;
  index: number;
  updateIncident: (incident: Incident) => void;
}

const IncidentRowEdit = ({ incident, index, updateIncident }: Props) => {
  const isInitialMount = useRef(true);
  const [results, setResults] = useState(incident.results);
  const [setting, setSetting] = useState(incident.setting);

  const debouncedUpdate = useRef(debounce(updateIncident, 500));

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (results === undefined) return;
      const newIncident = {
        ...incident,
        results,
        setting
      };
      if (isEqual(newIncident, incident)) return;
      debouncedUpdate.current(newIncident);
    }
  }, [incident, results, setting, debouncedUpdate]);

  return (
    <div className={styles.IncidentsTableRow}>
      <div className={styles.IncidentsTableCell}>
        {index}
      </div>
      <div className={styles.IncidentsTableCell}>
        <div className={styles.IncidentsTableCellHeading}>Started At</div>
        {moment(incident.startedAt).format('MM/DD/YYYY hh:mm:ssa')}
      </div>
      <div className={styles.IncidentsTableCell}>
        <div className={styles.IncidentsTableCellHeading}>Ended At</div>
        {moment(incident.endedAt).format('MM/DD/YYYY hh:mm:ssa')}
      </div>
      <div className={styles.IncidentsTableCell}>
        <div className={styles.IncidentsTableCellHeading}>Reporter</div>
        {incident.user?.name}
      </div>
      <div className={styles.IncidentsTableCell}>
        <div className={styles.IncidentsTableCellHeading}>Setting</div>
        <SettingSelect
          value={setting}
          setSetting={(newValue: string) => setSetting(newValue)}
        />
      </div>
      <div className={styles.IncidentsTableCell} style={{ width: 100 }}>
        <div className={styles.IncidentsTableCellHeading}>Interval</div>
        {incident.timeInterval}
      </div>
      <div className={styles.IncidentsTableCell} style={{ width: 120 }}>
        <div className={styles.IncidentsTableCellHeading}>Occurrences</div>
        {incident.occurrences}
      </div>
      <div className={styles.IncidentsTableCell} style={{ width: 100 }}>
        <div className={styles.IncidentsTableCellHeading}>Results</div>
        <TextField
          value={results}
          onChange={(e) => setResults(e.target.value && Number(e.target.value) ? Number(e.target.value) : undefined)}
          fullWidth
          margin="none"
          type="number"
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*'
          }}
        />
      </div>
    </div>
  );
};

export default IncidentRowEdit;
