import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  TextField, Grid, Button, Box, ClickAwayListener, Typography, CircularProgress
} from '@material-ui/core';
import { getUser } from '../../redux/actions/auth';

import AuthService from '../../services/auth.service';

import styles from './Auth.module.scss';

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordRepeat: '',
      loading: false,
      errors: [],
      alert: null
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  reset = async () => {
    const isFormValid = this.validatePasswordResetData(this.state.password, this.state.passwordRepeat);
    if (!isFormValid) return;

    this.setState({
      loading: true
    });

    try {
      await AuthService.resetPassword(this.props.match.params.token, this.state.password, this.state.passwordRepeat);
      await this.props.getUser();

      this.props.history.replace({
        ...this.props.location.state.background,
        state: undefined
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          variant: 'error',
          message: error?.message ? error?.message : 'Something went wrong',
        }
      });
    }
  }

  validatePasswordResetData(password, passwordRepeat) {
    const errors = [];

    if (!password) {
      errors.push('password');
    }
    if (!passwordRepeat) {
      errors.push('passwordRepeat');
    }
    if (password && passwordRepeat && password !== passwordRepeat) {
      errors.push('password');
      errors.push('passwordRepeat');
    }

    this.setState({
      alert: errors.length ? {
        variant: 'error',
        message: 'New password can\'t be empty and should match repeat password.'
      } : null,
      errors
    });

    return !errors.length;
  }

  render() {
    return (
      <div className={styles.AppModalPageWrapper}>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => this.props.history.push(this.props.location.state.background)}
        >
          <div className={styles.AppPageModal}>
            <form autoComplete="off" className={styles.AppPageModalContent} onSubmit={(e) => e.preventDefault()}>
              {this.state.loading && (
                <Box className={styles.AppModalLoading}>
                  <CircularProgress color="primary" />
                </Box>
              )}
              <h1>Password Reset</h1>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    value={this.state.password}
                    onChange={this.onChange}
                    error={this.state.errors.includes('password')}
                    label="Password *"
                    fullWidth
                    id="application-password"
                    margin="dense"
                    name="password"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    value={this.state.passwordRepeat}
                    onChange={this.onChange}
                    error={this.state.errors.includes('passwordRepeat')}
                    label="Repeat Password *"
                    fullWidth
                    id="application-password-repeat"
                    margin="dense"
                    name="passwordRepeat"
                  />
                </Grid>
              </Grid>
              {this.state.alert && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" component="div" className={`${this.state.alert.variant}-alert`}>
                    {this.state.alert.message}
                  </Typography>
                </Box>
              )}
              <Box sx={{ mt: 2 }}>
                <Button variant="contained" color="primary" onClick={this.reset} fullWidth size="large" role="button" type="submit">
                  Reset
                </Button>
              </Box>
            </form>
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

PasswordReset.propTypes = {
  getUser: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      background: PropTypes.shape({})
    })
  }).isRequired,
};

export default connect(
  null,
  { getUser }
)(PasswordReset);
