import {
  AlertProps,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import ScoreDefinition from "models/DigitalPassport/ScoreDefinition";
import { ReactElement, useEffect, useState } from "react";
import DigitalPassportRubricService from "../../../../services/digital-passport/rubric.service";

interface Props {
  studentId: string;
  open: boolean;
  onClose: () => void;
  setAlert: (
    value: React.SetStateAction<
      | {
          severity: AlertProps["severity"];
          message: string | ReactElement;
        }
      | undefined
    >
  ) => void;
}

const RubricDialog = ({ studentId, open, onClose, setAlert }: Props) => {
  const [scoreDefinitions, setScoreDefinitions] =
    useState<Array<ScoreDefinition>>();

  useEffect(() => {
    const loadScoreDefinitions = async () => {
      try {
        setScoreDefinitions(
          await DigitalPassportRubricService.getStudentRubric(Number(studentId))
        );
      } catch (error: any) {
        console.error(error);
        setAlert({
          severity: "error",
          message: error?.message,
        });
      }
    };

    loadScoreDefinitions();
  }, [studentId]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ mt: 2 }}>Rubric Score Definitions</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "grid" }}>
          {scoreDefinitions === undefined ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : scoreDefinitions.length === 0 ? (
            <>
              <p>No score definitions available.</p>
              <p>You can fill them in on the student's profile page.</p>
            </>
          ) : (
            scoreDefinitions.map((sd) => (
              <Box
                key={sd.score}
                sx={{
                  display: "grid",
                  border: "2px solid lightgray",
                  padding: "20px",
                  margin: "10px 0",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  align="center"
                  sx={{ mb: 1 }}
                >
                  Score {sd.score}
                </Typography>
                <div>{sd.definition}</div>
              </Box>
            ))
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RubricDialog;
