import { Box } from "@material-ui/core";

import styles from './PrivacyPage.module.scss'

const PrivacyPage = () => (
  <Box
    className={styles.PrivacyPage}
    sx={{
      maxWidth: "700px",
      m: "auto",
    }}
  >
    <h1>Privacy Promise</h1>
    <h2>Third Party Data</h2>

    <p>
      Propellrz does not rent or sell your data to third parties for any reason.
    </p>

    <p>
      Propellrz does not share your data to third parties for any marketing or
      advertising purposes.
    </p>

    <h2>Tracking and Advertising</h2>

    <p>
      Propellrz does not utilize personal information of its users to display
      third party behavioral or contextual advertising.
    </p>

    <p>
      Propellrz does not permit third-party advertising services or tracking
      technology to collect any information from its users.
    </p>

    <p>
      Propellrz does not allow the personal information of its users to be
      tracked and used for the purposes of targeted advertising on other
      third-party websites or services.
    </p>

    <p>
      Propellrz does not allow vendors to utilize the peronsal information of
      its users for the purposes of creating a profile, engaging in data
      enhancement, social advertising or targeted advertising.
    </p>

    <h2>Data Availability</h2>

    <p>
      Propellrz provides it paid users the ability to view, download and delete
      all od their own personal information entered into the app in the course
      of utilizing the app for its intended mission of tracking and reporting
      student behaviors.
    </p>

    <h2>Privacy Policy</h2>

    <p>
      Our complete&nbsp;
      <a
        href="https://www.propellrz.com/policies/Propellrz_Terms_and_Conditions.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        terms and conditions
      </a>
      , along with our&nbsp;
      <a
        href="https://www.propellrz.com/policies/Propellrz_Privacy_Policy.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        privacy policy
      </a>
      &nbsp;can be found here.
    </p>
    <h2>Security Policy</h2>
    <p>
      An Overview of our&nbsp;
      <a
        href="https://tracce.s3.amazonaws.com/policies/Privacy+and+Data+Security.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Security Plan
      </a>
      &nbsp;can be found here.
    </p>
  </Box>
);
export default PrivacyPage;
