enum IncidentType {
  AbcIncident = 'AbcIncident',
  FrequencyIncident = 'FrequencyIncident',
  IntervalIncident = 'IntervalIncident',
};

export default IncidentType;

export const IncidentTypeName = {
  [IncidentType.AbcIncident]: 'Abc Incident',
  [IncidentType.FrequencyIncident]: 'Frequency Incident',
  [IncidentType.IntervalIncident]: 'Interval Incident',
}
