import React from 'react';
import { Box } from '@material-ui/core';
import Header from '../../components/Header';

import styles from './FeaturesPage.module.scss';

const FeaturesPage = () => (
  <Box className={styles.FeaturesPage}>
    <div className="page-wrapper">
      <Header />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          flexShrink: 1,
        }}
      >
        <div className="container">
          <h1>
            Features Page
            <small>Propellrz is currently in Beta! Check back soon for pricing and availability!</small>
          </h1>
        </div>
      </Box>
    </div>
  </Box>
);

export default FeaturesPage;
