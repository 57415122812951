import { orderBy, set } from 'lodash';

import {
  STUDENTS_LOADING,
  STUDENTS_ERROR,
  FETCH_STUDENTS,
  ADD_STUDENT,
  REMOVE_STUDENT,
  USER_LOGOUT,
  CLEAR_CLASSROOM,
  GENERIC_SET_DATA,
} from '../actions/actionTypes';
import { ClassroomState } from '../RootState';

const initialState = {
  loading: false,
  students: undefined,
  error: null,
  addStudentModal: {
    activeStage: 'INITIAL',
    isLoading: false,
    errors: {},
    autocompleteStudentNameLoading: false,
    autocompleteStudentNameTrigger: false,
    autocompleteStudentNameValue: '',
    autocompleteStudentNameSelected: null,
    autocompleteStudentNameOptions: [],
    newStudentCaseManager: '',
    newStudentDateOfBirth: ''
  }
};

export default function authReducer(state: ClassroomState = initialState, action: any) {
  switch (action.type) {
    case STUDENTS_LOADING:
      return {
        ...state,
        error: null,
        loading: true
      };
    case STUDENTS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case FETCH_STUDENTS:
      return {
        ...state,
        students: orderBy(action.students, (student) => {
          return student.name.toLowerCase();
        }),
        error: null,
        loading: false
      };
    case ADD_STUDENT:
      return {
        ...state,
        students: orderBy([
          action.student,
          ...state.students ?? []
        ], (student) => student.name.toLowerCase()),
        error: null,
        loading: false
      };
    case REMOVE_STUDENT:
      return {
        ...state,
        students: orderBy(
          (state.students ?? []).filter((student) => student.id !== action.studentId),
          (student) => student.name.toLowerCase()
        ),
        error: null,
        loading: false
      };
    case USER_LOGOUT:
      return {
        ...state,
        students: undefined,
        error: null,
        loading: false
      };
    case CLEAR_CLASSROOM:
      return {
        ...state,
        students: [],
        error: null,
        loading: false
      };
    case GENERIC_SET_DATA:
      state = {...state};
      for (let item of action.payload) {
        set(state, item.path, item.value);
      }
      return state;
    default:
      return state;
  }
}
