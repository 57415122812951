export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const FETCH_AUTH_START = 'FETCH_AUTH_START';
export const FETCH_AUTH_SUCCESS = 'FETCH_AUTH_SUCCESS';
export const FETCH_AUTH_ERROR = 'FETCH_AUTH_ERROR';

export const STUDENTS_LOADING = 'STUDENTS_LOADING';
export const STUDENTS_ERROR = 'STUDENTS_ERROR';

export const CLEAR_CLASSROOM = 'CLEAR_CLASSROOM';

export const FETCH_STUDENTS = 'FETCH_STUDENTS';
export const ADD_STUDENT = 'ADD_STUDENT';
export const REMOVE_STUDENT = 'REMOVE_STUDENT';

export const USER_LOGOUT = 'USER_LOGOUT';

export const GENERIC_SET_DATA = 'GENERIC_SET_DATA';