import React, { useEffect, useState } from 'react';
import {
  Box, Typography,
} from '@material-ui/core';
import IIncident from 'models/Incident';
import Button from 'components/Button';
import TimelineIcon from '@material-ui/icons/Timeline';

interface Props {
  processing: boolean;
  onUpdate: (callback: (incidentData?: Pick<IIncident, 'frequency'>) => Pick<IIncident, 'frequency'> | undefined) => void;
}

function FrequencyIncident ({ onUpdate, processing }: Props) {
  const [frequency, setFrequency] = useState(0);

  useEffect(() => {
    onUpdate(() => ({ frequency }));
  }, [onUpdate, frequency]);

  useEffect(() => {
    if (!processing) setFrequency(0);
  }, [processing]);

  return (
    <>
      <Box
        sx={{
          maxWidth: 250,
          fontSize: 24,
          my: 2,
          mx: 'auto',
        }}
      >
        <p>
          <TimelineIcon />
          &nbsp;
          Frequency
        </p>
      </Box>
      <Box sx={{ maxWidth: 600, mx: 'auto', textAlign: 'center', marginY: 5 }}>
        {processing ? (
          <Button
            size="large"
            color="white"
            variant="outlined"
            onClick={() => setFrequency(prevFrequency => prevFrequency + 1)}
          >
            Frequency &nbsp;
            {frequency}
          </Button>
        ) : (
          <Typography variant="h6" component="h2">
            Click START to track frequency. Once you have started tracking, tap the frequency button each time an incident occurs.
          </Typography>
        )}
      </Box>
    </>
  );
}

export default FrequencyIncident;