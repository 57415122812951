import { useState } from 'react';
import { TextField, makeStyles, Typography } from '@material-ui/core';
import styles from './ScoreDefinitionSlot.module.scss';
import ScoreDefinition from './../../../../../models/DigitalPassport/ScoreDefinition';

interface Props {
  scoreDefinition: ScoreDefinition;
  update: (scoreDefinition: ScoreDefinition) => void;
}

const muiStyles = (theme: any) => ({
  textField: {
      marginTop: 0,
  },
  input: {
    backgroundColor: "#ffffffb3",
    "&:hover": {
      backgroundColor: "#ffffffb3",
    },
    "&.Mui-focused": {
      backgroundColor: "#ffffffb3",
    }
  },
});

function ScoreDefinitionSlot({ scoreDefinition, update }: Props) {
  const [definition, setDefinition] = useState<string>(scoreDefinition.definition);

  const classes = makeStyles(muiStyles)();

  const updateDefinition = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const updatedDefinition = e.target.value;

    setDefinition(updatedDefinition)

    update({ ...scoreDefinition, definition: updatedDefinition })
  }

  return (
    <div className={styles.Container}>
      <Typography variant='h6' textAlign="center" gutterBottom>
        Score {scoreDefinition.score}
      </Typography>
      <div>
        <TextField
          type="text"
          variant='filled'
          fullWidth
          margin="normal"
          label="Definition"
          value={definition}
          onChange={updateDefinition}
          multiline
          maxRows={3}
          inputProps={{ maxLength: 255 }}
          className={classes.textField}
          InputProps={{
            className: classes.input,
          }}
        />
      </div>
    </div>
  )
}

export default ScoreDefinitionSlot;