import { useRef, useState, useEffect, useCallback } from 'react';
import history from 'services/history';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import red from '@material-ui/core/colors/red';
import {
  Box, CircularProgress, Snackbar, Typography, Alert, AlertProps,
} from '@material-ui/core';

import Button from 'components/Button';
import ConfirmationModal, { ConfirmationProps } from 'components/ConfirmationModal';

import ClassroomService from 'services/classroom.service';
import TrackingService from 'services/tracking.service';

import { downloadIncidentsCsv } from 'services/download-csv.helper';
import RootState from 'redux/RootState';
import Incident from 'models/Incident';
import IncidentType, { IncidentTypeName } from 'models/IncidentType';
import Student, { StudentWithDependencies } from 'models/Student';

import AbcIncidentTable from './AbcIncidentTable';
import FrequencyIncidentTable from './FrequencyIncidentTable';
import IntervalIncidentTable from './IntervalIncidentTable';
import { ReportRootLevelType } from 'pages/ReportingSelectPage/ReportTypeSelect';


const mapState = (state: RootState) => ({
  userData: state.auth.userData,
});

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const ReportingResultPage = ({ userData }: PropsFromRedux) => {
  const location = useLocation<{ search: { startDate: string; endDate: string; }}>();
  const { studentId } = useParams<{ studentId: string }>();

  const searchParams = new URLSearchParams(location.search);
  const startDate = useRef(searchParams.get('startDate'));
  const endDate = useRef(searchParams.get('endDate'));
  const incidentType = useRef<IncidentType>(searchParams.get('type') as IncidentType);

  const [incidents, setIncidents] = useState<Incident[]>([]);
  const [student, setStudent] = useState<StudentWithDependencies | null>();
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState<ConfirmationProps>();
  const [alert, setAlert] = useState<{ severity: AlertProps['severity']; message: string; }>();

  useEffect(() => {
    async function start() {
      try {
        if (!startDate.current || !endDate.current) return;

        const startDateString = moment(startDate.current, 'MM/DD/YYYY').toISOString();
        const endDateString = moment(endDate.current, 'MM/DD/YYYY').endOf('day').toISOString();

        const [student, incidentsData] = await Promise.all([
          ClassroomService.getStudentInfo(Number(studentId)),
          TrackingService.getStudentIncidents(Number(studentId), {
            startDate: startDateString,
            endDate: endDateString
          }, incidentType.current)
        ]);

        (window as any).analytics.track('Created Report', {
          studentId,
          startDate: startDateString,
          endDate: endDateString
        });

        setStudent(student);
        setIncidents(incidentsData.incidents);
      } catch (error: any) {
        console.log(error);
        setStudent(null);
        setAlert({
          severity: 'error',
          message: error?.message,
        });
      }
      setLoading(false);
    }
    start();
  }, [studentId]);

  const updateIncident = useCallback(async (incidentData: Incident) => {
    if (!studentId) return;

    try {
      await TrackingService.updateIncident(Number(studentId), incidentData.id, incidentData);
      setAlert({
        severity: 'success',
        message: 'Incident updated successfully'
      });
    } catch (error: any) {
      console.log(error);
      setAlert({
        severity: 'error',
        message: error?.message
      });
    }
  }, [studentId]);

  const openConfirmation = () => {
    if (!student || !startDate.current || !endDate.current || !userData) return;

    const reportType = incidentType.current
      ? incidentType.current
      : ReportRootLevelType.AllIncidentTypes;

    setConfirmation({
      title: 'Download Report',
      text: `You are going to download report for ${student.name} in the date range ${startDate.current} - ${endDate.current}`,
      onConfirm: () => downloadIncidentsCsv({
        studentId: student.id, startDate: startDate.current!, endDate: endDate.current!, type: reportType,
      }, userData),
      confirmText: 'Confirm',
      cancelText: 'Modify',
      onCancel: () => history.push({
        pathname: `/classroom/${student.id}/reporting`,
        state: {
          student,
        }
      }),
      onClose: () => setConfirmation(undefined)
    });
  }

  const hideAlert = () => setAlert(undefined);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  if (student === null) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={red[500]}
        height="100%"
      >
        Unable to fetch report data. Try to select student from the report page.
      </Box>
    );
  }

  if (!userData || !student) return null;

  return (
    <div className="container">
      <h1>
        <span>Now viewing,</span>
        &nbsp;
        {student.name}
      </h1>
      <Box sx={{ m: 'auto', maxWidth: '1600px' }}>
        <Typography variant="h6" align="center">
          {`Type: ${incidentType.current ? IncidentTypeName[incidentType.current] : 'All'}`}
        </Typography>
        <Typography variant="h6" align="center">
          {`Date Range: ${startDate.current} - ${endDate.current}`}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button
            color="white"
            variant="contained"
            onClick={openConfirmation}
            disabled={incidents.length === 0}
          >
            Download
          </Button>
        </Box>
        {incidents.length === 0 && <Box sx={{ fontSize: 30, textAlign: 'center', mt: 3 }}>There are no incidents to display</Box>}
        <AbcIncidentTable
          incidents={incidents.filter(incident => incident.type === IncidentType.AbcIncident)}
          updateIncident={updateIncident}
          currentUserId={userData?.id}
          student={student}
        />
        <FrequencyIncidentTable
          incidents={incidents.filter(incident => incident.type === IncidentType.FrequencyIncident)}
          updateIncident={updateIncident}
          currentUserId={userData?.id}
        />
        <IntervalIncidentTable
          incidents={incidents.filter(incident => incident.type === IncidentType.IntervalIncident)}
          updateIncident={updateIncident}
          currentUserId={userData?.id}
        />
      </Box>
      {confirmation && (
        <ConfirmationModal
          open={true}
          title={confirmation.title}
          text={confirmation.text}
          confirmText={confirmation.confirmText}
          cancelText={confirmation.cancelText}
          onConfirm={confirmation.onConfirm}
          onCancel={confirmation.onCancel}
        />
      )}
      {alert ? (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={!!alert} autoHideDuration={6000} onClose={hideAlert}>
          <Alert elevation={6} variant="filled" onClose={hideAlert} severity={alert.severity}>
            {alert.message}
          </Alert>
        </Snackbar>
      ) : null }
    </div>
  );
}

export default connector(ReportingResultPage);