import { useState, useEffect } from 'react';
import red from '@material-ui/core/colors/red';
import { useParams } from 'react-router-dom';
import {
     Box, Grid, CircularProgress, useTheme, useMediaQuery
} from '@material-ui/core';
import { DIMENSIONS, SETTINGS } from 'assets/constants';

import ClassroomService from 'services/classroom.service';
import { StudentTargetBehaviorOverview } from 'models/Report';
import StudentProfile from 'components/StudentProfile/StudentProfile';
import ShortBehaivorStatistic from 'components/ReportBehaviorComponents/ShortBehaviorStatistic';
import TargetBehaviorBox from 'components/ReportBehaviorComponents/TargetBehaviorBox';
import FunctionOfBehavior from 'components/Chart/Chart';
import Section from 'pages/StudentPage/Section';
import Typography from '@material-ui/core/Typography';


const ReportTargetBehaviorOverviewPage = () => {
    const { studentId } = useParams<{ studentId: string }>();
    const { targetBehavior } = useParams<{ targetBehavior: string }>();
    const targetBehaviorDecode = decodeURIComponent(targetBehavior);
    const [studentTargetBehaviorOverview, setStudent] = useState<StudentTargetBehaviorOverview | null>();
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('lg'));
    const antecedentList = DIMENSIONS.filter(x => x.name == 'Antecedent')[0];
    const consequenceList = DIMENSIONS.filter(x => x.name == 'Consequence')[0];
    let dimensionsPercentSum = 0;

    useEffect(() => {
        async function loadStudent () {
          try {
            const studentTargetBehaviorOverview = await ClassroomService.getStudentTargetBehaviorOverview(Number(studentId), String(targetBehavior));
            setStudent(studentTargetBehaviorOverview);
          } catch (error) {
            console.error(error);
            setStudent(null);
          }
        }
        loadStudent();

    }, [studentId]);

    if (studentTargetBehaviorOverview === undefined) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress color="inherit" />
            </Box>
        );
    }

    if (studentTargetBehaviorOverview === null) {
        return (
            <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            color={red[500]}
            height="100%"
            >
                Unable to fetch student report data. Try to request a student report again.
            </Box>
        );
    }

    for (const item of studentTargetBehaviorOverview.dimensions){
        dimensionsPercentSum += item.percent;
    }

    return (
        <Box sx={{mt: 5}}>
            <Typography variant="h1" sx={{textAlign: 'center', mb: 5, fontSize: {xs: "40px !important", md: "60px !important"}}}>
                <span>Target behavior </span>
                {' '}
                {targetBehaviorDecode}
                {' '}
            </Typography>
            <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", mt: {xs: 5, md: 10}, mx: {xs: 0, md: 8}, mb: 4 }}>
                <Grid container sx={{display: "flex", justifyContent: "center", maxWidth: '1200px', gap: { xs: 3, md: 8 }, flexWrap: "nowrap" }}>
                    <Grid item xs="auto">
                        <Box sx={{width: {xs: 150, sm: 200}}}>
                            <StudentProfile name={studentTargetBehaviorOverview.name} avatarUrl={studentTargetBehaviorOverview.avatarUrl}/>
                        </Box>
                    </Grid>
                    <Grid item xs="auto" lg> 
                        <Box display="flex" sx={{
                            gap: 3,
                            width: 1,
                            height: 1,
                            flexDirection: { xs: "column", lg: "row" },
                            justifyContent: { xs: "center", lg: "space-around" },
                            alignItems: { xs: "flex-start", lg: "center" },
                        }}>
                            <ShortBehaivorStatistic count={`${studentTargetBehaviorOverview.targetBehaivorCount}`} description=" total incidents"/>
                            <ShortBehaivorStatistic count={`${studentTargetBehaviorOverview.targetBehaivorPercent}%`} description=" of all incidents"/> 
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={1} mb={2}>   
                    <Grid item xs={12}>
                        <Section id="op-settings" title='Settings'>
                            <Grid container spacing={isSmallDevice ? 1 : 2}>
                                {
                                    SETTINGS.map((option, i) => {
                                        const matchingIncident = studentTargetBehaviorOverview.settings.find(
                                            (incident) => incident.itemName === option
                                        );
                                        if (matchingIncident) {
                                            return (
                                                <TargetBehaviorBox
                                                    key={i}
                                                    studentId={studentTargetBehaviorOverview.id}
                                                    percent={matchingIncident.percent}
                                                    targetBehaviorCount={matchingIncident.count}
                                                    allIncidentsCount={studentTargetBehaviorOverview.targetBehaivorCount}
                                                    title={option}
                                                    viewDetailsButton={false}
                                                    isTargetPage={true}
                                                />
                                            );
                                        } else {
                                            return (
                                                <TargetBehaviorBox
                                                    key={i}
                                                    studentId={studentTargetBehaviorOverview.id}
                                                    percent={0}
                                                    targetBehaviorCount={0}
                                                    allIncidentsCount={studentTargetBehaviorOverview.targetBehaivorCount}
                                                    title={option}
                                                    viewDetailsButton={false}
                                                    isTargetPage={true}
                                                />
                                            )
                                        }
                                    })
                                }
                            </Grid>
                        </Section>
                        <Section id="op-antecedents" title='Antecedents'>
                            <Grid container spacing={isSmallDevice ? 1 : 2}>
                                {
                                    antecedentList.options.map((option, i) => {
                                        const matchingIncident = studentTargetBehaviorOverview.antecedents.find(
                                            (incident) => incident.itemName === option
                                        );
                                        if (matchingIncident) {
                                            return (
                                                <TargetBehaviorBox
                                                    key={i}
                                                    studentId={studentTargetBehaviorOverview.id}
                                                    percent={matchingIncident.percent}
                                                    targetBehaviorCount={matchingIncident.count}
                                                    allIncidentsCount={studentTargetBehaviorOverview.targetBehaivorCount}
                                                    title={option}
                                                    viewDetailsButton={false}
                                                    isTargetPage={true}
                                                />
                                            );
                                        } else {
                                            return (
                                                <TargetBehaviorBox
                                                    key={i}
                                                    studentId={studentTargetBehaviorOverview.id}
                                                    percent={0}
                                                    targetBehaviorCount={0}
                                                    allIncidentsCount={studentTargetBehaviorOverview.targetBehaivorCount}
                                                    title={option}
                                                    viewDetailsButton={false}
                                                    isTargetPage={true}
                                                />
                                            )
                                        }
                                    })
                                }
                            </Grid>
                        </Section>
                        <Section id="op-consequences" title='Consequences'>
                            <Grid container spacing={isSmallDevice ? 1 : 2}>
                                {
                                    consequenceList.options.map((option, i) => {
                                        const matchingIncident = studentTargetBehaviorOverview.consequences.find(
                                            (incident) => incident.itemName === option
                                        );
                                        if (matchingIncident) {
                                            return (
                                                <TargetBehaviorBox
                                                    key={i}
                                                    studentId={studentTargetBehaviorOverview.id}
                                                    percent={matchingIncident.percent}
                                                    targetBehaviorCount={matchingIncident.count}
                                                    allIncidentsCount={studentTargetBehaviorOverview.targetBehaivorCount}
                                                    title={option}
                                                    viewDetailsButton={false}
                                                    isTargetPage={true}
                                                />
                                            );
                                        } else {
                                            return (
                                                <TargetBehaviorBox
                                                    key={i}
                                                    studentId={studentTargetBehaviorOverview.id}
                                                    percent={0}
                                                    targetBehaviorCount={0}
                                                    allIncidentsCount={studentTargetBehaviorOverview.targetBehaivorCount}
                                                    title={option}
                                                    viewDetailsButton={false}
                                                    isTargetPage={true}
                                                />
                                            )
                                        }
                                    })
                                }
                            </Grid>
                        </Section>
                        <Section id="op-dimensions" title="Perceived Motivation">
                            <Grid container spacing={isSmallDevice ? 1 : 2}>
                                {
                                    studentTargetBehaviorOverview.dimensions.map((incident, i) => {
                                        return (
                                            <TargetBehaviorBox
                                                    key={i}
                                                    studentId={studentTargetBehaviorOverview.id}
                                                    percent={incident.percent}
                                                    targetBehaviorCount={incident.count}
                                                    allIncidentsCount={studentTargetBehaviorOverview.targetBehaivorCount}
                                                    title={incident.itemName}
                                                    viewDetailsButton={false}
                                                    isTargetPage={true}
                                            />
                                        )
                                    })
                                }
                            </Grid>
                        </Section>
                        {dimensionsPercentSum > 0 && 
                            <Section id="op-function-behavior" title='Function of student behavior'>
                                <Box sx={{width: "100%",  display: "flex", justifyContent: "center"}} mt={5}>
                                    <Box sx={{width: {xs: "100%", md: "50%"}}}>
                                        <FunctionOfBehavior itemList={studentTargetBehaviorOverview.dimensions}/>
                                    </Box>
                                </Box>
                            </Section>
                        } 
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default ReportTargetBehaviorOverviewPage;