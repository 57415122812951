import { Box, Typography } from "@material-ui/core";
import Button from "components/Button";
import history from "services/history";

interface Props {
  studentId: string;
  title: string;
  actionText: string;
  studentsPageSectionKey: string;
}

export default function RedirectBanner({
  studentId,
  title,
  actionText,
  studentsPageSectionKey,
}: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Typography pb={4} textAlign="center">
        {title}
      </Typography>
      <Button
        color="white"
        variant="contained"
        onClick={() => {
          history.push(`/classroom/${studentId}#${studentsPageSectionKey}`);
        }}
      >
        {actionText}
      </Button>
    </Box>
  );
}
