import React from 'react';
import IncidentType from 'models/IncidentType';
import AbcIncidentComponent from './AbcIncident';
import FrequencyIncidentComponent from './FrequencyIncident';
import IntervalIncidentComponent from './IntervalIncident';
import IIncident from 'models/Incident';
import { StudentWithDependencies } from 'models/Student';

const incidentTypeComponents = {
  AbcIncident: AbcIncidentComponent,
  FrequencyIncident: FrequencyIncidentComponent,
  IntervalIncident: IntervalIncidentComponent
};

interface Props {
  processing: boolean;
  type: IncidentType;
  student: StudentWithDependencies | null;
  onUpdate: (callback: (incident?: Omit<IIncident, 'id' | 'setting' | 'startedAt' | 'endedAt' | 'type'>) => Omit<IIncident, 'id' | 'setting' | 'startedAt' | 'endedAt' | 'type'> | undefined) => void;
};

function Incident ({ type, onUpdate, processing, student }: Props) {
  const IncidentComponent = incidentTypeComponents[type as keyof typeof incidentTypeComponents];
  return <IncidentComponent onUpdate={onUpdate} processing={processing} student={student} />
}

export default Incident;