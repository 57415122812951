import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TextField, Grid, Button, Box, ClickAwayListener, Typography, CircularProgress
} from '@material-ui/core';

import AuthService from '../../services/auth.service';

import styles from './Auth.module.scss';

class RequestPasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      loading: false,
      errors: [],
      alert: null
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  reset = async () => {
    const userData = {
      username: this.state.username
    };

    const isFormValid = this.validateLoginData(userData);
    if (!isFormValid) return;

    this.setState({
      loading: true
    });

    try {
      await AuthService.requestPasswordReset(this.state.username);
      this.setState({
        loading: false,
        alert: {
          variant: 'success',
          message: 'We have received your request to change your password. Please check your email for instructions'
        }
      });
    } catch (error) {
      this.setState({
        loading: false,
        alert: {
          variant: 'error',
          message: 'Something went wrong'
        }
      });
    }
  }

  validateLoginData(userData) {
    const errors = [];

    Object.keys(userData).forEach((key) => {
      if (!userData[key]) {
        errors.push(key);
      }
    });

    this.setState({
      alert: errors.length ? {
        variant: 'error',
        message: 'Email can\'t be empty'
      } : null,
      errors
    });

    return !errors.length;
  }

  render() {
    return (
      <div className={styles.AppModalPageWrapper}>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => this.props.history.push(this.props.location.state.background)}
        >
          <div className={styles.AppPageModal}>
            <form autoComplete="off" className={styles.AppPageModalContent} onSubmit={(e) => e.preventDefault()}>
              {this.state.loading && (
                <Box className={styles.AppModalLoading}>
                  <CircularProgress color="primary" />
                </Box>
              )}
              <h1>Password Reset</h1>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    value={this.state.username}
                    onChange={this.onChange}
                    error={this.state.errors.includes('username')}
                    label="Email"
                    fullWidth
                    id="application-username"
                    margin="normal"
                    name="username"
                    autoFocus
                  />
                </Grid>
              </Grid>
              {this.state.alert && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" component="div" className={`${this.state.alert.variant}-alert`}>
                    {this.state.alert.message}
                  </Typography>
                </Box>
              )}
              <Box sx={{ mt: 2 }}>
                <Button variant="contained" color="primary" onClick={this.reset} fullWidth size="large" role="button" type="submit">
                  Send Request
                </Button>
              </Box>
            </form>
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

RequestPasswordReset.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      background: PropTypes.shape({})
    })
  }).isRequired,
};

export default RequestPasswordReset;
